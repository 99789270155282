import React, {useState, useEffect} from 'react';
import {Progress, Radio} from 'antd';
import errorIcon from '../../../assets/img/ci_warning.png';
import {useHistory, useLocation} from 'react-router-dom';
import BottomButtons from '../../../pages/SignUp/NewCreateSkillListing/components/Footer/BottomButtons';
import {useDispatch, useSelector} from 'react-redux';
import {Tooltip} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import SkillListingTips from '../../../pages/SignUp/Components/SkillListingTips/SkillListingTips';
import SkillListingToolTip from '../../../pages/SignUp/Components/SkillListingTips/SkillListingToolTip';
import { fetchOneSkillListing } from '../../../redux/actions';

const EditGigType = () => {
    const dispatch = useDispatch();
    const history = useHistory();
	const [showError, setShowError] = useState(false);
	const [flag, setFlag] = useState(false);
	const location = useLocation();
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [checkValue, setCheckValue] = useState('');
	const profiledata = useSelector((state) => state.profileReducer.user);
	const getInputValues = JSON.parse(localStorage.getItem('SkillListing'));
	// const path = JSON.parse(localStorage.getItem('pathName'));
    const singleSkillListing = useSelector(
        (state) => state.skillListingReducer.singleSkillListing
      );

	const chooseSkillListing = (e) => {
		const value = e.target.value;
		if (value) {
			setShowError(false);
		}
		setCheckValue(value);
	};

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

    useEffect(() => {
        if(getInputValues){
            localStorage.setItem('SkillListing', JSON.stringify(getInputValues))
			setCheckValue(getInputValues?.GigType);
        }
      }, []);

	const handleNext = () => {
		if (checkValue) {
			setFlag(true);
			localStorage.setItem(
				'SkillListing',
				JSON.stringify({...getInputValues,GigType: checkValue}),
			);
		} else {
			setShowError(true);
		}
		history.push('/edit-skill-title');
	};

	const handleBack = () => {
		history.push(`/skill-listing-detail-page/${getInputValues?.SkillListingId}`);
		localStorage.removeItem('SkillListing');
	};

	const handleGigTextClick = (gigType) => {
		setShowError(false);
		const gigTypeValue = gigType == 'PerDiem'? 'Per Diem' : gigType
		setCheckValue(gigTypeValue);
	};

	const HCtitle = 'Are you looking for a contract, permanent, or per-diem position?';

	return (
		<div className="CreateSkillListingHeader">
			<div>
				<div className="create-skill-listing-title">
					Select Gig type<span className="Mandatory-Text" style={{position:'relative', left:'-4px', bottom: '1px'}}>(Mandatory)</span>
					{screenWidth < 769 && (
							<Tooltip
							className="gig-type-Tooltip"
							trigger={['click']}
							title={<SkillListingToolTip/>}
							style={{right:"200px"}}
							overlayClassName='gig-tooltip'
						>
							<div style={{position:'relative', bottom: '3px'}}><InfoCircleOutlined /></div>
						</Tooltip>
					)}
				</div>
				<div className="create-skill-listing-title-subheader">
            <span >
              {HCtitle}
            </span>
				</div>
				<div>
					<Radio.Group
						className="create-skl-lst-card-cnt"
						onChange={chooseSkillListing}
						value={checkValue?.replace(/\s+/g, '')}
					>
						<div>
							<div className="skillcreate-name-radio-btn">
								<Radio value="Contract"></Radio>
								<span
									className="skill-create-name"
									onClick={() => handleGigTextClick('Contract')}
								>
									Contract/Travel
								</span>
							</div>
						</div>
						<div>
							<div className="skillcreate-name-radio-btn">
								<Radio value="Permanent"></Radio>
								<span
									className="skill-create-name"
									onClick={() => handleGigTextClick('Permanent')}
								>
									Permanent
								</span>
							</div>
						</div>
						{profiledata?.profile?.ProfessionCode === 'HC' && (
							<div>
								<div className="skillcreate-name-radio-btn">
									<Radio value="PerDiem"></Radio>
									<span
										className="skill-create-name"
										onClick={() => handleGigTextClick('PerDiem')}
									>
										Per Diem
									</span>
								</div>
							</div>
						)}
					</Radio.Group>
				</div>
				{showError && (
					<div className="error-msg-content">
						<img src={errorIcon} alt="" />
						<span className="error-text">
							Please select the gig type to continue
						</span>
					</div>
				)}
			<div style={{marginTop:"-40px"}}>
			<BottomButtons
					handleBack={handleBack}
					handleNext={handleNext}
					isBack={false}
				/>
			</div>
			</div>
			{screenWidth > 1000 && (
				<div className="CreateSkillListing-tip-cont">
					<SkillListingTips pageType="gigType" />
				</div>
			)}
		</div>
	);
};
export default EditGigType;
