import React, { useState, useEffect } from "react";
import { Progress, Radio } from "antd";
import "./CreateSkillListingScreen.css";
import errorIcon from "../../../../../assets/img/ci_warning.png";
import { useHistory } from "react-router-dom";
import BottomBottons from "../Footer/BottomButtons";
import { useDispatch, useSelector, connect } from "react-redux";
import { updateSignupProfile } from "../../../../../redux/actions";
import SkillListingTips from "../../../Components/SkillListingTips/SkillListingTips";
import { useUpdateLogger } from "../../../../../shared/useUpdateLogger";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import SkillListingToolTip from "../../../Components/SkillListingTips/SkillListingToolTip";
import {
  back,
  getWizarFlowNumber,
  next,
} from "../../../../Auth/components/wizardFlow";
import Loader from "../../../../../components/Loader/Loader";

export const GigType = (props) => {
  const ProfessionCodes = useSelector(
    (state) => state.profileReducer?.user?.profile?.ProfessionCode
  );
  const getCurrentIndex = () => {
    return localStorage.getItem("ProfessionsCode") == "HC" ||
      ProfessionCodes == "HC"
      ? 14
      : 10;
  };
  const [showError, setShowError] = useState(false);
  const [flag, setFlag] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  // const [page, setPage] = useState(0);
  const { page, setPage } = props;
  const [checkValue, setCheckVlue] = useState("");
  const profiledata = useSelector((state) => state.profileReducer.user);
  const ProfessionCode = profiledata?.profile?.ProfessionCode;

  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );
  const { WizardFlow } = useSelector(
    (state) => state.profileReducer.user.profile
  );
  const history = useHistory();

  const chooseSkillListing = (e) => {
    const value = e.target.value;
    if (value) {
      setShowError(false);
    }
    setCheckVlue(value);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  // if (WizardFlow === 201) return;
  // dispatch(
  //   updateSignupProfile({
  //     WizardFlow: getWizarFlowNumber(currentIndex),
  //   })
  // );
  // }, []);

  useEffect(() => {
    if (apiSuccess && checkValue && flag) {
      history.push(next(getCurrentIndex()));
    }
  }, [apiSuccess]);

  useEffect(() => {
    setCheckVlue(profiledata?.profile?.DraftSkillListing?.GigType);
  }, [profiledata?.profile?.DraftSkillListing?.GigType]);

  const handleNext = () => {
    if (checkValue) {
      setFlag(true);
      dispatch(
        updateSignupProfile({
          "DraftSkillListing.GigType": checkValue,
          WizardFlow: getWizarFlowNumber(getCurrentIndex()),
        })
      );
    } else {
      setShowError(true);
    }
  };

  const handleBack = () => {
    history.push(back(getCurrentIndex()));
  };

  const handleGigTextClick = (gigType) => {
    setShowError(false);
    setCheckVlue(gigType);
  };

  const ITtitle = "Are you looking for a contract or permanent position?";
  const HCtitle =
    "Are you looking for a contract, permanent, or per-diem position?";

  const signUpLoading = useSelector(
    (state) => state.profileReducer.signUpLoading
  );
  const { loading: profileLoading } = useSelector(
    (state) => state.profileReducer
  );

  if (signUpLoading || profileLoading) return <Loader />;
 
  return (
    <div className="CreateSkillListingHeader">
      <div>
        <div className="create-skill-listing-title">
          Select Gig type<span className="Mandatory-Text">(Mandatory)</span>
          {screenWidth < 769 && (
            <Tooltip
              className="gig-type-Tooltip"
              trigger={["click"]}
              title={<SkillListingToolTip />}
            >
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </div>
        <div className="create-skill-listing-title-subheader">
          {!loading && (
            <span>
              {profiledata?.profile?.ProfessionCode === "IT"
                ? ITtitle
                : HCtitle}
            </span>
          )}
        </div>
        {/* {screenWidth < 769 &&
        
          <div className="CreateSkillListing-tip-cont">
            <SkillListingTips pageType="gigType" />
            
            
          </div>
        } */}
        <div>
          <Radio.Group
            className="create-skl-lst-card-cnt"
            onChange={chooseSkillListing}
            value={checkValue}
          >
            <div>
              <div className="skillcreate-name-radio-btn">
                <Radio value="Contract"></Radio>
                <span
                  className="skill-create-name"
                  onClick={() => handleGigTextClick("Contract")}
                >
                  Contract/Travel
                </span>
              </div>
            </div>
            <div>
              <div className="skillcreate-name-radio-btn">
                <Radio value="Permanent"></Radio>
                <span
                  className="skill-create-name"
                  onClick={() => handleGigTextClick("Permanent")}
                >
                  Permanent
                </span>
              </div>
            </div>
            {profiledata?.profile?.ProfessionCode === "HC" && (
              <div>
                <div className="skillcreate-name-radio-btn">
                  <Radio value="PerDiem"></Radio>
                  <span
                    className="skill-create-name"
                    onClick={() => handleGigTextClick("PerDiem")}
                  >
                    Per Diem
                  </span>
                </div>
              </div>
            )}
          </Radio.Group>
        </div>
        {showError && (
          <div className="error-msg-content">
            <img src={errorIcon} alt="" />
            <span className="error-text">
              Please select the gig type to continue
            </span>
          </div>
        )}
        <BottomBottons
          loading={loading}
          handleBack={handleBack}
          handleNext={handleNext}
          isGigType={true}
        />
      </div>
      {screenWidth > 1000 && (
        <div className="CreateSkillListing-tip-cont">
          <SkillListingTips pageType="gigType" />
        </div>
      )}
    </div>
  );
};
