import React, { useState, useEffect, useRef } from "react";

import "./Profile.css";

import { useDispatch, useSelector } from "react-redux";
import TalentProfile from "./components/TalentProfile";
import CareerSummary from "./components/CareerSummary";
import EducationHistory from "./components/EducationHistory/EducationHistory";
import WorkHistory from "./components/WorkHistory/WorkHistory";
import SkillListingPanel from "./components/SkillListingPanel";
import SocialAccounts from "../DashBoard/components/SocialAccounts/SocialAccounts";
import { getTalentInfo, resetState } from "../../redux/actions";
import CertificationsAndAwards from "./components/CertificationsAndAwards";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import { alertNotification } from "../../components/notifications/alertNotification";

const Profile = () => {
  const dispatch = useDispatch();
  const [addEdit, setAddEdit] = useState(false);
  const [addEditEd, setAddEditEd] = useState(false);
  const [addNew, setAddNew] = useState(false);

  const skillPanelRef = useRef();

  const authToken = useSelector((state) => state.authReducer.user.authToken);
  const professionCode = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );

  useEffect(() => {
    dispatch(getTalentInfo(authToken));
  }, [authToken]);

  return (
    <div className="profile-container">
      <div className="profile-left">
        <TalentProfile skillPanelRef={skillPanelRef} />
        <div className="left-col-card-2">
          <CareerSummary />
          <div className="dividers"></div>
          <SocialAccounts />
          <div className="dividers"></div>
          <EducationHistory addEditEd={addEditEd} setAddEditEd={setAddEditEd} />
          <div className="dividers"></div>
          <WorkHistory addEdit={addEdit} setAddEdit={setAddEdit} />
          {professionCode === "IT" && (
            <>
              <div className="dividers"></div>
              <CertificationsAndAwards addNew={addNew} setAddNew={setAddNew} />
            </>
          )}
        </div>
      </div>
      <div className="profile-right" ref={skillPanelRef}>
        <SkillListingPanel redirect={"/profile"} />
      </div>
    </div>
  );
};

export default Profile;
