import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import "./SignUp.css";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import RadioInput from "./Components/RadioInput/RadioInput";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import { updateSignupProfile } from "../../redux/actions";
import { updateSignUpProfileApi } from "../../API/API";
import { back, getWizarFlowNumber, next } from "../Auth/components/wizardFlow";

function WorkAuthorization() {

  const currentIndex = 8;

  const windowHeight = window.innerHeight;
  const dispatch = useDispatch();
  const history = useHistory();
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(false);
  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector((state) => state.profileReducer.signupUpdateSuccess);
  const options = [
    {
      VisaStatusId: 5,
      VisaStatusName: "US Resident/Citizen",
      VisaStatusCode: "GC",
    },
    {
      VisaStatusId: 9,
      VisaStatusName: "EAD (Employment Authorization Document)",
      VisaStatusCode: "EA",
    },
    {
      VisaStatusId: 8,
      VisaStatusName: "H1-B",
      VisaStatusCode: "H1",
    },
  ];
  useEffect(() => {
    if (profiledata?.profile?.VisaStatusName) {
      const temp = profiledata.profile.VisaStatusName === "EAD" ? "EAD (Employment Authorization Document)" : profiledata.profile.VisaStatusName;
      
      setSelected(temp);
    }
  }, [profiledata])

  useEffect(() => {
    if (apiSuccess && flag) {
      history.push(next(currentIndex));
    }
  }, [apiSuccess]);

  const nextHandler = () => {
    if (!selected) {
      setError("Please add your authorized to work option to continue")
    } else {
      setFlag(true);
      const temp = [...options];
      let response = temp.find(item => item.VisaStatusName === selected);
      if (selected === "EAD (Employment Authorization Document)") {
        response["VisaStatusName"] = "EAD";
      }

      dispatch(updateSignupProfile({
        "Profiles.VisaStatuses": response,
        WizardFlow: getWizarFlowNumber(currentIndex),
      }))
    }
  }
  const backHandler = () => {
    history.push(back(currentIndex));
  }
  return (
    <div className="NameScreenContainer" 
    // style={{ minHeight: windowHeight }}
    >
      {typeof apiError === "string" && <Alert message={apiError} type="error" className="alert-error" showIcon closable />}
      <ProfileCompletion currenPage={8} totalPage={10} />
      <RadioInput
        title={"What are you authorized to work as?"}
        type={"Mandatory"}
        onChange={(e) => { setSelected(e.target.value); error && setError(null) }}
        options={options}
        keyVal={"VisaStatusName"}
        value={selected}
        error={error}
      />
      <BottomButtons
        nextHandler={nextHandler}
        nextBtnLoading={loading}
        backHandler={backHandler}
      />
    </div>
  );
}

export default WorkAuthorization;
