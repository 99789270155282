import React, { useState, useEffect } from "react";
import "./CreateSkillListing.css";
import ProgressBar from "./components/Header/Progress";
import { useLocation, useHistory } from "react-router-dom";
import { GigType } from "./components/CreateSkillListing/GigType";
import AddSkillListingTitle from "./components/CreateSkillListing/AddSkillListingTitle";
import DesiredLocation from "./components/CreateSkillListing/DesiredLocation";
import YourSkills from "./components/CreateSkillListing/YourSkills";
import DesiredSalary from "./components/CreateSkillListing/DesiredSalary";
import StartDateSection from "./components/CreateSkillListing/StartDateSection";

export const CreateSkillListing = () => {
  const [page, setPage] = useState("");
  const history = useHistory();
  const location = useLocation();

  return (
    <React.Fragment>
      <div className="create-skill-listing-parent">
        <div className="create-skill-klisting-child">
          <ProgressBar title={"Let’s build your skill listing"} />
          {location.pathname === "/create-skilllisting-gig-type" ? (
            <GigType page={page} setPage={setPage} />
          ) : location.pathname === "/create-skilllisting-title" ? (
            <AddSkillListingTitle />
          ) : location.pathname === "/create-skilllisting-location" ? (
            <DesiredLocation />
          ) : location.pathname === "/create-skilllisting-skill" ? (
            <YourSkills />
          ) : location.pathname === "/create-skilllisting-salary" ? (
            <DesiredSalary />
          ) : (
            location.pathname === "/create-skilllisting-date" && (
              <StartDateSection />
            )
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateSkillListing;
