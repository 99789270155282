import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import "./ManageSkillListing.css";
import moment from "moment";
import { Select, Menu, Dropdown, message, Modal } from "antd";
import { Form, Input, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import ModalDelete from "./ModalDelete";
import ModalEdit from "./ModalEdit";
// icons
import eyesign from "../../assets/img/eye.png";
import { deleteSkillListing, fetchUserSkillListing } from "../../redux/actions";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import { EyeOutlined } from "@ant-design/icons";
import { BsFillEyeFill } from "react-icons/bs";

const ManageSkillListingtwo = (props) => {
  const history = useHistory();
  const location = useLocation()
  const { Option } = Select;
  const [isHovered, setIsHovered] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false); // For controlling visibility in mobile
  const tooltipRef = useRef(null);

  const isWeb = useMediaQuery({ minWidth: 768 });
  const { skillListings } = props;
  const [skillFiller, setSkillFiller] = useState({});
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [editModalState, setEditModalState] = useState(false);

  
  const singleSkillListing = useSelector(
    (state) => state.skillListingReducer?.singleSkillListing
  );
   const professionCode = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode );



  // useUpdateLogger("skillListings", skillListings);
  
  useEffect(() => {
    if (props.formSkills) {
      const skills = props.formSkills.skillListings;
      // // 
      const skill = skills[skills.length - 1];
      // // 
      setSkillFiller(skill);
    }
  }, [props.formSkills]);

  const handleUpdateSkillListing = (state) => {
    setEditModalState(state);
  };
 
  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setIsHovered(false);
      setIsTooltipVisible(false); 
    }
  };

  useEffect(() => {
    if (isHovered || isTooltipVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isHovered, isTooltipVisible]);
  const menu = (
    <Menu
      className="editDeleteDropdownSkill"
      // onClick={(e) => e.stopPropagation()}
    >
      <Menu.Item
        className="editDropdownSkill"
        key="1"
        onClick={(e) => {
          if(professionCode == "HC"){ 
            const skills = props?.formSkills?.skillListings?.data;
            const skillListing = skills?.filter((el) => el._id === props.skillListings._id)[0];
            history.push(`/skill-listing-detail-page/${skillListing?.SkillListingId}`);
          }else{
            // e.stopPropagation();
            handleUpdateSkillListing(true);

          }
        }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        className="deleteDropdownSkill"
        key="2"
        onClick={(e) => {
          // e.stopPropagation();
          setDeleteConfirmModal(true);
        }}
      >
        <span>Delete</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="skillisting-card-outer-box">
      {props.skillListings.SkillListingStatuses.SkillListingStatusName ===
      "Published" ? (
        <div
          className={`${"card-inner-box"} clickable`}
          onClick={() =>
            history.push({
              pathname: `/skill-listing-detail-page/${props.skillListings.SkillListingId}`,
              state: { SkillListing: props.skillListings },
            })
          }
        >
          <div className="dropdown-title-skilllisting">
          <Tooltip
          placement="topRight"
      title={
        <span
          onClick={(e) => {
            e.preventDefault(); 
            e.stopPropagation();
          }}
        >
          {(props.skillListings?.DisplayTitle || props.skillListings.Title).slice(0, 100)}
        </span>
      }
      visible={isWeb ? isHovered : isTooltipVisible} 
    >
      <span
        ref={tooltipRef} 
        className="skillfont"
        onMouseEnter={() => isWeb && setIsHovered(true)} 
        onMouseLeave={() => isWeb && setIsHovered(false)} 
        onClick={(e) => {
          if (!isWeb) { 
            e.preventDefault();
            e.stopPropagation();
            setIsTooltipVisible((prev) => !prev); 
          }
        }}
      >
        {props.skillListings?.DisplayTitle
          ? props.skillListings.DisplayTitle.slice(0, 100)
          : props.skillListings.Title.slice(0, 100)}
      </span>
    </Tooltip>

            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown.Button
                overlayClassName="dropdownClassSkillL"
                // onClick={(e) => e.stopPropagation()}
                overlay={menu}
                trigger={["click"]}
              >
                <ModalEdit
                  editModalState={editModalState}
                  setEditModalState={setEditModalState}
                  handleUpdateSkillListing={handleUpdateSkillListing}
                  id={props.skillListings._id}
                  // idSkill={props.skillListings.SkillListingId}
                />
                <ModalDelete
                  deleteSkillListing={props.deleteSkillListing}
                  deleteConfirmModal={deleteConfirmModal}
                  setDeleteConfirmModal={setDeleteConfirmModal}
                  id={props.skillListings._id}
                />
              </Dropdown.Button>
            </div>
          </div>
          <div className="locationfont">
            {props?.skillListings?.SkillListings_Cities?.length > 0 &&
              props?.skillListings?.SkillListings_Cities?.map((loc) => {
                

                // 
                return (
                  <span>
                    {" "}
                    {loc?.Cities?.Location || loc?.Location ? `${loc?.Cities?.Location || loc?.Location}` : "N/A"}



                  </span>
                ); // space required
              })}
          </div>
            {props?.skillListings?.MinimumBid?.$numberDecimal || props?.skillListings?.MinimumBid  && (
            <div className="salaryfont">
                {`${professionCode === "IT" && props.skillListings.MinimumBidType === "Year" ? "Desired Annual Salary" : professionCode === "IT" && props.skillListings.MinimumBidType === "Hour" ? "Desired Pay Rate" : "Desired Salary"} $${
                props?.skillListings?.MinimumBid?.$numberDecimal
                  ? props?.skillListings?.MinimumBid?.$numberDecimal
                  : props?.skillListings?.MinimumBid?.toLocaleString('en-US')
                  ? props?.skillListings?.MinimumBid?.toLocaleString('en-US')
                  : 0
              }${
                props.skillListings.MinimumBidType !== "Year" ? "/HR" : "/YR"
              }`}
            </div>
            )}
          <div className="contain">
            <div className="publishedfontframe">
              <span className="publishedfont">Posted</span>
            </div>
            <span className="daysfont">
              {moment(props.skillListings.Created).fromNow()}
            </span>
          </div>
          <div className="manage-skillisting-status">
            <span
              className="eyeicon"
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  "/view-listing/" + props.skillListings?.SkillListingId
                );
              }}
            >
              {/* <BsEyeFill /> */}{" "}
              <BsFillEyeFill className="viewsManageSkillisting eyeIconStyle" />
            </span>
            <span
              className="eyeiconviews"
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  "/view-listing/" + props.skillListings?.SkillListingId
                );
              }}
            >
              {skillListings?.views?.length > 0
                ? skillListings?.views?.length
                : 0}
            </span>
            <span
              className="bidfont"
              onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: "/receivedBids",
                  state: {
                    skillListingId: props.skillListings.SkillListingId,
                  },
                });
              }}
            >
              {skillListings?.Bids?.length ? skillListings?.Bids?.length : 0}
              {skillListings?.Bids?.length > 0 ? " Bids" : " Bid"}
            </span>
          </div>
        </div>
      ) : (
        <div>
          <div className="skillisting-card-outer-box">
            <div
              className={`${
                // props.deletedData.length > 1
                // ?
                "Rectangle-17523deleted"
                // : "singleSkillListingDeleted"
              }`}
            >
              <div className="skillfontdeleted">
                {props.skillListings?.DisplayTitle
                  ? props.skillListings?.DisplayTitle
                  : props.skillListings.Title}
              </div>
              <div className="locationfontdeleted">
                {props?.skillListings?.SkillListings_Cities?.length > 0 &&
                  props?.skillListings?.SkillListings_Cities?.map((loc) => {
                    // // 
                    return (
                      <span>
                        {" "}
                        {loc?.Cities?.Location
                          ? `${loc?.Cities?.Location}`
                          : "N/A"}
                      </span>
                    ); // space required
                  })}
              </div>
              <div className="salaryfontdeleted">
                {`Desired salary $${
                  props?.skillListings?.MinimumBid?.$numberDecimal
                    ? props?.skillListings?.MinimumBid?.$numberDecimal
                    : props?.skillListings?.MinimumBid
                    ? props?.skillListings?.MinimumBid
                    : 0
                }${
                  props.skillListings.MinimumBidType !== "Year" ? "/HR" : "/YR"
                }`}
              </div>
              <div className="containdeleted">
                <div className="publishedfontframedeleted">
                  <span className="publishedfontdeleted">Deleted</span>
                </div>
                <span className="daysfontdeleted">
                  {moment(props.skillListings.Created).fromNow()}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataForm: state.credentialingReducer.step3,
    formSkills: state.skillListingReducer,
    searchedDiscipline: state.skillListingReducer.searchedDiscipline,
    disciplineLoader: state.skillListingReducer.disciplineLoader,
    currentSkillListing: state.skillListingReducer.currentSkillListing,
    // loadingResume: state.docReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSkillListing: () => {
      dispatch(fetchUserSkillListing());
    },
    deleteSkillListing: (id) => {
      dispatch(deleteSkillListing(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSkillListingtwo);
