import React, { useEffect, useState } from "react";
import "./RadioInput.css";
import { Radio } from "antd";
import { WarningFilled } from "@ant-design/icons";
function RadioInput({ title, type, onChange, options, keyVal, value, error ,gap}) {
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  // const typeComponent =(type)=>{
  //   return(
  //     <span className="radioInputType">({type})</span>
  //   )
  // }
  return (
    <div className="radioInputParentContainer">
      <div className="radioInputContainer">
        <div className={dimensions < 768 ?"radioInputTitleContainer-cell":"radioInputTitleContainer"}>
          <div className="radioInputTitle">{`${title} \u00A0 \u00A0`}<span className="radioInputType">{`(${type})`}</span></div>
          {/* {dimensions < 768 || title?.length>45 ?
            <span className="radioInputType-cell">{`(${type})`}</span>
            :
            <div className="radioInputType">({type})</div>
          } */}
        </div>
        <Radio.Group
          onChange={onChange}
          value={value}
          name={title}
          className="radioinput-group"
          style={{ gap: gap || "15px" }}
          status={error ? "error" : ''}
          size="large"
        >
          {options.map((opt) => {
            return (
              <Radio value={keyVal ? opt[keyVal] : opt} className="radioinput-styles">
                {keyVal ? opt[keyVal]?.replace(/-/g, ' - ') : opt}
              </Radio>
            );
          })}
        </Radio.Group>
        {error && 
          <div className="errorMsg">
          <WarningFilled className="errorIcon"/>{error}
          </div>
        }
      </div>
    </div>
  );
}
export default RadioInput;
