import React, { useState, useEffect, useCallback } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import errorIcon from "../../../assets/img/ci_warning.png";
import { AutoComplete, Spin, Select } from "antd";
import { debounce } from "../../../shared/helpers";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { fetchKeySearchDiscipline } from "../../../redux/actions";
import BottomButtons from "../../../pages/SignUp/NewCreateSkillListing/components/Footer/BottomButtons";
import SkillListingTips from '../../../pages/SignUp/Components/SkillListingTips/SkillListingTips';
import { useHistory } from "react-router-dom";
import SkillListingTittleToolTip from "../../../pages/SignUp/Components/SkillListingTips/SkillListingTittleToolTip";

const EditTitle = () => {
  const ProfessionCodes = useSelector(
    (state) => state.profileReducer?.user?.profile?.ProfessionCode
  );
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [showError, setShowError] = useState(false);
  const [charLimitError, setCharLimitError] = useState(false);
  const [flag, setFlag] = useState(false);
  const [noDataViewTitle, setNoDataViewTitle] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { Option } = Select;
  const history = useHistory();
  const { ProfessionCode } = useSelector(
    (state) => state.profileReducer.user.profile
  );
  const profiledata = useSelector((state) => state.profileReducer.user);
  const discipline = profiledata?.profile?.Discipline;
  const speciality = profiledata?.profile?.Speciality;
    const disciplineLoader = useSelector(
      (state) => state.skillListingReducer.disciplineLoader
    );
    const searchedDiscipline = useSelector(
        (state) => state.skillListingReducer.searchedDiscipline
      );
    const getInputValues = JSON.parse(localStorage.getItem('SkillListing')) || []; 

    useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let totalSuggestions = speciality?.map((specs) => {
      return discipline + " - " + specs;
    });
    setSuggestions(totalSuggestions?.length ? totalSuggestions : []);
  }, [discipline, speciality]);

  useEffect(() => {
    setTitle(getInputValues?.Title);
  }, [getInputValues?.Title]);


  const saveSearchDiscipline = (value) => {
    setTitle(value);
    setShowError(false);
    setCharLimitError(value.length > 100);
  };

  const onSearch = (val) => {
    setNoDataViewTitle(val);
    dispatch(fetchKeySearchDiscipline({ val: val, profCode: ProfessionCode }));
  };

  const debouncedOnTitleSearch = debounce((val, profCode) => {
    setNoDataViewTitle(val);
    dispatch(fetchKeySearchDiscipline({ val: val, profCode }));
  }, 700);

  const debouceTitleSearchRequest = useCallback(
    (value) => debouncedOnTitleSearch(value, ProfessionCode),
    [ProfessionCode]
  );

  const handleNext = () => {
    if (title && !charLimitError) {
      setFlag(true);
      localStorage.setItem('SkillListing', JSON.stringify({
        ...getInputValues, 
       Title: title
      }));
      history.push('/edit-skill')
    } else {
      setShowError(!title);
    }
  };

  const handleBack = () => {
    history.push('/edit-gig-type');
  };

  const displaySuggestions = () => {
    return (
      <div className="skill-listing-title-suggestions-container">
        <span>Suggestions based on your discipline and speciality</span>
        {suggestions?.map((suggestion, index) => {
          return (
            <div
              key={index}
              className="skill-listing-title-suggestion"
              onClick={() => {
                setTitle(suggestion);
                setShowError(false);
                setCharLimitError(false);
              }}
            >
              <span>{suggestion}</span>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="CreateSkillListingHeaderScreen2">
      <div className="create-skill-listing-title create-skill-input">
        Add Skill listing title{" "}
        <span className="Mandatory-Text" style={{position:'relative', left:'-12px', bottom: '2px'}}>(Mandatory)</span>
        {screenWidth < 769 && (
          <Tooltip
            className="gig-type-Tooltip"
            title={<SkillListingTittleToolTip />}
            overlayClassName='gig-tooltip'
          >
            <div  style={{position:'relative', bottom: '3px'}}><InfoCircleOutlined /></div>
          </Tooltip>
        )}
      </div>
      <div className="skill-listing-title-tip-container">
        <div className="skill-listing-title-suggestion-cont">
          <AutoComplete
            autoFocus
            bordered={false}
            className={`desired-loc-skill${
              showError || charLimitError ? " error-block" : ""
            } title`}
            name="roleDiscipline"
            value={title}
            onChange={saveSearchDiscipline}
            onSearch={(val) => debouceTitleSearchRequest(val)}
            placeholder={
              <div className="placeholderSkillListingFormOne">
                {ProfessionCode === "HC" ? "Add title" : "ex. Java developer"}
              </div>
            }
            notFoundContent={
              noDataViewTitle?.length > 0 ? (
                disciplineLoader ||
                searchedDiscipline.length > 0 ? (
                  <div>
                    <Spin size="small" />
                  </div>
                ) : (
                  "No data found!"
                )
              ) : null
            }
            showSearch
          >
            {searchedDiscipline &&
              searchedDiscipline
                ?.filter((discipline) => discipline?.Name?.length > 0)
                ?.map((discipline) => (
                  <Option value={discipline?.Name} key={discipline?._id}>
                    {discipline?.Name}
                  </Option>
                ))}
          </AutoComplete>
          {showError && (
            <div className="error-msg-content">
              <img src={errorIcon} alt="" />
              <span className="error-text">
                Please enter the skill listing title to continue
              </span>
            </div>
          )}
          {charLimitError && (
            <div className="error-msg-content">
              <img src={errorIcon} alt="" />
              <span className="error-text">
                Exceeded maximum character length of 100
              </span>
            </div>
          )}
          {suggestions?.length > 0 && ProfessionCode === "HC"
            ? displaySuggestions()
            : null}
        </div>

        {screenWidth > 1000 && (
          <div className="CreateSkillListing-tip-cont">
            <SkillListingTips
              pageType="skill-listing-title"
              ProfessionCode={ProfessionCode}
            />
          </div>
        )}
      </div>
      <div style={{marginTop: '-36px'}}>
			<BottomButtons
					handleBack={handleBack}
					handleNext={handleNext}
          isBack={false}
				/>
			</div>
    </div>
  );
};
export default EditTitle;