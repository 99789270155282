import React, { useState, useEffect, useCallback } from "react";
import { WarningFilled } from "@ant-design/icons"; 
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateSignupProfile } from "../../../../redux/actions";
import { debounce } from "../../../../shared/helpers";
import BottomButtons from "../BottomBottons/BottomButtons";
import socialsecuirty from "../../../../assets/icons/socialsecurity.svg";
import { handleAlertVisibility } from "../../../../assets/helpers";

const EditSocialSecuity = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [SSN, setSSN] = useState("");
  const [error, setError] = useState("");

  const profileData = useSelector((state) => state.profileReducer.user);
  const isEmail = useSelector(
    (state) => state.profileReducer?.talentApiData?.Profiles?.IsEmailVerified
  );
  useEffect(() => {
    if (profileData?.profile?.SocialSecurityNumber) {
      setSSN(formatSSN(profileData.profile.SocialSecurityNumber));
    }
  }, [profileData]);

  const formatSSN = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    if (cleaned.length > 9) {
      return SSN;
    }
    const match = cleaned.match(/^(\d{0,3})(\d{0,2})(\d{0,4})$/);
    if (match) {
      return `${match[1]}${match[2] ? "-" : ""}${match[2]}${match[3] ? "-" : ""}${match[3]}`;
    }
    return value;
  };

  const updateValue = (e) => {
    setError("");
    setSSN(formatSSN(e.target.value));
  };

  const validateSSN = () => {
    const cleanedSSN = SSN.replace(/[^0-9]/g, "");
    if (!SSN || cleanedSSN.length !== 9) {
      setError("Please enter your SSN");
      return false;
    }
    return true;
  };

  const nextHandler = () => {
    if (validateSSN()) {
      const cleanedSSN = SSN.replace(/[^0-9]/g, "");
      dispatch(
        updateSignupProfile({
          "Profiles.SocialSecurityNumber": cleanedSSN,
        })
      );
      history.push("/profile", { success: true });
    }
  };

  return (
    <div
      className="ProfileCompletionParent"
      style={{marginTop: handleAlertVisibility(isEmail)}}
    >
      <div className="ProfileCompletion">
        <div className="InputTitleContainer">
          <div className="TextInput">Your SSN</div>
          <div className="Text-Type">(optional)</div>
        </div>
        <div className="Sub-ssn">
          Enter your social security number & earn + 0.5 rating to your profile
        </div>
        <div className="TextInputField">
          <input
            type="text"
            className="InputText"
            value={SSN}
            onChange={updateValue}
            placeholder="000-00-0000"
          />
          {error && (
            <div
              className="error-text"
              style={{
                marginTop: "10px",
              }}
            >
              <WarningFilled style={{ color: "red", marginRight: "8px" }} />
              {error}
            </div>
          )}
        </div>
        <div className="text">
          <img className="Ssn-Icon" src={socialsecuirty} alt="SSN Icon" />
          <p className="subtext">
            <span style={{ fontWeight: "600" }}>
              Your SSN is secure with SkillGigs.
            </span>
            <span style={{ fontSize: "13px", fontWeight: "400" }}>
              We are JCAHO certified and your Social Security Number (SSN) is
              encrypted and stored securely to protect your privacy.
            </span>
          </p>
        </div>
      </div>
      <BottomButtons
        nextHandler={nextHandler}
        backButtonText="Cancel"
        nextButtonText="Save"
        backHandler={() => history.push("/profile")}
        NextText="Save"
      />
    </div>
  );
};

export default EditSocialSecuity;
