import React from "react";
import { Progress } from "antd";
import "./Progress.css";
import { useLocation } from "react-router-dom";

export const ProgressBar = (props) => {
  const { title } = props;
  let progressPercent = 0;
  const location = useLocation();

  switch (location.pathname) {
    case "/create-skilllisting-gig-type":
      progressPercent = 75;
      break;
    case "/create-skilllisting-title":
      progressPercent = 80;
      break;
    case "/create-skilllisting-skill":
      progressPercent = 85;
      break;
    case "/create-skilllisting-location":
      progressPercent = 90;
      break;
    case "/create-skilllisting-salary":
      progressPercent = 95;
      break;
    case "/create-skilllisting-date":
      progressPercent = 100;
      break;
    case "/edit-gig-type":
      progressPercent = 17;
      break;
    case "/edit-skill-title":
      progressPercent = 34;
      break;
    case "/edit-skill":
      progressPercent = 51;
       break;
    case "/edit-location":
      progressPercent = 68;
       break; 
    case "/edit-desired-pay":
      progressPercent = 85;
      break;
    case "/edit-start-date":
      progressPercent = 100;
      break;
    default:
      progressPercent = 0;
  }

  return (
    <React.Fragment>
      <span className="Create-listing-heading">
        {title}
      </span>
      <div className="progres-bar-create">
        <Progress
          size={[, 5]}
          percent={progressPercent}
          showInfo={false}
          strokeColor="#118936"
        />
        <div className="back-btn-bt">
         
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProgressBar;
