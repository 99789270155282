import React, { useEffect, useState } from "react";
import "./TalentProfile.css";
import { useHistory,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./TalentProfile.css";
import { message, notification } from "antd";
import {
  downloadResumeInit,
  fetchKeySearchLocation,
  fetchUserProfile,
  fetchProfileExperience,
  updateProfile,
  uploadResume,
} from "../../../redux/actions";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";

import locIcon from "../../../assets/icons/carbon_location-filled.svg";
import suitcaseIcon from "../../../assets/icons/ps_suitcase.svg";
import personIcon from "../../../assets/icons/eva_person-fill.svg";
import chatIcon from "../../../assets/icons/Group_20655.svg";
import threeDIcon from "../../../assets/icons/351995_3d_rotation_icon.svg";
import { AiOutlineEdit } from "react-icons/ai";
import { FiUserCheck } from "react-icons/fi";
import { TbCertificate } from "react-icons/tb";
import { BsExclamationCircleFill } from "react-icons/bs";
import { Select, Spin, Tooltip } from "antd";
import ProfileAvatar from "../../../components/ProfileAvatar";
import _3D_Resume from "../../_3D_Resume/_3D_Resume";
import FiPhone from "../../../assets/icons/fluent-call-16-regular.svg";
import SSN from "../../../assets/icons/SSN.svg";
import Discipline from "../../../assets/icons/Discipline.svg";
import Speciality from "../../../assets/icons/Speciality.svg";
import StarRating from "../../../assets/icons/StarRating.svg";
import Email from "../../../assets/icons/Email.svg";
import verificationIcon from "../../../assets/icons/verificationIcon.svg"
import { BsDownload, BsFacebook, BsLinkedin } from "react-icons/bs";
import UpdateContactNumber from "./UpdateContactNumber";
import { useRef } from "react";
import { alertNotification } from "../../../components/notifications/alertNotification";
import {
  getCountryPhoneCode,
  getFullStateName,
} from "../../WelcomeWizard/helper";
import { ProfileAvtar } from "../../../components/ProfileAvtar/ProfileAvtar";
import ToolTips from "../../../components/ToolTip/ToolTips";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";
import { formatPhoneNumber } from "../../../assets/helpers";
import { Modal } from "antd";

import RoleExperienceNew from "../../WelcomeWizard/RoleExperienceNew";
import { setGlobal } from "@antv/g2plot";
import { LoadingOutlined } from "@ant-design/icons";
import { EmployerAssociation } from "../../NewDashboard/Components/ProfileSectionV2.2/EmployerAssociation";
const TalentProfile = (props) => {
  const { skillListingDetailPage } = props;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
  });
  const searchedLocation = useSelector(
    (state) => state.credentialingReducer.searchedLocation
  );

  const searchLoading = useSelector(
    (state) => state.credentialingReducer.searchLoading
  );

  const profession = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );
  const { user, loadingPP: loading } = useSelector(
    (state) => state.profileReducer
  );
  const savedLocation = useSelector(
    (state) => state.profileReducer.user.profile?.Location?.LocationWithCountry
  );
  const savedLocationUpdate = useSelector(
    (state) => state.profileReducer.user.profile
  );

  const verifiedEmail = useSelector(
    (state) => state.profileReducer?.talentApiData?.EmailAddress
  );
  const verifyName = useSelector(
    (state) => state.profileReducer.user.profile?.FirstName
  );
  const isEmail = useSelector(
    (state) => state.profileReducer?.talentApiData?.Profiles?.IsEmailVerified
  );

  const resume = useSelector(
    (state) => state.profileReducer.user?.profile?.ResumeFilename
  );
  const { downloadURL } = useSelector(
    (state) => state.profileReducer.downloadURL
  );
  const BackgroundCheck = useSelector(
    (state) => state.profileReducer?.user?.profile?.BackgroundCheck
  );

  const VisaStatusName = useSelector(
    (state) => state.profileReducer?.user?.profile?.VisaStatusName
  );

  const VisaStatusCode = useSelector(
    (state) => state.profileReducer?.user?.profile?.VisaStatusCode
  );

  // const docID = useSelector(
  //   (state) => state.profileReducer?.user?.profile?.ResumeDocID?._id
  // );

  const docID = useSelector(
    (state) => state.profileReducer?.talentInfo?.ResumeDocID?._id
  );
  const EmployeeAssociation = useSelector(
    (state) => state.profileReducer?.user?.EmployeeAssociation || []
  );

  const profile = useSelector((state) => state.profileReducer?.user?.profiles);
  const resumeloading = useSelector(
    (state) => state.profileReducer.resumeloading
  );

  const [styleEditDelete, setStyleEditDelete] = useState({ display: "none" });
  const [styleEditDeleteExp, setStyleEditDeleteExp] = useState({
    display: "none",
  });
  const resumeInputRef = useRef();
  const [styleContact, setStyleContact] = useState({ display: "none" });
  const [isNumberUpdate, setIsNumberUpdate] = useState(false);
  const [isResumeUploaded, setIsResumeUploaded] = useState(false);
  const [searchSaveLoading, setSearchSaveLoading] = useState(false);
  const [locationSearhState, setLocationSearchState] = useState(false);
  const [noDataViewLocation, setNoDataViewLocation] = useState("");
  const [flagcode, setflagcode] = useState("US");
  const [Countrycode, setCountrycode] = useState(1);

  const [experienceSaveLoading, setExperienceSaveLoading] = useState(false);
  const [workExperienceState, setWorkExperienceState] = useState(false);
  const [workExperience, setWorkExperience] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);

  const [editModalStateRL, setEditModalStateRL] = useState(false);

  const [theLoading, setTheLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  // useUpdateLogger("Date:", user);

  // Search Location Logic

  const onSearchLocation = (input) => {
    setNoDataViewLocation(input);
    dispatch(fetchKeySearchLocation(input));
  };

  const handleLocationSelect = (Location) => {
    setStyleEditDelete({ display: "none" });
    setSearchSaveLoading(true);
    setLocationSearchState(false);
    dispatch(
      updateProfile({
        userId: user.id,
        Profiles: { Location: JSON.parse(Location) },
      })
    );
  };

  useEffect(() => {
    if (location.state?.success) {
      alertNotification("success", "Changes successfully updated");

      history.replace({
        pathname: location?.pathname,
        state: {}
      });
    }
  }, [location.state, history]);



  useEffect(() => {
    setSearchSaveLoading(false);
  }, [flagcode]);

  // Experience Logic
  useEffect(() => {
    dispatch(fetchProfileExperience());
    dispatch(fetchUserProfile());
  }, []);

  useEffect(() => {
    const sortWork = user.workExperience.sort(
      (a, b) => a.ExperienceLevelId - b.ExperienceLevelId
    );
    setWorkExperience(sortWork);
  }, [user.workExperience]);

  const handleExperience = (e) => {
    setExperienceSaveLoading(true);
    setWorkExperienceState(false);
    setStyleEditDeleteExp({ display: "none" });
    const experienceObj = JSON.parse(e);
    const { ExperienceLevelId, ExperienceLevelCode, ExperienceLevelName } =
      experienceObj;
    //
    dispatch(
      updateProfile({
        userId: user.id,
        Profiles: {
          ExperienceLevelId,
          ExperienceLevelCode,
          ExperienceLevelName,
        },
      })
    );
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = ["pdf", "jpeg", "jpg", "png", "doc", "docx"];
    const fileExtension = file?.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      alertNotification("warning", "File format not supported");
      resumeInputRef.current.value = "";
      return;
    }

    if (file?.size >= 10590617) {
      alertNotification("warning", "File size is larger than 10 MB");
      resumeInputRef.current.value = "";
      return;
    }

    if (
      e.target.name === "resume" &&
      allowedExtensions.includes(fileExtension)
    ) {
      const payload = {
        file,
        fileName: e.target.files[0].name,
        fileSize: file.size.toString(),
        userId: user?.id,
        documentId: docID,
        type: "credentialingDoc",
      };

      dispatch(uploadResume(payload));
      setIsResumeUploaded(true);
      setSelectedFile(true);
      resumeInputRef.current.value = "";
    }
  };

  const onResumeDownloadBtn = () => {
    const fileURL = `${process.env.REACT_APP_S3_URL}${user?.profile?.ResumeFilename?.charAt(0) != "/" ? "/" : ""
      }${user?.profile?.ResumeFilename}`;
    fetch(fileURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", user?.profile?.ResumeFilename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        alertNotification("success", "Resume downloaded successfully");
      });
  };

  useEffect(() => {
    setExperienceSaveLoading(false);
    setTheLoading(false);
  }, [user.profile]);

  // useEffect(() => {
  //
  // }, [loading]);

  useEffect(() => {
    const fileName = user?.profile?.ResumeFilename
      ? user?.profile?.ResumeFilename.replace("content/certificates/", "")
      : "";
    // //
    if (fileName) {
      // //
      dispatch(downloadResumeInit(fileName));
    }
    setflagcode(getFullStateName(savedLocationUpdate?.Countries?.CountryName));
    setCountrycode(
      getCountryPhoneCode(savedLocationUpdate?.Countries?.CountryName)
    );
  }, [
    user?.profile?.ResumeFilename,
    savedLocationUpdate?.Countries?.CountryName,
  ]);

  useEffect(() => {
    // if (dimensions < 768 || skillListingDetailPage)
    if (skillListingDetailPage) {
      setStyleEditDelete({
        display: "none",
      });
      setStyleContact({
        display: "none",
      });
      setStyleEditDeleteExp({
        display: "none",
      });
    } else {
      setStyleEditDelete({
        display: "inline",
      });
      setStyleContact({
        display: "inline",
      });
      setStyleEditDeleteExp({
        display: "inline",
      });
    }
  }, [skillListingDetailPage]);

  useEffect(() => {
    if (selectedFile && !resumeloading) {
      setSelectedFile(false);
      alertNotification(
        "success",
        `Resume ${user?.profile?.ResumeFilename ? "updated" : "uploaded"
        } successfully`
      );
    }
  }, [selectedFile, resumeloading]);

  // useEffect(() => {
  //   //
  //     "resume:user?.profile?.ResumeFilename",
  //     user?.profile?.ResumeFilename
  //   );
  //   //
  //   //
  // }, [user?.profile?.ResumeFilename, downloadURL, isResumeUploaded]);
  const getPositionParameter = (length) => {
    switch (length) {
      case 1:
        return '10px'
      case 2: return '55px'
      case 3: return '65px'
      case 4: return '80px'
      case 5: return '95px'
      default: return '110px'

    }
  }

  return (
    <div style={{ position: "relative" }} className="left-col-card">
      {/* <div style={{ position: "relative" }}> */}
      <div
        style={{
          position: "absolute",
          right: getPositionParameter(EmployeeAssociation.length),
          top: skillListingDetailPage ? "3px" : '10px',
          zIndex: 999,
        }}
      >
        <EmployerAssociation />
        {/* </div> */}
      </div>
      <div className="updateProfile clickable" style={{ marginTop: EmployeeAssociation.length && skillListingDetailPage ? '15px' : '0px' }}>
        {skillListingDetailPage && (
          <div onClick={() => history.push("/profile")}>Update profile</div>
        )}
      </div>
      <div
        className={`profile-avatar ${skillListingDetailPage && "profile-avatar-skill"
          }`}
      >
        <ProfileAvtar
          loading={loading}
          profilePic={user.profile.PictureUrl}
          name={user?.profile?.FirstName}
          // userImage="userImage"
          upload={true}
          skillListingDetailPage={skillListingDetailPage}
        />
      </div>
      <div
        className="profile-talent-name"
      // style={{ display: "flex", alignItems: "center" }}
      >
        {user.profile?.FirstName && user.profile?.LastName
          ? user.profile?.FirstName.charAt(0).toUpperCase() +
          user.profile?.FirstName.slice(1).toLowerCase() +
          " " +
          user.profile?.LastName.charAt(0).toUpperCase() +
          user.profile?.LastName.slice(1).toLowerCase()
          : "NA"}{" "}
        <span
          className="clickable"
          style={styleEditDelete}

          // onClick={() => history.push("/Edit-name")}

          onClick={() => {
            if (profession === "IT") {
              if (skillListingDetailPage) return;
              setEditModalStateRL(true);
            } else {
              history.push("/Edit-name");
            }

          }}

        >
          <AiOutlineEdit className="iconColorED" fontSize="1rem" />
        </span>
      </div>
      <div className="email-main">
        <img src={Email} />
        <div className="email-sub">  <span>{verifiedEmail ? verifiedEmail : "N/A"}</span>
          {isEmail ? <img style={{ margin: "0px 5px 0 5px" }} src={verificationIcon} /> : null}
        </div>
        <span
          className="clickable"
          style={styleEditDelete}

          onClick={() => {
            if (profession === "HC") {
              history.push("/Edit-Email");
            }
          }}

        >
          <AiOutlineEdit className="iconColorED" fontSize="1rem" />
        </span>

      </div>
      <div className="Star-rating">
        <div className="rating">
          <div className="star">
            <img src={StarRating} />
            <span>{user?.profile?.StarRating || "N/A"} out of 5</span>
          </div>
        </div>
      </div>

      {skillListingDetailPage && (
        <div className="facebook-linkedin">
          <BsFacebook
            className={`${profile?.Facebook && "clickable"}`}
            fontSize="1rem"
            color="#7e7e7e"
            onClick={() => {
              if (profile?.Facebook) window.open(profile?.Facebook, "_blank");
            }}
          />
          <BsLinkedin
            className={`${profile?.LinkedIn && "clickable"}`}
            fontSize="1rem"
            color="#7e7e7e"
            onClick={() => {
              if (profile?.LinkedIn) window.open(profile?.LinkedIn, "_blank");
            }}
          />
        </div>
      )}
      <div className="profile-dtl-btns">
        <span
          className="bt-3d-resume"
          onClick={() => history.push("/3d_resume")}
        >
          <img className="threeD-resume-icon" src={threeDIcon} />
          <span>{`3D resume`}</span>
        </span>
        {/* <span className="bt-chat" onClick={() => history.push("/messages")}>
          <img height="20px" src={chatIcon} />
        </span> */}
        {dimensions > 720 && (
          <ToolTips
            placement="bottomLeft"
            title="Your 3D resume is a visual profile of your skill density. We calculate your professional experience and skills to generate accurate data for your 3D resume."
          />
        )}
      </div>
      <div className="profile-dtl-btns uploadButton">
        <span className="bt-3d-resume">
          <label htmlFor="file">
            {resumeloading ? (
              <LoadingOutlined />
            ) : (
              `${!isResumeUploaded && !resume ? "Upload" : "Update"} Resume`
            )}
          </label>
          <input
            type="file"
            id="file"
            ref={resumeInputRef}
            name="resume"
            accept=".pdf,.jpeg,.png,.jpg,.doc,.docx"
            style={{ display: "none" }}
            onChange={(e) => onChangeFile(e)}
          />
        </span>
        {(isResumeUploaded || resume) && (
          <span className="bt-chat downloadButton">
            <a onClick={() => onResumeDownloadBtn()}>
              <BsDownload />
            </a>
          </span>
        )}
      </div>
      <div className="divider-line"></div>
      {theLoading ? (
        <Loader size="3rem" height="5rem" scrolling={false} />
      ) : (
        <div
          className="basic-profile-dtls"
          onBlur={() => {
            setLocationSearchState(false);
            setWorkExperienceState(false);
          }}
        >
          <Modal
            centered
            footer={false}
            width="47.50rem"
            visible={editModalStateRL}
            destroyOnClose={true}
            wrapClassName="modalEditSkillListing profileModelRoleExperience"
            onCancel={() => setEditModalStateRL(false)}
            title={<div className="createSkillistingTitle">Edit Profile</div>}
          >
            <RoleExperienceNew
              setEditModalStateRL={setEditModalStateRL}
              talentProfile={true}
              setTheLoading={setTheLoading}
            />
          </Modal>
          <div className="basic-item">
            {searchSaveLoading ? (
              <Loader size="1rem" height="0.5rem" scrolling={false} />
            ) : (
              <>
                {locationSearhState ? (
                  <Select
                    notFoundContent={
                      noDataViewLocation?.length > 0 ? (
                        <div>
                          {searchLoading || searchedLocation.length > 0 ? (
                            <Spin size="small" />
                          ) : (
                            "No data found!"
                          )}
                        </div>
                      ) : null
                    }
                    showArrow={false}
                    placeholder="Add your preferred location"
                    onChange={handleLocationSelect}
                    onSearch={onSearchLocation}
                    className="locationSearch-TP"
                    showSearch
                    autoFocus={true}
                  >
                    {searchedLocation.map((loc) => {
                      return (
                        <Select.Option
                          key={loc.Location}
                          value={JSON.stringify(loc)}
                        >
                          {loc.Location}
                        </Select.Option>
                      );
                    })}
                  </Select>
                ) : (
                  <>
                    <span>
                      <img height="18px" width="18px" src={locIcon} />
                    </span>
                    <div
                      className="basic-item-div"
                    // onMouseEnter={(e) => {
                    //   if (dimensions < 768 || skillListingDetailPage) return;
                    //   setStyleEditDelete({
                    //     display: "inline",
                    //     // paddingTop: "5px",
                    //   });
                    // }}
                    // onMouseLeave={(e) => {
                    //   setStyleEditDelete({ display: "none" });
                    // }}
                    >
                      <span className="profile-basic-parameters">
                        {profession == "HC" ? "State Licensed in" : "Your Current Location"}
                      </span>
                      {savedLocation !== null ? (
                        <div
                          className={`${!skillListingDetailPage && "clickable"
                            } profile-basic-parameters-value`}
                          onClick={() => {

                            if (profession === "IT") {
                              if (skillListingDetailPage) {
                                return;
                              }
                              setEditModalStateRL(true);
                            } else {
                              history.push("/Edit-State-Licensed");
                            }
                          }}

                        >
                          <span>
                            {savedLocationUpdate?.Cities?.CityName &&
                              savedLocationUpdate?.Cities?.CityName + ", "}
                            {
                              savedLocationUpdate?.Regions?.Code &&
                              savedLocationUpdate?.Regions?.Code
                              // + ", "
                            }{" "}
                            {/* {savedLocationUpdate?.Countries?.CountryName} */}
                          </span>
                          <span style={styleEditDelete}>
                            <AiOutlineEdit className="iconColorED" />
                          </span>
                        </div>
                      ) : (
                        <span
                          className="addLocation"
                          onClick={() => {
                            if (dimensions < 768) return;
                            setLocationSearchState(true);
                          }}
                        >
                          Add location
                        </span>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="basic-item">
            {experienceSaveLoading ? (
              <Loader size="1rem" height="0.5rem" scrolling={false} />
            ) : (
              <>
                {workExperienceState ? (
                  <Select
                    showArrow={false}
                    className="selectExperience"
                    onChange={handleExperience}
                    placeholder="Choose Experience"
                    autoFocus={true}
                  >
                    {workExperience.map((exp) => (
                      <Select.Option
                        value={JSON.stringify(exp)}
                        key={exp.ExperienceLevelName}
                      >
                        {exp.ExperienceLevelName}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <>
                    <span>
                      <img height="18px" width="18px" src={suitcaseIcon} />
                    </span>
                    <div
                      className="basic-item-div"
                    // onMouseEnter={(e) => {
                    //   if (dimensions < 768 || skillListingDetailPage) return;
                    //   setStyleEditDeleteExp({
                    //     display: "inline",
                    //     // paddingTop: "5px",
                    //   });
                    // }}
                    // onMouseLeave={(e) => {
                    //   setStyleEditDeleteExp({ display: "none" });
                    // }}
                    >
                      <span className="profile-basic-parameters">
                        Experience
                      </span>
                      <div
                        className={`${!skillListingDetailPage && "clickable"
                          } profile-basic-parameters-value`}
                        // onClick={() => {
                        //   if (dimensions < 768 || skillListingDetailPage) return;
                        //   setWorkExperienceState(true);
                        // }}
                        onClick={() => {
                          if(profession == 'IT'){
                            if (skillListingDetailPage) return;
                            setEditModalStateRL(true);
                          }else{
                         history.push('/edit-experience')
                          }
                        }}
                      >
                        <span>{user.profile.ExperienceLevelName}</span>
                        <span style={styleEditDeleteExp}>
                          <AiOutlineEdit className="iconColorED" />
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="basic-item">
            <span>
              <img height="18px" width="18px" src={personIcon} />
            </span>
            <div className="basic-item-div">
              <span className="profile-basic-parameters">Member since</span>
              <span className="profile-basic-parameters-value">
                {user.created_at !== null
                  ? moment(user.created_at).format("MMM DD, YYYY")
                  : ""}
              </span>
            </div>
          </div>
          <div
            className="basic-item"
          // onMouseEnter={(e) => {
          //   if (dimensions < 768 || skillListingDetailPage) return;
          //   setStyleContact({
          //     display: "inline",
          //     // paddingTop: "5px",
          //   });
          // }}
          // onMouseLeave={(e) => {
          //   setStyleContact({ display: "none" });
          // }}
          >
            <span>
              <img src={FiPhone} alt="" />
            </span>
            <div className="basic-item-div">
              <span className="profile-basic-parameters">Contact no</span>
              <span
                className={`${!skillListingDetailPage && "clickable"
                  } profile-basic-parameters-value`}
                // onClick={() => {
                //   if (dimensions < 768 || skillListingDetailPage) return;
                //   setIsNumberUpdate(true);
                // }}
                onClick={() => {
                  if (profession === "IT") {
                    if (skillListingDetailPage) {
                      return;
                    }
                    setEditModalStateRL(true);
                  } else {
                    history.push("/Edit-number");
                  }
                }}

              >
                <span>{`${formatPhoneNumber(
                  user?.profile?.MobilePhone !== undefined
                    ? user?.profile?.MobilePhone?.replace(/[- )(]/g, "")?.slice(
                      -10
                    )
                    : ""
                )}`}</span>
                <span style={styleContact}>
                  <AiOutlineEdit className="iconColorED" />
                </span>
              </span>
              {isNumberUpdate && (
                <UpdateContactNumber
                  contactNumber={user?.profile?.MobilePhone}
                  isNumberUpdate={isNumberUpdate}
                  setIsNumberUpdate={setIsNumberUpdate}
                  flagcode={flagcode?.toLocaleLowerCase()}
                  Countrycode={Countrycode}
                />
              )}
            </div>
          </div>
          {/* {skillListingDetailPage && (
          <> */}
          {/* <div className="basic-item">
            <span className="icon-skill">
              <img  src={personIcon} /> */}
          {/* <FiUserCheck color="#5e5e5e" />
            </span>
            <div className="basic-item-div">
              <span className="profile-basic-parameters">
                Work authorization
              </span>
              <span
                className={`${
                  !skillListingDetailPage && "clickable"
                } profile-basic-parameters-value`}
                onClick={() => {
                  // if (dimensions < 768 || skillListingDetailPage) return;
                  if (skillListingDetailPage) return;
                  setEditModalStateRL(true);
                }}
              >
                {VisaStatusName}{" "}
                <span style={styleEditDeleteExp}>
                  <AiOutlineEdit className="iconColorED" />
                </span>
              </span>
            </div>
          </div> */}
          {/* <div className="basic-item">
            <span>
              <TbCertificate color="#5e5e5e" />
            </span>
            <div className="basic-item-div">
              <span className="profile-basic-parameters">
                Bg or state licensing issues
              </span>
              <span
                className={`${
                  !skillListingDetailPage && "clickable"
                } profile-basic-parameters-value`}
                onClick={() => {
                  // if (dimensions < 768 || skillListingDetailPage) return;
                  if (skillListingDetailPage) return;
                  setEditModalStateRL(true);
                }}
              >
                {BackgroundCheck}{" "}
                <span style={styleEditDeleteExp}>
                  <AiOutlineEdit className="iconColorED" />
                </span>
              </span>
            </div>
          </div> */}

          {profession === "HC" && (<div
            className="basic-item"
          >
            <span>
              <img src={SSN} alt="" />
            </span>
            <div className="basic-item-div">
              <span className="profile-basic-parameters">SSN</span>
              <span
                className={`${!skillListingDetailPage && "clickable"
                  } profile-basic-parameters-value`}
                onClick={() => {
                  if (profession === "HC") {
                    history.push("/Edit-Social-Secuity")

                  }

                }}
              >
                <span>
                  {user?.profile?.SocialSecurityNumber ||
                    user?.profile?.SSN ||
                    "N/A"}
                </span>
                <span style={styleContact}>
                  <AiOutlineEdit className="iconColorED" />
                </span>
              </span>
            </div>
          </div>
          )}

          <div className="basic-item">
            <span>
              <img src={Discipline} alt="" />
            </span>
            <div className="basic-item-div">
              <span className="profile-basic-parameters">Discipline</span>
              <span
                className={`${!skillListingDetailPage && "clickable"
                  } profile-basic-parameters-value`}
                onClick={() => {
                  // if (skillListingDetailPage) return;
                  // setEditModalStateRL(true);
                  if (profession === "HC") {
                    history.push("/Edit-Discipline")

                  }

                }}
              >
                <span>{user?.profile?.Discipline || "N/A"}</span>
                <span style={styleContact}>
                  <AiOutlineEdit className="iconColorED" />
                </span>
              </span>



            </div>
          </div>
          <div className="basic-item">
            <span>
              <img src={Speciality} alt="" />
            </span>
            <div className="basic-item-div">
              <span className="profile-basic-parameters">Speciality</span>
              <div className="Speciality-main">
                {user?.profile?.Speciality?.length ? (
                  <>
                    <div className="speciality-container">
                      {(showMore ? user.profile.Speciality : user.profile.Speciality.slice(0, 3)).map((speciality, index, array) => (
                        <span key={index} className="speciality-item">
                          {speciality}
                          {(index < array.length - 1) && (array.length > 1) && (index < 2 || showMore) && ","}
                        </span>
                      ))}
                      <span
                        className={`${!skillListingDetailPage && "clickable"
                          } profile-basic-parameters-value`}
                        onClick={() => {
                          if (profession === "HC") {
                            history.push("/Edit-Specialty")
                          }
                        }}
                      >
                        <span style={styleContact}>
                          <AiOutlineEdit className="iconColorED" />
                        </span>
                      </span>
                    </div>
                    {user.profile.Speciality.length > 3 && (
                      <span
                        className="view-more"
                        onClick={() => setShowMore((prev) => !prev)}
                      >
                        {showMore ? "View less" : "View more"}
                      </span>
                    )}
                  </>
                ) : (
                  "N/A"
                )}
              </div>
            </div>
          </div>


          {/* {!skillListingDetailPage && (
            <div className="add-edit-profile-website">
              Add / edit profile available only in website
            </div>
          )} */}
        </div>
      )}
      <div className="about-skillListing">
        {/* {!skillListingDetailPage && (
          <>
            <div className="about-p">About</div>
            <div
              className="skillListing-p"
              onClick={() =>
                props.skillPanelRef.current.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              Skill listings
            </div>
          </>
        )} */}
      </div>
    </div>
  );
};

export default TalentProfile;
