import { types } from "../action-types";

const initialState = {
  loading: false,
  skills: [],
  skillListings: [],
  singleSkillListing: {},
  currentSkillListing: null,
  searchedDiscipline: [],
  error: {},
  disciplineLoader: false,
  skillLoader: false,
  loadingSkillListing: false,
  page: 1,
  perPage: window.innerWidth > 768 ? 24 : 20,
  pubfilter: "Published",
  skillListings1: "",

  oneSkillId: null,
  statusCode: null,
};

const skillListingReducer = (state = initialState, action) => {
      switch (action.type) {
       case types.FETCH_SKILLS_INIT:
      return {
        ...state,
        skillLoader: true,
      };

    case types.FETCH_SKILLS_SUCCESS:
      const skills = action.payload;
      return {
        ...state,
        skillLoader: false,
        skills,
        error: {},
      };

    case types.CREATE_SKILL_LISTING_INIT:
      return {
        ...state,
        loadingSkillListing: true,
      };

    case types.UPDATE_SKILL_LISTING_INIT:
      return {
        ...state,
        loadingSkillListing: true,
        statusCode: null
      };

    case types.FETCH_USER_SKILLISTING_INIT:
      return {
        ...state,
        loadingSkillListing: true,
      };

    case types.FETCH_USER_SKILLISTING_SUCCESS:
      return {
        ...state,
        loadingSkillListing: false,
        skillListings: action.payload,
        error: {},
      };

    case types.FETCH_ONE_SKILLLISTING_INIT:
      return {
        ...state,
        // loadingSkillListing: true,
      };

    case types.FETCH_ONE_SKILLLISTING_SUCCESS:
      return {
        ...state,
        // loadingSkillListing: false,
        singleSkillListing: action.payload,
        error: {},
      };

    case types.FETCH_USER_SKILLISTING_SUCCESS_ID:
      return {
        ...state,
        skillListings1: action.payload.data[0]._id,
      };

    case types.UPDATE_SKILL_LISTING_SUCCESS:
      // 
      return {
        ...state,
        loadingSkillListing: false,
        currentSkillListing: {...action.payload.data.data},
        error: {},
        statusCode: action.payload.status
      };

    case types.SET_PUBFILTER_SKILLISTING:
      return {
        ...state,
        loading: false,
        pubfilter: action.payload,
        error: {},
      };

    case types.SET_PAGE_SKILLISTING:
      return {
        ...state,
        loading: false,
        page: action.payload,
        error: {},
      };

    case types.FETCH_SEARCH_DISCIPLINE_INIT:
      return {
        ...state,
        disciplineLoader: true,
      };

    case types.FETCH_SEARCH_DISCIPLINE_SUCCESS:
      // // 
      return {
        ...state,
        loading: false,
        disciplineLoader: false,
        searchedDiscipline: action.payload,
        error: {},
      };

    case types.SKILLLISTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.FETCH_ONE_SKILLLISTING_ID:
      return {
        ...state,
        oneSkillId: action.payload,
      };

    case types.REMOVE_ONE_SKILLLISTING_ID:
      return {
        ...state,
        oneSkillId: null,
      };
    case types.RESET_STATE:
        return {
          ...state,
          statusCode: null,
        }; 

    default:
      return state;
  }
};

export default skillListingReducer;
