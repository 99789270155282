import React from "react";
import UploadDocV2 from "../../UploadDoc";
import './ACLS_DocV2.css'
import { back, getWizarFlowNumber, next } from "../../../Auth/components/wizardFlow";
const ACLS_DocV2 = () => {

  const currentIndex = 13;

  const docInfo = [
    {
      "ACLS - Advanced cardiovascular life support - front": 11,
    },
    {
      "ACLS - Advanced cardiovascular life support - back": 82,
    },
  ];
  const styleContainer = {
    width :"337px"
  }


  return (
    <div className="acls-doc-container">
      <UploadDocV2
        docInfo={docInfo}
        backUrl={back(currentIndex)}
        nextUrl={next(currentIndex)}
        currentPage={3}
        WizardFlow={getWizarFlowNumber(currentIndex)}
        IsMandatory={true}
        skipUrl={next(currentIndex)}
        style={styleContainer}
        getCurrentIndex={() => currentIndex}
        isSignup={true}
      />
    </div>
  );
};

export default ACLS_DocV2;
