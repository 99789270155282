import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import "./SignUp.css";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import MultiSelect from "./Components/MultiSelect/MultiSelect";
import { fetchDisciplineSpeciality, updateSignUpProfileApi } from "../../API/API";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import { useEffect } from "react";
import { updateSignupProfile , talentProfileApidata} from "../../redux/actions";
import ProgressBar from "../../components/RatingProgressBar/ProgressBar";
import BackNextSignUpButton from "../SignUp/Components/DocUpload/Signup_gap_screen/BackNextSignUpButton";
import { back, getWizarFlowNumber, next } from "../Auth/components/wizardFlow";
import Loader from "../../components/Loader/Loader";

function SpecialityScreen() {

  const currentIndex = 4;

  const windowHeight = window.innerHeight - 50;
  const history = useHistory();
  const dispatch = useDispatch();
  const [val, setVal] = useState("");
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(null);
  const [speciality, setSpeciality] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading1 = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);

  // const rating = useSelector((state) => state.profileReducer.talentApiData?.StarRating);

  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );
  useEffect(() => {
    if (apiSuccess && flag) {
      setFlag(false);
      history.push(next(currentIndex));
    }
  }, [apiSuccess]);
  useEffect(() => {
    if (profiledata?.profile?.Speciality) {
      setSpeciality(profiledata.profile.Speciality);
      setVal(profiledata.profile.Speciality);
      setSelected(profiledata.profile.Speciality);
    }
  }, [profiledata]);
  useEffect(
    () =>
      localStorage.getItem("specialitySelections")
        ? setSelected(localStorage.getItem("specialitySelections"))
        : null,
    []
  );
  const getSpeciality = async (e) => {
    setVal(e);
    setFlag(false);
    setLoading(true);
    const temp = await fetchDisciplineSpeciality({
      search: e?.trim()?.length < 1 ? "a" : e?.trim(),
      type: "speciality",
    });
    if (temp.data) {
      const specialityArray = temp.data?.map((obj) => obj.specilty);
      setSpeciality(specialityArray);
      // setSelected(specialityArray);
    }
    setLoading(false);
    error && setError(null);
  };
  const nextHandler = () => {
    
    if (!selected || !selected?.length) {
      setError("Please add your sub speciality to continue");
  } else {

    setFlag(true);
    setError(null);
    JSON.stringify(localStorage.setItem("specialitySelections", selected));
    dispatch(
      updateSignupProfile({
        "Profiles.Speciality": selected,
        WizardFlow: getWizarFlowNumber(currentIndex),
      })
    );

  }

  };

  const handleBack = () => {
    history.push(back(currentIndex));
  }

  function handleSelection(e) {
    setSelected(e);
    error && setError(null);
  }

  const signUpLoading = useSelector(
    (state) => state.profileReducer.signUpLoading
  );
  const { loading: profileLoading } = useSelector(
    (state) => state.profileReducer
  );

  if (signUpLoading || profileLoading) return <Loader />;
  return (
    <div className="NameScreenContainer" 
    // style={{ minHeight: windowHeight }}
    >
      {typeof apiError === "string" && (
        <Alert
          message={apiError}
          type="error"
          className="alert-error"
          showIcon
          closable
        />
      )}
      <div>
      {profiledata?.profile?.ProfessionCode == "HC"?<div className='Profile-Completion-Parent'>
      <div className='ProfileCompletion'>
      <ProgressBar heading={"Tell us a bit about yourself"} />
      </div>
    </div> : 
     <ProfileCompletion currenPage={1} totalPage={10} title="Tell us a bit about yourself" />}
        {/* <ProfileCompletion currenPage={4} totalPage={10} /> */}
        <MultiSelect
          title={"Your speciality"}
          placeholder={"Acute care, Apheresis"}
          type={"Mandatory"}
          value={val}
          onChange={getSpeciality}
          options={speciality}
          keyVal={"specilty"}
          selected={
            localStorage.getItem("specialitySelections")?.split(",") ??
            profiledata?.profile?.Speciality
          }
          setSelected={(e) => handleSelection(e)}
          error={error}
          loading={loading}
        />
      </div>
      <div
        style={
          window.innerWidth < 768
            ? { display: "flex", justifyContent: "center" }
            : { display: "flex", justifyContent: "center", width: "77%" }
        }
        className="bck-button-2"
      >
       
      </div>
      <BottomButtons 
          nextHandler={nextHandler}
          nextBtnLoading={loading1}
          backHandler={handleBack}
        />
      {/* <BottomButtons
        nextHandler={nextHandler}
        nextBtnLoading={loading1}
        backHandler={() => history.push("/discipline")}
      /> */}
    </div>
  );
}

export default SpecialityScreen;