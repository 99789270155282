import React, { useState ,useEffect } from "react";
import { useSelector } from "react-redux";
import { resendEmailVerificationAPI } from "../../API/API";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useHistory , useLocation} from "react-router-dom";

const VerifyAlertWeb = () => {
  const history = useHistory();
  const location = useLocation()
  const [hoverState, setHoverState] = useState(false);
  const initialBtnText = localStorage.getItem("btnText") || "Verify email";
  const [btnText, setBtnText] = useState(initialBtnText);
  const verifyEmail = useSelector(
    (state) => state.profileReducer?.talentApiData?.EmailAddress
  );
  const verifyName = useSelector(
    (state) => state.profileReducer.user.profile?.FirstName
  );
  const handleChangeEmailClick = () => {
    history.push("/Edit-Email");
  };
    useEffect(() => {
      localStorage.setItem('btnText', btnText);
    }, [btnText]);
  
    useEffect(() => {
      const storedBtnText = localStorage.getItem('btnText');
      if (storedBtnText === 'Email sent' || storedBtnText === 'Resend email' && location.pathname) {
        setBtnText('Resend email');
      } else {
        setBtnText('Verify email');
      }
    }, [location.pathname]);

  const handleVerifyEmail = () => {
    if (btnText === "Verify email") {
      setBtnText("Email sent");
      resendEmailVerificationAPI();
      window.open("https://mail.google.com/");
    } else if (btnText === "Resend email") {
      setBtnText("Email sent");
      resendEmailVerificationAPI();
    }
  };
  return (
    <div className="alertContainer" style={{top: window.innerWidth > 786 &&  window.innerWidth <= 1000
        ? '45px' : '28px'
    }}>
      <div className="textContainer">
        {window.innerWidth < 786 ? null : (
          <BsExclamationCircleFill
            style={{
              color: "#DB2828",
              fontSize: "20px",
              marginRight: "4px",
              marginTop: "4px",
            }}
          />
        )}

        <span style={{ fontSize: "16px", fontWeight: "400", marginTop: "3px" }}>
          Hi{" "}
          <span style={{ color: "#2E2E2E", fontWeight: "600" }}>
            {verifyName}
          </span>
          , Verify email{" "}
          <span style={{ color: "#2E2E2E", fontWeight: "600" }}>
            {verifyEmail}
          </span>{" "}
          to secure your account.
          <span
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={handleChangeEmailClick}
          >
            Change email
          </span>
        </span>
      </div>
      <button
        style={{
          background: hoverState
            ? btnText === "Verify email" || btnText === "Resend email"
              ? "#FFFFFF"
              : btnText === "Email sent"
              ? "#7E7E7E"
              : "#1E1E1E"
            : "#1E1E1E",
          border: "none",
          borderRadius: "4px",
          padding: "5px 12px",
          cursor: "pointer",
          color:
            hoverState &&
            (btnText === "Verify email" || btnText === "Resend email")
              ? "#1E1E1E"
              : "#FFFFFF",
          width: "133px",
          height: "33px",
          fontWeight: "400",
          fontSize: "16px"
        }}
        onClick={handleVerifyEmail}
        onMouseEnter={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
      >
        {btnText}
      </button>
    </div>
  );
};

export default VerifyAlertWeb;
