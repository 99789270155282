import { Button } from "antd";
import React, { useEffect } from "react";
import successcr from "../../assets/icons/success-screen.svg";
import "./SuccessScreen.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  sendEmailVerification,
  updateProfile,
  updateSignupProfile,
} from "../../redux/actions";
import { alertNotification } from "../../components/notifications/alertNotification";
import { LoadingOutlined } from "@ant-design/icons";
import { sendInviteNotification } from "../../API/API";
import { getEmployerId } from "../../assets/helpers";
import Loader from "../../components/Loader/Loader";
const SuccessScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [percentage, setPercentage] = React.useState(false);
  const [LocalLoader, setLocalLoader] = React.useState(false);
  const profiledata = useSelector((state) => state.profileReducer.user);
  const { ResumeFilename: resume, WizardFlow } = useSelector(
    (state) => state.profileReducer.user.profile
  );

  const username = useSelector((state) => state.profileReducer.user.profile);
  const loading = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );
  const skillId = useSelector(
    (state) => state?.skillListingReducer?.currentSkillListing?.SkillListingId
  );

  const profession = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );

  const professionGig = localStorage.getItem("ProfessionsCode");

  const handleFinish = () => {
    dispatch(
      updateSignupProfile({
        WizardFlow: 1000,
      })
    );
    dispatch(sendEmailVerification());

    if (localStorage.getItem("JobDetailpageUrl")?.slice(0, 7) === "/detail") {
      if (profession === professionGig) {
        history.push(localStorage.getItem("JobDetailpageUrl"));
      } else {
        localStorage.removeItem("JobDetailpageUrl");
        localStorage.removeItem("ProfessionsCode");
        alertNotification(
          "warning",
          `Hi ${username.FirstName} ${username.LastName}, The selected gig is not applicable to your profile, please find relevant gigs`
        );
        history.push("/");
      }
    } else if (localStorage.getItem("JobDetailpageUrl")) {
      history.push(localStorage.getItem("JobDetailpageUrl"));
    } else {
      if (WizardFlow === 1000) {
        if (isEmployerSignUp()) {
          const employer = JSON.parse(localStorage.getItem("employer"));
          localStorage.setItem("employerDetails", JSON.stringify(employer));
          localStorage.removeItem("employer");
          const redirectUrl = localStorage.getItem("redirectUrl");
          history.push(`${redirectUrl || "/"}?isEmployer=true`);
        } else history.push("/");
      }
    }
    setLocalLoader(true);
  };

  const isEmployerSignUp = () => {
    const employer = JSON.parse(localStorage.getItem("employer"));
    return employer != null;
  };

  useEffect(() => {
    if (resume) {
      setPercentage(true);
    } else {
      setPercentage(false);
    }
    if (WizardFlow === 1000) {
      if (isEmployerSignUp()) {
        const employer = JSON.parse(localStorage.getItem("employer"));
        localStorage.setItem("employerDetails", JSON.stringify(employer));
        localStorage.removeItem("employer");
        localStorage.removeItem("socialSignUpWithEmp");
        const redirectUrl = localStorage.getItem("redirectUrl");
        history.push(`${redirectUrl || "/"}?isEmployer=true`);
        // history.push("/?isEmployer=true");
      } else history.push("/");
    }
  }, [resume, WizardFlow]);

  useEffect(() => {
    if (window.location.href.indexOf("/talent/basic-profile-success") != -1) {
      window.gtag("event", "conversion", {
        send_to: "AW-972299611/SK8lCNT0vpIYENu60M8D",
      });
    }
  }, []);

  const signUpLoading = useSelector(
    (state) => state.profileReducer.signUpLoading
  );

  const { loading: profileLoading } = useSelector(
    (state) => state.profileReducer
  );

  if (signUpLoading || profileLoading) return <Loader />;

  const isMobileView = window.innerWidth <= 768;
  return (
    <div className="scs-screen-container">
      <div className="scs-screen-comp-1">
        {!isMobileView ? (
          <div className="scs-screen-comp-head-2">
            Welcome to SkillGigs! Your adventure begins now.
          </div>
        ) : (
          <>
            <div className="scs-screen-comp-head-1">
              Welcome to SkillGigs! Your
            </div>
            <div className="scs-screen-comp-head-2">adventure begins now.</div>
          </>
        )}
        <div className="scs-screen-comp-subhead">Your profile is created</div>
      </div>
      <div className="scs-centered">
        <div>
          <img
            className="scs-screen-comp-2"
            src={successcr}
            alt="success-screen"
          />{" "}
        </div>
        <div className="scs-screen-comp-3">
          <div>
            {" "}
            <Button
              onClick={handleFinish}
              type="text"
              style={{
                background: "#FBDF50",
                width: "310px",
                height: "40px",
                borderRadius: "4px",
                border: "0.5px solid #1E1E1E",
                color: "#1E1E1E",
                fontWeight: "600",
              }}
            >
              {!loading && LocalLoader && (
                <LoadingOutlined
                  style={{ fontSize: 24, marginRight: "4px" }}
                  spin
                />
              )}
              Explore SkillGigs
            </Button>
          </div>
          {/* <div>
            <Button
              type="text"
              onClick={() => {
                history.push("/profile");
                dispatch(updateProfile({ WizardFlow: 1000 }));
              }}
              style={{
                width: "310px",
                height: "40px",
                borderRadius: "4px",
                border: "0.5px solid #1E1E1E",
                color: "#1E1E1E",
                fontWeight: "600",
              }}
            >
              Edit profile
            </Button>
          </div> */}
        </div>
        <div
          className="scs-screen-comp-4"
          onClick={() => {
            history.push("/profile");
            dispatch(updateProfile({ WizardFlow: 1000 }));
          }}
        >
          {" "}
          You can always edit your profile later
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
