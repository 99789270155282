import React, { useState, useEffect } from "react";

import "../../CreateSkillListing.css";

import "./CreateSkillListingScreen.css";

import errorIcon from "../../../../../assets/img/ci_warning.png";

import BottomBottons from "../Footer/BottomButtons";

import { useHistory } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";

import { updateSignupProfile } from "../../../../../redux/actions";
import {
  back,
  getWizarFlowNumber,
  next,
} from "../../../../Auth/components/wizardFlow";
import Loader from "../../../../../components/Loader/Loader";

const DesiredSalary = (props) => {
  const ProfessionCodes = useSelector(
    (state) => state.profileReducer?.user?.profile?.ProfessionCode
  );
  const getCurrentIndex = () => {
    return localStorage.getItem("ProfessionsCode") == "HC" ||
      ProfessionCodes == "HC"
      ? 18
      : 14;
  };

  const dispatch = useDispatch();

  const [salary, setSalary] = useState("");
  const [flag, setFlag] = useState(false);
  const [buttonType, setButtonType] = useState("Year");

  const [showError, setShowError] = useState(false);

  const history = useHistory();

  const profiledata = useSelector((state) => state.profileReducer.user);

  const loading = useSelector((state) => state.profileReducer.signUpLoading);

  const apiError = useSelector((state) => state.profileReducer.error);

  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );

  const minimumBid = profiledata?.profile?.DraftSkillListing?.MinimumBid;

  const gigType = profiledata?.profile?.DraftSkillListing?.GigType;

  useEffect(() => {
    setSalary(minimumBid);
    setButtonType(gigType === "Permanent" ? "Year" : "Hour");
  }, [minimumBid, gigType]);

  useEffect(() => {
    if (apiSuccess && flag) {
      history.push(next(getCurrentIndex()));
    }
  }, [apiSuccess]);

  const handleNext = () => {
    if (salary && buttonType) {
      setFlag(true);
      dispatch(
        updateSignupProfile({
          "DraftSkillListing.MinimumBid": salary,

          "DraftSkillListing.MinimumBidType": buttonType,

          WizardFlow: getWizarFlowNumber(getCurrentIndex()),
        })
      );
    } else {
      setShowError(true);
    }
  };

  const Skiphandle = () => {
    setFlag(true);
    dispatch(
      updateSignupProfile({
        "DraftSkillListing.MinimumBid": null,
        "DraftSkillListing.MinimumBidType": null,
        WizardFlow: getWizarFlowNumber(getCurrentIndex()),
      })
    );
  };

  const handleBack = () => {
    history.push(back(getCurrentIndex()));
  };

  const yearlyHourlyButtons = () => {
    return (
      <div className="year-weekly-container">
        <div
          className="week-btn"
          style={
            buttonType == "Year"
              ? { backgroundColor: "#118936", color: "white" }
              : { backgroundColor: "white", color: "black" }
          }
        >
          Yearly
        </div>

        <div
          className="week-btn"
          style={
            buttonType == "Hour"
              ? { backgroundColor: "#118936", color: "white" }
              : { backgroundColor: "white", color: "black" }
          }
        >
          Hourly
        </div>
      </div>
    );
  };

  const title = "Desired pay";

  const signUpLoading = useSelector(
    (state) => state.profileReducer.signUpLoading
  );
  const { loading: profileLoading } = useSelector(
    (state) => state.profileReducer
  );

  if (signUpLoading || profileLoading) return <Loader />;
  return (
    <div className="CreateSkillListingHeaderScreen2">
      <div className="create-skill-listing-title">
        {title} <span className="Mandatory-Text">(Optional)</span>
      </div>

      <div
        className={`currency-parent-container ${
          showError ? " error-block" : ""
        }`}
      >
        <div className="currency-container">
          <div className="usd-container">
            <span>{buttonType === "Year" ? "Yearly" : "Hourly"}</span>
          </div>
          <div className="dollar-sign">$</div>
          <input
            className="input-screenscurrency"
            type="number"
            value={salary}
            onChange={(e) => {
              setSalary(e.target.value);
              setShowError(false);
            }}
            autoFocus={true}
          />
        </div>
      </div>

      {showError && (
        <div className="error-msg-content">
          <img src={errorIcon} alt="" />

          <span className="error-text">
            Please enter your desired salary to continue
          </span>
        </div>
      )}

      <BottomBottons
        loading={loading}
        handleBack={handleBack}
        handleNext={handleNext}
        Skiphandle={Skiphandle}
        Skip={true}
      />
    </div>
  );
};

export default DesiredSalary;
