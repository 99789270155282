import React, { useState, useEffect, useRef } from "react";
import "./SearchBar.css";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  ApolloLink,
  gql,
  useLazyQuery,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { connect, useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import {
  fetchGigs,
  SearchResponse,
  SearchResponseRequested,
  SearchValue,
  fetchSearchSuggestions,
  updateProfile,
  fetchResumeDetailInit,
  onClearFilterInit,
  searchSuggestionInput,
  onClearSearchInit,
  onClearSearchValueInit,
  onClearSearchIconInit,
  UpdateSearchFilter,
  updateRecentSearch,
} from "../../redux/actions";
import { HttpLink } from "apollo-link-http";
import { useHistory } from "react-router-dom";
import { set } from "lodash";
import { debounce, timeout } from "../../shared/helpers";
import { useLocation } from "react-router";
import { Button, Input, AutoComplete, Drawer } from "antd";
import {
  StarOutlined,
  StarFilled,
  StarTwoTone,
  UserOutlined,
} from "@ant-design/icons";
import ServiceOutkine from "../../assets/icons/service-outlined.svg";
import Suitcase from "../../assets/icons/suitcase.svg";
import closeIcon from "../../assets/icons/clarity_window-closeLine.svg";
import RecentlyViewed from "../../assets/icons/RecentlyViewed.svg";
import ArrowTrending from "../../assets/icons/ArrowTrending.svg";
import fluentlocation from "../../assets/icons/fluentlocation.svg";
import carbonCloseFilled from "../../assets/icons/carbonCloseFilled.svg";
import evaArrowBackOutline from "../../assets/icons/evaArrowBackOutline.svg";
import { GetQueryParams } from "../../assets/helpers";
import { IoSearchOutline } from "react-icons/io5";
const { Search } = Input;

const SearchBar = (props) => {
  const { onClear, clearAllMobile, setCitiesInput } = useSelector(
    (state) => state.onClearFilterReducer
  );
  const url = useLocation();
  const history = useHistory();
  const initialPath = useLocation().pathname.split("/")[1];
  const { loggedInSearchBar, setloggedInSearchBar } = props;

  const [search, setSearch] = useState(props.value);
  const [searchInputText, setSearchInputText] = useState(false);
  // 
  const [count, setCount] = useState(0);
  const [isSuggestion, setIsSuggestion] = useState(false);
  const [suggestionGigs, setSuggestionGigs] = useState([]);
  const [suggestionSlisiting, setSuggestionSlisting] = useState([]);
  const [isVisibleCloseIcon, setIsVisibleCloseIcon] = useState(false);
  const [role, setRole] = useState("");
  const [showClear, setShowClear] = useState(true);
  let data = ["a", "b", "c", "d"];
  const [isOpened, setisOpened] = useState(false);
  const [isOpened2, setisOpened2] = useState(false);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [trending, setTrending] = useState("java developer");
  const user = useSelector((state) => state.profileReducer.user);
  const [clearr, setClearr] = useState(false);
  const [jobInfo, setJobInfo] = useState([]);
  const dispatch = useDispatch();
  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );
  const ProfessionCode = useSelector((state) => state.profileReducer.user.profile.ProfessionCode);
  const [TendingJob, setTendingJob] = useState(ProfessionCode)
  useEffect(() => {
    authTokenValue && dispatch(fetchResumeDetailInit());
    dispatch(searchSuggestionInput([jobInfo, setJobInfo]));
  }, []);
  const trendingData = useSelector(
    (state) => state.resumeDetailReducer.success
  );
  dispatch(onClearSearchInit([search, setSearch]));
  dispatch(onClearSearchValueInit([isOpened, setisOpened]));

  dispatch(onClearSearchIconInit([isVisibleCloseIcon, setIsVisibleCloseIcon]));

  useEffect(() => {
    setTendingJob(ProfessionCode)
  }, [ProfessionCode])

  useEffect(() => {
    const jobSearch =
      trendingData &&
      trendingData?.user &&
      trendingData?.user?.profile &&
      trendingData?.user?.profile?.RecentJobSearch
        ? trendingData?.user?.profile?.RecentJobSearch
        : [];
    // 
    setJobInfo(jobSearch);
  }, [trendingData]);

  useEffect(() => {
    setisOpened(false);
  }, [authTokenValue]);

  // 

  // 
  function encodeURIComponentSafe(s) {
    if (!s) {
      return s;
    }
    return encodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
  }

  const gigs = useSelector((state) => state.searchGigsReducer.gigs);
  const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
  const onSearch = (e) => {
    // setSearch(e.target.value);
    // if (authTokenValue) {
    if(search?.length === 0 || search == " " ){
     return;
    }
    setIsSuggestion(false);
    if (search.length > 0) {
      // if (url.pathname !== "/search") {
      const encodeURl = encodeURIComponentSafe(search);
      history.push({ pathname: `/search/${encodeURl}` });
      // }
      props.searchValue(search);
    }
    setJobInfo((prev) => {
      if (!jobInfo.includes(search)) {
        // 
        if(prev.length === 3){
          const updatedState = [search, ...prev.slice(0, 2)];
          return updatedState;
        }
        else {
          return [search, ...prev];
        }
      } else {
        return [...prev];
      }
    });
    authTokenValue && search !== "" && dispatch(updateRecentSearch({ Profiles: { RecentJobSearch: search } }));
    // }
    if (typeof onClear === "function") {
      onClear("jobMenu");
      onClear("skillMenu");
      onClear("locationMenu");
      onClear("WorkplaceMenu");
      onClear("cityMenu");
      onClear("regionMenu");
      onClear("countryMenu");
      clearAllMobile(false);
    }
  };
  useEffect(() => {
    if (user?.profile?.Role?.length > 0) {
      setRole(user?.profile?.Role[0]);
      // 
    }
  }, [user]);

  useEffect(() => {
    if (initialPath === "search"){
      
      setSearchInputText(true)
    }
    else{
      setSearchInputText(false)
    }
  }, [initialPath])

  useEffect(() => {
    setIsSuggestion(false);
  }, [gigs]);
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  const onClickSkill = (e) => {
    
    setIsSuggestion(false);
    setSearch(e);
    props.searchSuggestions(e);
    if (search?.length > 0) {
      const encodeURl = encodeURIComponentSafe(e);
      history.push({ pathname: `/search/${encodeURl}` });
      props.searchValue(e);
    }
    setJobInfo((prev) => {
      if (!jobInfo.includes(e)) {
        // 
        if(prev.length === 3) {
          const updatedState = [e, ...prev.slice(0, 2)];
          return updatedState;
        }
        else {
          return [e, ...prev];
        }
      } else {
        return [...prev];
      }
    });
    dispatch(updateRecentSearch({ Profiles: { RecentJobSearch: e } }));
  };
  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };
  const onChangeSearch = (e) => {
    setSearchInputText(true)
    setIsVisibleCloseIcon(true);
    setSearch(e.target.value);
    e.preventDefault();
    props.searchSuggestions(e.target.value);
    if (e.target.value.length > 0) {
      setIsSuggestion(true);
    }
    if (e.target.value.length == 0) {
      setIsSuggestion(false);
      setIsVisibleCloseIcon(false);
    }

    //  setJobInfo((prev) => {
    //   if (!jobInfo.includes(e.target.value)) {
    //     // 
    //     return [e.target.value, ...prev];
    //   } else {
    //     return [...prev];
    //   }
    // });
    // dispatch(updateProfile({ profile: { RecentJobSearch: e.target.value } }));

    // if (e.target.value?.length > 3) {
    // hitQuery({ variables: { all: e.target.value } });
    // debounce(hitQuery());
    // }
  };
  const onEnterKey = (e) => {
    // if (e.key === "Enter" && search.length > 0 && authTokenValue) {
    if(search.length === 0 || search == " "){
      return;
    }
    if (e.key === "Enter" && search.length > 0) {
      const encodeURl = encodeURIComponentSafe(e.target.value);
      history.push({ pathname: `/search/${encodeURl}` });
      props.searchValue(search);
      // props.searchSuggestions(search);
      setJobInfo((prev) => {
        if (!jobInfo.includes(e.target.value)) {
          // 
          if(prev.length === 3) {
            const updatedState = [e.target.value, ...prev.slice(0, 2)];
            return updatedState;
          }
          else {
            return [e.target.value, ...prev];
          }
        } else {
          return [...prev];
        }
      });
      dispatch(updateRecentSearch({ Profiles: { RecentJobSearch: search } }));
      if (window.innerWidth < 768) {
        setloggedInSearchBar(false);
        setisOpened(false);
      }
    }

    if (typeof onClear === "function") {
      onClear("jobMenu");
      onClear("skillMenu");
      onClear("locationMenu");
      onClear("WorkplaceMenu");
      onClear("WorkplaceMenu");
      clearAllMobile();
    }
  };
  const addQuery = (key, value) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    history.push({
      search: searchParams.toString(),
    });
  };

  const GetTrendingLocation = (data) =>{
    setisOpened(false)
    // 
    history.push({ pathname: `/search/${" "}` });
    props.searchValue(" ");
    // props.UpdateSearchFilter("TrendingLocSearch", data?._id);
    props.UpdateSearchFilter("cityQuery", [data?.Location]);
    // addQuery("tj", data?._id)
    addQuery("cities", data?.Location)
    // setCitiesInput(data?.Location)
  }

  const onClickskilled = (e, Title = null) => {
    const title = Title ? Title : null;
    //// 
    setSearch(title ? title : e.target.textContent);

    // 
    // if (search?.length > 0) {
    //   if (url.pathname != "/search") {
    //     history.push("/search");
    //   }
    //   props.searchValue();
    // }
    // props.UpdateSearchFilter("TrendingLocSearch", null);

    const encodeURl = encodeURIComponentSafe(title ? title : e.target.textContent);
    history.push({ pathname: `/search/${encodeURl}` });
    props.searchValue(title ? title : e.target.textContent);
    dispatch(
      updateRecentSearch({
        Profiles: { RecentJobSearch: title ? title : e.target.textContent },
      })
    );

    // setJobInfo((prev) => {
    //   if (!jobInfo.includes(e.target.textContent))
    //     return [e.target.textContent, ...prev];
    //   return [...prev];
    // });
    setJobInfo((prev) => {
      if (!jobInfo.includes(title ? title : e.target.textContent)) {
        //  
        //   "jobss",
        //   !jobInfo.includes(title ? title : e.target.textContent)
        // );
        if(prev.length === 3){
          const updatedState = [title ? title : e.target.textContent, ...prev.slice(0, 2)];
          return updatedState;
        }
        else {
          return [title ? title : e.target.textContent, ...prev];
        }
      } else {
        return [...prev];
      }
    });
    // dispatch(
    //   updateRecentSearch({ Profiles: { RecentJobSearch: e.target.textContent } })
    // );
    if (typeof onClear === "function") {
      onClear("jobMenu");
      onClear("skillMenu");
      onClear("locationMenu");
      onClear("WorkplaceMenu");
      onClear("WorkplaceMenu");
      clearAllMobile();
    }
  };
  const Query = () => {
    // 
    // props.GetGigs(query);

    // const GetGigs = props.user.Gigs;
    // // 
    // if (loading) return "Loading...";
    // if (error) return `Error! ${error.message}`;
  };
  useEffect(() => {
    // 
    // 
    // // 
    setSuggestionGigs(props.suggestions?.searchSuggestions?.gigs);
    setSuggestionSlisting(props.suggestions?.searchSuggestions?.skillsListing);
  }, [props.suggestions]);
  useEffect(() => {
    setSearch(props.value ? props.value : "");
    if (props.value) setIsVisibleCloseIcon(true);
  }, [props.value]);
  const bothview = () => {
    setisOpened(true);
    setisOpened2(true);
  };

  function handlingSpecialCharcter(text) {
    return text?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  const getHighlightedTextValue = (textValue) => {
    const highlight = search;
    // Split on highlight term and include term into parts, ignore case (using regular expression)
    const parts = textValue.split(
      new RegExp(`(${handlingSpecialCharcter(highlight)})`, "gi")
    );
    // // 
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === highlight.toLowerCase()
                ? "Highlight_Seacrh_Value"
                : ""
            }
          >
            {part}
          </span>
        ))}
      </span>
    );
  };

  const renderTitle1 = (title) => (
    <span
      className="sbar-render-title1"
      // style={{
      //   display: "flex",
      //   fontSize: "16px",
      //   color: "#7e7e7e",
      // }}
    >
      <img
        src={Suitcase}
        className="sbar-suggestion-icon"
        // style={{ margin: "0 10px 0 0", width: "18px", height: "18px" }}
      />
      <span>{title}</span>
      {/* <a
        style={{
          float: "right",
        }}
        href="https://www.google.com/search?q=antd"
        target="_blank"
        rel="noopener noreferrer"
      >
        more
      </a> */}
    </span>
  );
  const renderTitle2 = (title) => (
    <span
      className="sbar-render-title2"
      // style={{
      //   display: "flex",
      //   fontSize: "16px",
      //   color: "#7e7e7e",
      //   margin: "10px 0 0 0",
      // }}
    >
      <img
        src={ServiceOutkine}
        className="sbar-suggestion-icon"
        // style={{ margin: "0 10px 0 0", width: "18px", height: "18px" }}
      />
      <span>{title}</span>
      {/* <a
        style={{
          float: "right",
        }}
        href="https://www.google.com/search?q=antd"
        target="_blank"
        rel="noopener noreferrer"
      >
        more
      </a> */}
    </span>
  );

  // let mapping = suggestion?.map((item) => renderItem(item));

  const renderItem1 = (title,key) => ({
    key: key,
    value: title,
    label: (
      <div
        // style={{
        //   display: "flex",
        //   width: "40px",
        //   fontSize: "16px",
        //   color: "#1e1e1e",

        //   // justifyContent: "space-between",
        // }}
        className="sbar-render-title"
        // onClick={() => onClickSkill(title)}
      >
        <div key={key} className="inner-div">{getHighlightedTextValue(title)}</div>
      </div>
    ),
  });

  const renderItem2 = (title, key) => ({
    key: key,
    value: title,
    label: (
        <div
          // style={{
          //   display: "flex",
          //   width: "40px",
          //   fontSize: "16px",
          //   color: "#1e1e1e",
          //   // justifyContent: "space-between",
          // }}
          onClick={() => {window.location.replace(`/employer/query/${encodeURIComponentSafe(title)}`)} }
          className="sbar-render-title"
        // onClick={onSearch}
        >
          <div className="inner-div">{getHighlightedTextValue(title)}</div>
        </div>
    ),
  });

  const options =
    authTokenValue === null
      ? [
          {
            label: renderTitle1("Gigs"),
            options: suggestionGigs
              ? suggestionGigs?.map((item) => {
                  return renderItem1(item._id, item?.oid);
                })
              : [],
          },
          {
            label: renderTitle2("Skill Listings"),
            options: suggestionSlisiting
              ? suggestionSlisiting?.map((item) => {
                return renderItem2(item._id, item?.oid);
                })
              : [],
          },
        ]
      : role === "CONS"
      ? [
          {
            label: renderTitle1("Gigs"),
            options: suggestionGigs
              ? suggestionGigs?.map((item) => {
                  return renderItem1(item._id, item?.oid);
                })
              : [],
          },
        ]
      : [
          {
            label: renderTitle2("Skill Listings"),
            options: suggestionSlisiting
              ? suggestionSlisiting?.map((item) => {
                return renderItem2(item._id, item?.oid);
                })
              : [],
          },
        ];
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setisOpened(false);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // useEffect(() => {
  //   dispatch(updateProfile({ profile: { RecentJobSearch: "anuragsssss"   } }));
  // }, []);
  // const updateTrending = () => {
  //   dispatch(updateProfile({ profile: { RecentJobSeach: {search } } }));

  //   // const submitProfessionCode = () => {
  //   //   const ProfessionCode = isHealthcare ? "HC" : "IT";
  //   //   props.updateProfile({
  //   //     userId: props.user.id,
  //   //     profile: { ProfessionCode },
  //   //   });
  //   // };
  // };

  const onClearRecentlySearched = (e) => {
    e.stopPropagation();
    setJobInfo([]);
    setClearr(true);
    // setShowClear(false);
    dispatch(updateRecentSearch({ Profiles: { RecentJobSearch: "" } }));
  };
  useEffect(()=>{
    if (!GetQueryParams()?.tl && !GetQueryParams()?.tj){
      props.UpdateSearchFilter("TrendingLocSearch", null);
      props.UpdateSearchFilter("TrendingPlaceName", null);
    }
  }, [search])
  // useUpdateLogger("suggestionGigs", suggestionGigs);

  return (
    // <div className="searchInputDashboard">
    //   <div className="search-gigs">
    //     {/* <StarOutlined/> */}
    //     <input
    //       style={{ color: "white" }}
    //       type="text"
    //       placeholder="Find Jobs or Candidates"
    //       value={search}
    //       onChange={(e) => onChangeSearch(e)}
    //       onKeyPress={(e) => onEnterKey(e)}
    //       className="icons"
    //     />
    //     <button onClick={onSearch}>Search</button>
    //   </div>
    // </div>
    <div className="search-gigs">
      <AutoComplete
        dropdownClassName="certain-category-search-dropdown"
        // onChange={(e) => onChangeSearch(e)}
        // dropdownStyle={{ width: "20px" }}
        dropdownMatchSelectWidth={100}
        options={
          !(window.innerWidth < 768 && authTokenValue)
            ? isSuggestion && search?.length && suggestionGigs?.length
              ? options
              : null
            : null
        }
        // options={isSuggestion ? options : null}
        onSelect={onClickSkill}
        // options={options}
      >
        {/* <div className="searchInputDashboard"> */}
        <div className="search-gigs">
          <div className={`searchInputBox ${props.extraClass}`}>
            <Input
              // style={{ color: "white" }}
              type="text"
              placeholder={
                // !(window.innerWidth < 768 && authTokenValue)
                // ? "Find Jobs"
                // : "Search"
                authTokenValue ? "Search gigs" : "Search gigs or skill listings"
              }
              value={search === " " || !searchInputText ? null : search}
              open={true}
              onChange={(e) => onChangeSearch(e)}
              // onClick={() => setIsSuggestion(!isSuggestion)}
              onPressEnter={(e) => {onEnterKey(e); e.target.blur();}}
              className="icons"
              // onClick={(e) => setisOpened(!isOpened)}
              onClick={bothview}
              onFocus={() => {
                setClearr(false);
                if (authTokenValue && window.innerWidth < 768) {
                  setloggedInSearchBar(true);
                  setisOpened(true);
                  setShowClear(true);
                }
              }}
            />
            {authTokenValue && window.innerWidth < 768 && (
              <img
                src={evaArrowBackOutline}
                className={`evaArrowBackOutline `}
                alt=""
                onClick={() => {
                  setloggedInSearchBar(false);
                }}
              />
            )}
            <img
              src={
                authTokenValue && window.innerWidth < 768
                  ? carbonCloseFilled
                  : closeIcon
              }
              className={`closeIcon ${
                isVisibleCloseIcon && searchInputText ? "visibleCloseIcon" : ""
              }`}
              alt=""
              onClick={(e) => {
                setSearch("");
                setIsVisibleCloseIcon(false);
                loggedInSearchBar && setisOpened(true);
              }}
            />
          </div>
          <button onClick={(e) => onSearch(e)}><IoSearchOutline style={{fontSize: "1.25rem"}} /></button>
        </div>
        {/* </div> */}
      </AutoComplete>
      {isOpened &&
      (window.innerWidth < 768 || search.length === 0) &&
      authTokenValue ? (
        <div ref={wrapperRef} className="dropdown-menuu">
          {window.innerWidth < 768 && (
            <div className="mobileLoggedInSuggetion">
              {search && search.length >= 0 && suggestionGigs
                ? suggestionGigs?.map((title, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div
                          className="mobileLoggedInSuggetionOption"
                          onClick={(e) => {
                            onClickskilled(e, title._id);
                            if (window.innerWidth < 768) {
                              setloggedInSearchBar(false);
                              setisOpened(false);
                              e.stopPropagation();
                            }
                          }}
                        >
                          {getHighlightedTextValue(title._id)}
                        </div>
                      </React.Fragment>
                    );
                  })
                : ""}
            </div>
          )}
            {!clearr && jobInfo?.filter((item) => item !== '')?.length != 0 && (
            <>
              <div className="dropdown-menuu-insidebox">
                <div className="dropdown-menuu-recentSearch">
                  Recently searched
                </div>
                <div
                  // onClick={clearRecentSearch}
                  className="dropdown-menuu-clear-click"
                  onClick={(e) => onClearRecentlySearched(e)}
                >
                  Clear
                </div>
              </div>
              <div className="dropdown-menuu-clear-recentjobss-inner">
                {jobInfo &&
                  jobInfo.slice(0, 3).map((data, key) => {
                    // 
                    return (
                      data && (
                        <>
                          <div
                            key={key}
                            className="dropdown-menuu-clear-recentjobss-inside"
                            onClick={(e) => {
                              onClickskilled(e);
                              if (window.innerWidth < 768) {
                                setloggedInSearchBar(false);
                                setisOpened(false);
                                e.stopPropagation();
                              }
                            }}
                          >
                            <img
                              className="dropdown-menuu-recentlyview-icon"
                              src={RecentlyViewed}
                              alt="fluentlocation"
                            />
                            <span className="dropdown-menuu-clear-recentjobss-onclick">
                              {data ? data : " "}
                            </span>
                          </div>
                        </>
                      )
                    );
                  })}
              </div>
            </>
          )}
          <div className="dropdown-menuu-clear-trendingJobss">
            Trending gigs
          </div>
          <div className="dropdown-menuu-clear-recentjobss-inner">
            {trendingData?.user?.profile?.Trending?.Job?.[ProfessionCode]?.map((data, key) => {
              return (
                <div
                  key={key}
                  className="dropdown-menuu-clear-recentjobss-inside"
                  onClick={(e) => {
                    onClickskilled(e);
                    if (window.innerWidth < 768) {
                      setloggedInSearchBar(false);
                      setisOpened(false);
                    }
                  }}
                >
                  <img
                    src={ArrowTrending}
                    alt="trending arrow"
                    className="dropdown-menuu-recentlyview-icon"
                  />
                  <span className="dropdown-menuu-clear-recentjobss-onclick">
                    {data?.Title ? data?.Title : ""}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="dropdown-menuu-clear-trendingJobss">
            Trending locations
          </div>
          <div className="dropdown-menuu-clear-recentjobss-inner">
              {trendingData?.user?.profile?.Trending?.Location?.[ProfessionCode]?.map(
              (data, key) => {
                return (
                  <div
                    key={key}
                    className="dropdown-menuu-clear-recentjobss-inside"
                    onClick={(e) => {
                      // onClickskilled(e);
                      GetTrendingLocation(data);
                      if (window.innerWidth < 768) {
                        setloggedInSearchBar(false);
                        setisOpened(false);
                      }
                    }}
                  >
                    <img
                      src={fluentlocation}
                      alt="fluentlocation"
                      className="dropdown-menuu-recentlyview-icon"
                    />
                    <span className="dropdown-menuu-clear-recentjobss-onclick">
                      {data?.Location}
                    </span>
                  </div>
                );
              }
            )}
          </div>
        </div>
      ) : null}
    </div>

    // <div className="container-search">
    //   <input
    //     type="text"
    //     placeholder="Search Gigs..."
    //     value={search}
    //     className="searchbox-search"
    //     onChange={(e) => {
    //       setSearch(e.target.value);
    //       // // 
    //       // 
    //       // Query();
    //     }}
    //   />
    // </div>
  );
};
const mapStateToProps = (state) => {
  return {
    gigs: state.searchGigsReducer.gigs,
    value: state.searchGigsReducer.searchvalue,
    error: state.searchGigsReducer,
    talentToken: state.authReducer.user?.authToken,
    suggestions: state.searchGigsReducer.searchSuggestions,
    // userProfile: state.profileReducer.user.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // GetGigs: (params) => {
    //   dispatch(fetchGigs(params)); // action dispatched
    // },
    // SearchValue
    SearchResponseRequested: (params) => {
      dispatch(SearchResponseRequested(params)); // action dispatched
    },
    searchResponse: (params) => {
      dispatch(SearchResponse(params)); // action dispatched
    },
    searchValue: (params) => {
      dispatch(SearchValue(params)); // action dispatched
    },
    searchSuggestions: (params) => {
      dispatch(fetchSearchSuggestions(params));
    },
    UpdateSearchFilter: (key, params) => {
      dispatch(UpdateSearchFilter(key, params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
// export default SearchBar;
