import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import BottomButtons from '../pages/SignUp/Components/BottomBottons/BottomButtons';
import RadioInput from '../pages/SignUp/Components/RadioInput/RadioInput';
import {useDispatch, useSelector} from 'react-redux';
import {Alert} from 'antd';
import {updateSignupProfile} from '../redux/actions';
import { handleAlertVisibility } from '../assets/helpers';
const exp = [
	{
		ExperienceLevelId: 1,
		ExperienceLevelCode: 'less',
		ExperienceLevelName: 'Less than 1 year',
	},
	{
		ExperienceLevelId: 2,
		ExperienceLevelCode: '2',
		ExperienceLevelName: '1-2 years',
	},
	{
		ExperienceLevelId: 3,
		ExperienceLevelCode: '3-5',
		ExperienceLevelName: '3-5 years',
	},
	{
		ExperienceLevelId: 4,
		ExperienceLevelCode: '6',
		ExperienceLevelName: '6-10 years',
	},
	{
		ExperienceLevelId: 5,
		ExperienceLevelCode: 'more',
		ExperienceLevelName: 'More than 10 years',
	},
];

function EditExperience() {
	const windowHeight = window.innerHeight;
	const history = useHistory();
	const dispatch = useDispatch();
	const [selected, setSelected] = useState(null);
	const [error, setError] = useState(null);
	const [flag, setFlag] = useState(false);
	const profiledata = useSelector((state) => state.profileReducer.user);
	const loading = useSelector((state) => state.profileReducer.signUpLoading);
	const apiError = useSelector((state) => state.profileReducer.error);
	const apiSuccess = useSelector(
		(state) => state.profileReducer.signupUpdateSuccess,
	);
	const isEmail = useSelector(
		(state) => state.profileReducer?.talentApiData?.Profiles?.IsEmailVerified
	);
	useEffect(() => {
		if (profiledata?.profile?.ExperienceLevels?.ExperienceLevelName) {
			setSelected(profiledata.profile.ExperienceLevels?.ExperienceLevelName);
		}
	}, [profiledata]);

	useEffect(() => {
		if (apiSuccess && flag) {
			setFlag(false);
			history.push('/profile', {success: true});
		}
	}, [apiSuccess]);

	const nextHandler = () => {
		if (!selected) {
			setError('Please add your years of experience to continue');
		} else {
			setFlag(true);
			const res = exp.find((item) => item.ExperienceLevelName === selected);
			dispatch(
				updateSignupProfile({
					'Profiles.ExperienceLevels': res,
				}),
			);
		}
	};
	return (
		<div
			className="NameScreenContainer"
			style={{marginTop: handleAlertVisibility(isEmail)}}
		>
			{typeof apiError === 'string' && (
				<Alert
					message={apiError}
					type="error"
					className="alert-error"
					showIcon
					closable
				/>
			)}

			<RadioInput
				title={'Years of experience'}
				type={'Optional'}
				onChange={(e) => {
					setSelected(e.target.value);
					error && setError(null);
				}}
				options={exp}
				keyVal={'ExperienceLevelName'}
				value={selected}
				error={error}
				gap={'0px'}
			/>

			<BottomButtons
				nextHandler={nextHandler}
				nextBtnLoading={loading}
				backHandler={() => history.push('/profile')}
				skip={true}
				nextButtonText="Save"
				NextText="Save"
				backButtonText="Cancel"
			/>
		</div>
	);
}

export default EditExperience;
