import React, { useState } from "react";
import { useHistory } from "react-router";
import "./EffectiveProfileInfo.css";
import icon_1 from "../../assets/icons/Group (2).svg";
import icon_2 from "../../assets/icons/UserPlus.svg";
import image from "../../assets/img/image 430.png";
import imageIT from "../../assets/img/image 435.png";
import icon_3 from "../../assets/img/icon-3.png";
import icon_4 from "../../assets/img/marketplace-icon-4.png";
import icon_5 from "../../assets/img/marketplace-icon-5.png";
import icon_6 from "../../assets/icons/mail.svg";
import { useDispatch, useSelector } from "react-redux";
import { BaseUrl } from "../../components/Footer";
import NursePicThree from "../../assets/img/Healthcare/NursePicThreeMob21.png";
import NursePicThreeMob from "../../assets/img/Healthcare/NursePicThreeMob21.png";
import ItPicThree from "../../assets/img/Healthcare/ItPicThree.jpg";
import ItPicThreeMob from "../../assets/img/Healthcare/ItPicThreeMob.jpg";
import Loader from "../../components/Loader/Loader";
import { updateProfile, updateSignupProfile } from "../../redux/actions";
import { useEffect } from "react";
import ReadRuleEffective, {
  ContentText,
} from "./ReadRuleComponent/ReadRuleMobile";
import BottomButtons from "../SignUp/Components/BottomBottons/BottomButtons";
import { updateSignUpProfileApi } from "../../API/API";
import { back, getWizarFlowNumber, next } from "../Auth/components/wizardFlow";

export const EffectiveProfileInfo = () => {

  const currentIndex = 2;

  const [flag, setFlag] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profileReducer.user);
  const { loading } = useSelector((state) => state.profileReducer);

  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );

  useEffect(() => {
    if (apiSuccess && flag) {
      history.push(next(currentIndex));
    }
  }, [apiSuccess]);

  const handleContinue = () => {
    setFlag(true);
    dispatch(
      updateSignupProfile({
        userId: user.id,
        WizardFlow: getWizarFlowNumber(currentIndex),
      })
    );
    
  }
  
  const handleBack = () => {
    history.push(back(currentIndex));
  };

  const signUpLoading = useSelector(
    (state) => state.profileReducer.signUpLoading
  );
  
  const { loading: profileLoading } = useSelector(
    (state) => state.profileReducer
  );

  if (signUpLoading || profileLoading) return <Loader />;

  return (
    <>
      {loading ? (
        <Loader size={"4rem"} scrolling />
      ) : (
        <div className="effective-profile-info-main">
          <div className="effective-profile-info-first">
            {user?.profile?.ProfessionCode == "HC" ? (
              <img
                className="NursePicThree-img-tag"
                src={NursePicThree}
                alt=""
                style={{ borderRadius: "8px" }}
              />
            ) : (
              <img src={ItPicThree} alt="" style={{ borderRadius: "8px" }} />
            )}
          </div>
          <div className="effective-profile-info-second">
            <div className="effective-profile-info-heading">
              <div className="info-heading">
                Things to avoid when building a successful and effective profile
                on SkillGigs
              </div>
              <div className="info-sub-heading">
                Your success is our focus. Here are some things to keep in mind
              </div>
            </div>

            {window.innerWidth < 768 && (
              <div className="effective-profile-info-img-mobile mobile-second-page">
                {user?.profile?.ProfessionCode == "HC" ? (
                  <img
                    className="effective-profile-info-img-mobile"
                    style={{ borderRadius: "8px" }}
                    src={NursePicThreeMob}
                    alt=""
                  />
                ) : (
                  <img
                    className="effective-profile-info-img-mobile"
                    style={{ borderRadius: "8px" }}
                    src={ItPicThreeMob}
                    alt=""
                  />
                )}
              </div>
            )}
            <div className="Effective-sign-Page-cantainer">
              {ContentText?.map((data) => {
                return <ReadRuleEffective data={data} />;
              })}
            </div>
            {window.innerWidth >= 786 ? (
              <div className="effective-profile-info-btn-senctin">
                <div onClick={handleBack} className="btn-senctin-back">
                  Back
                </div>
                <div onClick={handleContinue} className="btn-senctin-continue">
                  Next
                </div>
              </div>
            ) : (
              <BottomButtons
                nextHandler={handleContinue}
                nextBtnLoading={loading}
                backHandler={handleBack}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EffectiveProfileInfo;
