import React from "react";
import UploadDocV2 from "../../UploadDoc";
import { back, getWizarFlowNumber, next } from "../../../Auth/components/wizardFlow";

const BLSDocv2 = () => {

  const currentIndex = 12;

  const docInfo = [
    {
      "BLS - Basic life support - front": 2,
    },
    {
      "BLS - Basic life support - back": 81,
    },
  ];
  const styleContainer = {
    width :"337px"
  }

  return (
    <div className="acls-doc-container">
      <UploadDocV2
        docInfo={docInfo}
        backUrl={back(currentIndex)}
        nextUrl={next(currentIndex)}
        currentPage={2}
        WizardFlow={getWizarFlowNumber(currentIndex)}
        IsMandatory={true}
        skipUrl={next(currentIndex)}
        style={styleContainer}
        getCurrentIndex={() => currentIndex}
        isSignup={true}
      />
    </div>
  );
};

export default BLSDocv2;
