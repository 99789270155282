import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import "./Profession.css";

import healthCareIcon from "../../assets/icons/healthCareICON.svg";
import IT_icon from "../../assets/icons/IT_ICON.svg";
import freelancer from "../../assets/icons/IT_Freelancer_ICON.svg";
import { updateProfile, updateSignupProfile } from "../../redux/actions";
import Loader from "../../components/Loader/Loader";
import { getWizarFlowNumber, next } from "../Auth/components/wizardFlow";

const Profession = () => {

  const currentIndex = 0;

  const [flag, setFlag] = useState(false);

  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );

  useEffect(() => {
    if (apiSuccess && flag) {
      console.log(next(currentIndex));
      history.push(next(currentIndex));
    }
  }, [apiSuccess]);

  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.profileReducer.user);
  const signUpLoading = useSelector(
    (state) => state.profileReducer.signUpLoading
  );
  const { loading } = useSelector((state) => state.profileReducer);

  const updateProfession = (ProfessionCode) => {
    setFlag(true);
    dispatch(
      updateSignupProfile({
        userId: user.id,
        Professions: { ProfessionCode: ProfessionCode },
        WizardFlow: getWizarFlowNumber(currentIndex),
      })
    );
  
  };

  if (signUpLoading || loading) return <Loader />;

  return (
    <div className="ProfessionPage">
      <div className="profession">
        <div className="professionHeader">
          <div style={{ maxWidth: "28.1rem" }}>
            <div className="preferenceHeader">
              Choose a marketplace category that best suits your role.
            </div>
          </div>
        </div>
        {/* <section className="alignProfession"> */}
        <div className="chooseProfession">
          <div>
            <div
              className={`healthCarePro ${
                user?.profile?.ProfessionCode === "HC" &&
                "SelectedProfessionCode"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                // gtag_report_conversion_hc();
                updateProfession("HC");
              }}
            >
              <div>
                {" "}
                <img src={healthCareIcon} alt="healthCareIcon" />{" "}
              </div>
              <hr
                style={{
                  width: "100%",
                  marginBottom: "0.5rem",
                  marginTop: "0.5rem",
                  color: "#E4E4E4",
                  opacity: "30%",
                }}
              />
              <div className="professiontxt">
                {" "}
                <span className="professionText">Healthcare</span>
              </div>
            </div>
            <div className="professionSubText">
              Our healthcare professionals range from Registered nurses and
              Travel nurses to Respiratory therapists.{" "}
            </div>{" "}
          </div>
          <div>
            <div
              className={`InfoTPro ${
                user?.profile?.ProfessionCode === "IT" &&
                "SelectedProfessionCode"
              }`}
              onClick={() => {
                // gtag_report_conversion_it();
                updateProfession("IT");
              }}
            >
              <div>
                {" "}
                <img src={IT_icon} alt="IT_icon" />{" "}
              </div>
              <hr
                style={{
                  width: "100%",
                  marginBottom: "0.5rem",
                  marginTop: "0.5rem",
                  color: "#E4E4E4",
                  opacity: "30%",
                }}
              />
              <div className="professiontxt">
                {" "}
                <span className="professionText">IT</span>
              </div>
            </div>
            <div className="professionSubText">
              {" "}
              Our IT professionals can range from Developers and Designers to
              Project managers.
            </div>{" "}
          </div>

          {/* <div
            className="ITFreelancerPro"
            onClick={() => updateProfession("FL")}
          >
            <img src={freelancer} alt="freelancer" />
            <span className="professionDivider"></span>
            <span className="professionText">IT Freelancer</span>
          </div> */}
        </div>
        {/* </section> */}
      </div>
    </div>
  );
};

export default Profession;
