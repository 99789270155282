import React, {useState, useEffect} from 'react';
import '../CareerSummary.css';
import {useDispatch, useSelector} from 'react-redux';
import {updateProfile} from '../../../../redux/actions';
import AddEditBox from '../../../../components/AddEditBox/AddEditBox';
import Loader from '../../../../components/Loader/Loader';
import TextArea from 'antd/lib/input/TextArea';
import plus from "../../../../assets/icons/createSkillPlus.svg";
import { alertNotification } from '../../../../components/notifications/alertNotification';

const CareerSummaryHC = ({editDescription, handleEditDesc}) => {
	const dispatch = useDispatch();
	const [addState, setAddState] = useState(false);
	const [addUpdateState, setAddUpdateState] = useState(false);
	const [description, setDescription] = useState(false);
	const [textValue, setTextValue] = useState('');
	const [loading, setLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const user = useSelector((state) => state.profileReducer.user);
	const success = useSelector((state) => state.profileReducer.loading);

	const handleTextArea = (e) => {
		const val = e.target.value;
		setTextValue(val);
		if (val.length > 0) {
			setAddState(true);
		} else setAddState(false);
	};

	useEffect(() => {
		setTextValue(user.profile.Description);
		setLoading(false);
		if (user.profile.Description?.length > 0) {
			setAddUpdateState(true);
		} else setAddUpdateState(false);
	}, [user.profile.Description]);

	useEffect(()=>{
		if(!success && loading){
			alertNotification(
				"success","Changes successfully updated"
			  );
		}
	},[!success])

	const inputTextArea = (
		<TextArea
			showCount
			name="description"
			rows="4"
			placeholder="Please tell about your career summary"
			className="textAreaCareerSummary"
			maxLength={150}
			onChange={handleTextArea}
			value={textValue}
			autoFocus
		/>
	);

	const cancelDescription = () => {
			handleEditDesc(false);
			setDescription(false);
			setTextValue(user.profile.Description);
	};

	const addDescription = () => {
		handleEditDesc(false);
		setDescription(false);
		if (textValue === user.profile.Description) return;
		setLoading(true);
		dispatch(
			updateProfile({
				userId: user.id,
				Profiles: {Objective: textValue === '' ? null : textValue},
			}),
		);
		if (user.profile.Description !== textValue) {
			setAddState(false);
		}
	};
	return (
		<div className={`${!editDescription ? 'top-section' : ''}`}>
			<div className="career-summary" >
				<div className="career-description">
					<p
						className="add-summary"
						onClick={() => {
							setDescription(true);
							setTextValue(user.profile.Description);
							handleEditDesc(true)
						}}
					>
						{/* {description === false && user.profile.Description
            ? "Edit description"
            :  */}
						{!description && !editDescription && !loading && !user.profile.Description ? (
							<img src={plus} alt='add'/>
						) : null}
					</p>
				</div>
				{description || editDescription ? (
					<AddEditBox
						input={inputTextArea}
						add={() => {
							if (addState) addDescription();
						}}
						cancel={cancelDescription}
						addState={addState}
						addUpdate={addUpdateState}
						isTrue={true}
					/>
				) : (
					<>
						{loading ? (
							<Loader height="2rem" scrolling={false} />
						) : (
							<>
								{
								// user.profile.Description === null ? (
								// 	<p className="career-summary-subheading">
								// 		Please tell about your career summary
								// 	</p>
								// ) :
								 (
									<div className="user-description">
										<p>{user.profile.Description}</p>
									</div>
								)}
							</>
						)}
					</>
				)}
				{/* {user.profile.ProfessionCode === "HC" && (
        <>
         <div className="dividers"></div>
          <h3 className="clinical-assessment">
            Take your clinical assessment exam
          </h3>
          <p className="relias-CS">HSH</p>
          <a href="https://app.healthcarestaffinghire.com/?redirectUrl=/admin/users" target="_blank">
            <div className="startExam-CS">Start exam</div>
          </a>
        </>
      )} */}
			</div>
		</div>
	);
};

export default CareerSummaryHC;
