import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import "./SignUp.css";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import TextInput from "./Components/TextInput/TextInput";
import PhoneNumberInput from "./Components/NumericInput/PhoneNumberInput";
import {
  getCountryPhoneCode,
  getRemainingSeconds,
} from "../WelcomeWizard/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMobileOTPInit,
  fetchVerifictionOTPInit,
  resetOtpStatus,
  updateSignupProfile,
} from "../../redux/actions";
import MobileNumberOTP from "./Components/MobileNumberOTP/MobileNumberOTP";
import { alertNotification } from "../../components/notifications/alertNotification";
import ProgressBar from "../../components/RatingProgressBar/ProgressBar";
import { MobileOTPSendAPI } from "../../API/API";
import { back, next } from "../Auth/components/wizardFlow";
import Loader from "../../components/Loader/Loader";

function MobileNumberScreen() {
  // const currentIndex = localStorage.getItem("ProfessionsCode") == "HC" ? 7 : 5;
  const ProfessionCode = useSelector(
    (state) => state.profileReducer?.user?.profile?.ProfessionCode
  );
  const getCurrentIndex = () => {
    return localStorage.getItem("ProfessionsCode") == "HC" ||
      ProfessionCode == "HC"
      ? 6
      : 5;
  };
  const windowHeight = window.innerHeight - 50;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("1");
  const [isVerifiedMobileNumber, setIsVerifiedMobileNumber] = useState(false);
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState(null);
  const [LoadingOtp, setLoadingOtp] = useState(false);
  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );
  const OTPapiSuccess = useSelector((state) => state.authReducer.OTPVerfiction);
  const OTPLoading = useSelector((state) => state.authReducer.loadingOTP);

  const prevPhoneNumber =
    useSelector((state) => state.authReducer.phoneNumber) ||
    location?.state?.phoneNumber;

  const formatPhoneNumber = (input) => {
    try {
      const cleanedInput = input.replace(/\D/g, "");
      if (cleanedInput.length >= 10) {
        const areaCode = cleanedInput.substring(0, 3);
        const prefix = cleanedInput.substring(3, 6);
        const lineNumber = cleanedInput.substring(6, 10);
        setPhoneNumber(`(${areaCode}) ${prefix}-${lineNumber}`);
      } else if (cleanedInput.length >= 7) {
        const areaCode = cleanedInput.substring(0, 3);
        const prefix = cleanedInput.substring(3, 6);
        const lineNumber = cleanedInput.substring(6, 10);
        setPhoneNumber(`(${areaCode}) ${prefix}-${lineNumber}`);
      } else if (cleanedInput.length >= 4) {
        const areaCode = cleanedInput.substring(0, 3);
        const prefix = cleanedInput.substring(3, 6);
        setPhoneNumber(`(${areaCode}) ${prefix}`);
      } else {
        setPhoneNumber(cleanedInput);
      }
    } catch (error) {}
  };

  const checkMobileNumberVerified = (phoneNumber) => {
    if (
      profiledata?.profile?.IsVerifiedMobileNumber &&
      phoneNumber == profiledata?.profile?.MobilePhone
    )
      return true;
    else return false;
  };

  useEffect(() => {
    const { MobilePhone, Location } = profiledata?.profile || {};
    MobilePhone?.slice(-10);
    setCountryCode(getCountryPhoneCode(Location?.CountryName) ?? "1");
    formatPhoneNumber(
      prevPhoneNumber?.toString()?.slice(-10) || MobilePhone?.slice(-10)
    );
    setIsVerifiedMobileNumber(
      checkMobileNumberVerified(prevPhoneNumber?.toString() || MobilePhone)
    );
  }, [profiledata]);

  const nextHandler = () => {
    if (isVerifiedMobileNumber) {
      // history.push(next(getCurrentIndex()) );
      history.push("/mobileOtp");
      return;
    }
    const cleanedInput = phoneNumber?.replace(/\D/g, "");
    if (cleanedInput == "") {
      setError("Please add your Mobile number to continue");
    } else if (cleanedInput.length < 10) {
      setError("Please enter valid mobile number");
    } else {
      setError(null);
      setFlag(true);
      const phoneNumber = Number(countryCode + cleanedInput);
      sendOtp(phoneNumber);
    }
  };

  const sendOtp = async (phoneNumber) => {
    try {
      const storedTimestamp = Number(localStorage.getItem("otpTimer"));
      const remainingSeconds = getRemainingSeconds(storedTimestamp);
      if (prevPhoneNumber != phoneNumber || remainingSeconds < 1) {
        setLoadingOtp(true);
        dispatch(
          fetchMobileOTPInit({
            MobilePhone: phoneNumber,
          })
        );
        const response = await MobileOTPSendAPI({ MobilePhone: phoneNumber });
        if (response.status == 200) {
          localStorage.setItem("otpTimer", Date.now());
          history.push({ pathname: "/mobileOtp", state: { phoneNumber } });
        } else {
          throw "Error";
        }
      } else {
        history.push({ pathname: "/mobileOtp", state: { phoneNumber } });
      }
    } catch (error) {
      alertNotification(
        "error",
        "Error while sending OTP",
        undefined,
        undefined,
        true
      );
    } finally {
      setLoadingOtp(false);
    }
  };

  const backHandler = () => {
    // history.push(back(getCurrentIndex()));
    history.push("/location");
  };

  //  useEffect(() => {
  //   if (apiSuccess && flag) {
  //     setFlag(false);
  //     history.push("/explever");
  //   }
  // }, [apiSuccess])

  // const nextHandler = () => {
  //   const cleanedInput = phoneNumber?.replace(/\D/g, '');
  //   if (cleanedInput == "") {
  //     setError("Please add your Mobile number to continue");
  //   } else if (cleanedInput.length < 10) {
  //     setError("Please enter valid mobile number");
  //   } else {
  //     setError(null);
  //     setFlag(true);
  //     dispatch(updateSignupProfile({
  //       "Profiles.MobilePhone": countryCode + cleanedInput,
  //       WizardFlow: 105
  //     }))
  //   }
  // };

  const signUpLoading = useSelector(
    (state) => state.profileReducer.signUpLoading
  );
  const { loading: profileLoading } = useSelector(
    (state) => state.profileReducer
  );

  if (signUpLoading || profileLoading) return <Loader />;
  return (
    <div
      className="NameScreenContainer"
      // style={{minHeight:windowHeight}}
    >
      {profiledata?.profile?.ProfessionCode == "HC" ? (
        <div className="Profile-Completion-Parent">
          <div className="ProfileCompletion">
            <ProgressBar heading={"Your contact info"} />
          </div>
        </div>
      ) : (
        <ProfileCompletion currenPage={5} totalPage={10} />
      )}
      <PhoneNumberInput
        title={"Mobile number"}
        placeholder={"(878) 916-4875"}
        type={"Mandatory"}
        onChange={(e) => {
          setIsVerifiedMobileNumber(false);
          formatPhoneNumber(e.target.value);
          error && setError(null);
        }}
        countryCode={countryCode}
        value={phoneNumber}
        error={error}
        maxLength={50}
        isVerified={isVerifiedMobileNumber}
      />
      <BottomButtons
        nextHandler={nextHandler}
        nextBtnLoading={loading || LoadingOtp}
        nextButtonText={isVerifiedMobileNumber ? "Next" : "Get OTP"}
        // nextButtonText={"Next"}
        backHandler={backHandler}
      />

      {/* <MobileNumberOTP 
        setIsModalOpen={setIsModalOpen} 
        isModalOpen={isModalOpen}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        setOtp={setOtp}
        otp={otp}
        OTPVerification={OTPVerification}
        OTPapiSuccess={OTPapiSuccess}
        LoadingOtp={OTPLoading}
        setLoadingOtp={setLoadingOtp}
      /> */}
    </div>
  );
}

export default MobileNumberScreen;
