import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import AuthSuccess from "../pages/Auth/components/AuthSuccess";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getAllowedUrl,
  isBlockedURL,
} from "../pages/Auth/components/wizardFlow";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export const PublicRoute = (props) => <Route {...props} />;

export const LoginRoute = (props) => {
  const authToken = useSelector((state) => state.authReducer.user.authToken);
  return !authToken ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/",
        state: { from: props.location },
      }}
    />
  );
};

export const ValidateRoute = (props) => {
  const authToken = useSelector((state) => state.authReducer.user.authToken);
  const { ExperienceLevelName, WizardFlow } = useSelector(
    (state) => state.profileReducer.user.profile
  );
  return authToken && WizardFlow <= 207 && WizardFlow !== 6 ? (
    <AuthSuccess />
  ) : (
    <Route {...props} />
  );
};

export const RestrictSignUpRoutes = (props) => {
  const { WizardFlow, ProfessionCode } = useSelector(
    (state) => state.profileReducer.user.profile
  );

  return WizardFlow >= 1000 || WizardFlow == 6 ? (
    <Redirect
      to={{
        pathname: localStorage.getItem("redirectURL") || "/",
      }}
    />
  ) : (
    <Route {...props} />
  );
};

const RestrictNextRoutes = (props) => {
  const { WizardFlow, ProfessionCode } = useSelector(
    (state) => state.profileReducer.user.profile
  );
  const location = useLocation()
  const isBlocked = isBlockedURL(location.pathname, {
    WizardFlow,
    ProfessionCode: ProfessionCode,
  });

  return isBlocked ? (
    <Redirect
      to={{
        pathname: `/${isBlocked}`,
      }}
    />
  ) : (
    <Route {...props} />
  );
};

export default RestrictNextRoutes;

export const SpecialRoute = (props) => {
  const authToken = useSelector((state) => state.authReducer.user.authToken);
  return authToken === null ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/",
        state: { from: props.location },
      }}
    />
  );
};

export const ProtectedRoute = (props) => {
  const authToken = useSelector((state) => state.authReducer.user.authToken);
  if (authToken == null) {
    localStorage.setItem("redirectUrl", props.location.pathname);
  }
  return authToken ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: props.location },
      }}
    />
  );
};