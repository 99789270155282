import React, { useCallback, useEffect, useReducer, useState } from "react";
import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";
import { Input, Typography, Button, Statistic } from "antd";
import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import BottomButtons from "../../pages/SignUp/Components/BottomBottons/BottomButtons";



import { useDispatch, useSelector } from "react-redux";
// import { getRemainingSeconds } from "../../../WelcomeWizard/helper";
import { getRemainingSeconds } from "../../pages/WelcomeWizard/helper";
// import { alertNotification } from "../../../../components/notifications/alertNotification";
import { alertNotification } from "../notifications/alertNotification";
import { fetchVerifictionOTPInit,resetOtpStatus,updateSignupProfile } from "../../redux/actions";
// import { MobileOTPSendAPI } from "../../../../API/API";
import { MobileOTPSendAPI } from "../../API/API";
import { handleAlertVisibility } from "../../assets/helpers";
const { Title, Text } = Typography;
const { Countdown } = Statistic;

const EditNumberOtp = () => {
    const history = useHistory(); // Get the history object
      const location = useLocation();
      const dispatch = useDispatch();
      const OTPapiSuccess = useSelector((state) => state.authReducer.OTPVerfiction);
      const loadingVerificationOtp = useSelector(
        (state) => state.authReducer.loadingOTP
      );
      const profiledata = useSelector((state) => state.profileReducer.user);
      const isEmail = useSelector(
        (state) => state.profileReducer?.talentApiData?.Profiles?.IsEmailVerified
      );
      const phoneNumber =
        useSelector((state) => state.authReducer.phoneNumber) ||
        location?.state?.phoneNumber;
    
      const handleEditNumberClick = () => {
        history.push({
          pathname: "/Edit-number",
          state: { phoneNumber: phoneNumber },
        });
      };
      const [resendLoading, setResendLoading] = useState(false);
    
      const [time, setTime] = useState(60);
      const [otpError, setOtpError] = useState("");
      const [otp, setOtp] = useState("");
    
      const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs.toString().padStart(2, "0")}`;
      };
    
      const handleOtpChange = (e) => {
        setOtpError(false);
        setOtp(e);
        if (e.length == 4) {
          handleVerifyOtp(e);
        }
      };
    
      const startTimer = useCallback(() => {
        const currentTimestamp = Date.now();
        localStorage.setItem("otpTimer", currentTimestamp);
        setTime(getRemainingSeconds(currentTimestamp));
        const updateTime = () => {
          setTime((prev) => {
            if (prev <= 1) {
              clearInterval(intervalTimer);
              return 0;
            }
            return prev - 1;
          });
        };
    
        const intervalTimer = setInterval(updateTime, 1000);
      }, []);
    
      useEffect(() => {
        const storedTimestamp = Number(localStorage.getItem("otpTimer"));
        const remainingTime = getRemainingSeconds(storedTimestamp || Date.now());
        setTime(remainingTime);
        const updateTime = () => {
          setTime((prev) => {
            if (prev <= 1) {
              clearInterval(intervalTimer);
              return 0;
            }
            return prev - 1;
          });
        };
    
        const intervalTimer = setInterval(updateTime, 1000);
    
        return () => {
          clearInterval(intervalTimer);
        };
      }, []);
    
      const onClickResend = () => {
        setResendLoading(true);
        resendOtp();
      };
    
      const resendOtp = async () => {
        try {
          const response = await MobileOTPSendAPI({ MobilePhone: phoneNumber });
          if (response.status == 200) {
            // alertNotification(
            //   "success",
            //   "OTP sent successfully",
            //   undefined,
            //   undefined,
            //   undefined,
            //   2
            // );
            startTimer();
          }
        } catch {
        } finally {
          setResendLoading(false);
        }
      };
    
      const handleVerifyOtp = (_otp = otp) => {
        if (_otp.length != 4) {
          setOtpError("OTP Incorrect");
          return;
        }
        if (loadingVerificationOtp) return;
    
        OTPVerification(_otp);
      };
    
      const OTPVerification = (_otp) => {
        dispatch(
          fetchVerifictionOTPInit({
            MobilePhone: phoneNumber,
            Otp: Number(_otp),
          })
        );
      };
    
      useEffect(() => {
        if (OTPapiSuccess?.status == "passed") {
          dispatch(updateSignupProfile({}));
          dispatch(resetOtpStatus());
          history.push("/profile", { success: true });
        }
        if (OTPapiSuccess?.status == "failed") {
          setOtpError("The code you entered is invalid. Please try again");
          dispatch(resetOtpStatus());
        }
      }, [OTPapiSuccess?.status]);
    
      useEffect(() => {
        setOtpError(false);
      }, []);
    
      function formatPhoneNumber(phoneNumber) {
        const cleaned = ("" + phoneNumber).replace(/\D/g, "").slice(1);
        const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        return formatted;
      }
  return (
    <div className="MainContainer"
    style={{marginTop: handleAlertVisibility(isEmail)}}>
      <div className="subContainer">
        
        <div className="MobileNumberOTP-cantiner">
          <div className="MobileNumberOTP-Text-section">
            OTP sent to +1 {formatPhoneNumber(phoneNumber)}{" "}
            <div className="mandatoryText">(Mandatory)</div>
          </div>

          <div className="Mobile-code-verification-enter-text">
            Enter the SMS verification code
          </div>
          <div
            className={`Mobile-code-verification-enter ${
              otpError ? "OTP-margin-true" : null
            }`}
          >
            <OtpInput
              value={otp}
              onChange={handleOtpChange}
              inputType={"tel"}
              numInputs={4}
              shouldAutoFocus
              renderInput={(props) => <input {...props} />}
              inputStyle={`OtpInput-number ${
                otpError ? "OtpInput-number-failed" : null
              }`}
            />
          </div>
          <div>
            {otpError ? <span className="otpErrorText">{otpError}</span> : ""}
          </div>
          <div className="resend-edit-container">
            {time > 0 ? (
              <p className="retryText">
                You can retry after {formatTime(time)} sec
              </p>
            ) : (
              <Button
                disabled={resendLoading}
                className="edit-number"
                onClick={onClickResend}
              >
                Resend code
              </Button>
            )}
            <Button className="edit-number" onClick={handleEditNumberClick}>
              Edit mobile number
            </Button>
          </div>
        </div>
        
        <BottomButtons
          nextButtonText="Verify"
          backButtonText="Cancel"
          NextText = 'Verify'
          backHandler={handleEditNumberClick}
          nextBtnLoading={loadingVerificationOtp}
          nextHandler={handleVerifyOtp}
        />

      </div>
    </div>
  )
}

export default EditNumberOtp