import { useSelector } from "react-redux";
import { fetchUserWizard, hitApi } from "../../../API/API";
import { isNull } from "../../WelcomeWizard/helper";

const WIZARD_FLOW_HC = [
  {
    id: 1,
    name: "profession",
    wizardFlowNumber: 2,
    actualWizardFlowNumber:1
  },
  {
    id: 2,
    name: "read-rules",
    wizardFlowNumber: 8,
    actualWizardFlowNumber: 2,
  },
  {
    id: 3,
    name: "effective-profile-info",
    wizardFlowNumber: 102,
    actualWizardFlowNumber: 8,
  },
  {
    id: 4,
    name: "discipline",
    wizardFlowNumber: 103,
    actualWizardFlowNumber: 102,
  },
  {
    id: 5,
    name: "speciality",
    wizardFlowNumber: 100,
    actualWizardFlowNumber: 103,
  },
  {
    id: 6,
    name: "name",
    wizardFlowNumber: 101,
    actualWizardFlowNumber: 100,
  },
  {
    id: 7,
    name: "location",
    wizardFlowNumber: 104,
    actualWizardFlowNumber: 101,
  },
  {
    // need focus here
    id: 8,
    name: "mobileOtp",
    wizardFlowNumber: 105,
    actualWizardFlowNumber: null,
  },
  {
    // need focus here
    id: 9,
    name: "mobileno",
    wizardFlowNumber: 105,
    actualWizardFlowNumber: 104,
  },

  {
    id: 10,
    name: "explever",
    wizardFlowNumber: 110,
    actualWizardFlowNumber: 105,
  },
  {
    id: 11,
    name: "resume-upload-v2",
    wizardFlowNumber: 112,
    actualWizardFlowNumber: 110,
  },
  {
    id: 12,
    name: "state-lisence-doc",
    wizardFlowNumber: 116,
    actualWizardFlowNumber: 112,
  },
  {
    id: 13,
    name: "bls-doc",
    wizardFlowNumber: 113,
    actualWizardFlowNumber: 116,
  },
  {
    id: 14,
    name: "acls-doc",
    wizardFlowNumber: 114,
    actualWizardFlowNumber: 113,
  },
  {
    // need focus heere
    id: 15,
    name: "create-skilllisting-gig-type",
    wizardFlowNumber: 201,
    actualWizardFlowNumber: 114,
  },
  {
    id: 16,
    name: "create-skilllisting-title",
    wizardFlowNumber: 203,
    actualWizardFlowNumber: 201,
  },
  {
    id: 17,
    name: "create-skilllisting-skill",
    wizardFlowNumber: 204,
    actualWizardFlowNumber: 203,
  },
  {
    id: 18,
    name: "create-skilllisting-location",
    wizardFlowNumber: 205,
    actualWizardFlowNumber: 204,
  },
  {
    id: 19,
    name: "create-skilllisting-salary",
    wizardFlowNumber: 206,
    actualWizardFlowNumber: 205,
  },
  {
    id: 20,
    name: "create-skilllisting-date",
    wizardFlowNumber: 1000,
    actualWizardFlowNumber: 206,
  },
];

const WIZARD_FLOW_IT = [
  {
    id: 1,
    name: "profession",
    wizardFlowNumber: 2,
    actualWizardFlowNumber:1
  },
  {
    id: 2,
    name: "read-rules",
    wizardFlowNumber: 8,
    actualWizardFlowNumber: 2,
  },
  {
    id: 3,
    name: "effective-profile-info",
    wizardFlowNumber: 100,
    actualWizardFlowNumber: 8,
  },
  {
    id: 4,
    name: "name",
    wizardFlowNumber: 101,
    actualWizardFlowNumber: 100,
  },
  {
    id: 5,
    name: "location",
    wizardFlowNumber: 104,
    actualWizardFlowNumber: 101,
  },
  {
    id: 6,
    name: "mobileOtp",
    wizardFlowNumber: 105,
    actualWizardFlowNumber: null,
  },
  {
    id: 7,
    name: "mobileno",
    wizardFlowNumber: 105,
    actualWizardFlowNumber: 104,
  },

  {
    id: 8,
    name: "explever",
    wizardFlowNumber: 107,
    actualWizardFlowNumber: 105,
  },
  {
    id: 9,
    name: "workAuthorization",
    wizardFlowNumber: 108,
    actualWizardFlowNumber: 107,
  },
  {
    id: 10,
    name: "resume-upload-v2",
    wizardFlowNumber: 110,
    actualWizardFlowNumber: 108,
  },
  {
    id: 11,
    name: "create-skilllisting-gig-type",
    wizardFlowNumber: 201,
    actualWizardFlowNumber: 110,
  },
  {
    id: 12,
    name: "create-skilllisting-title",
    wizardFlowNumber: 203,
    actualWizardFlowNumber: 201,
  },
  {
    id: 13,
    name: "create-skilllisting-skill",
    wizardFlowNumber: 204,
    actualWizardFlowNumber: 203,
  },
  {
    id: 14,
    name: "create-skilllisting-location",
    wizardFlowNumber: 205,
    actualWizardFlowNumber: 204,
  },
  {
    id: 15,
    name: "create-skilllisting-salary",
    wizardFlowNumber: 206,
    actualWizardFlowNumber: 205,
  },
  {
    id: 16,
    name: "create-skilllisting-date",
    wizardFlowNumber: 207,
    actualWizardFlowNumber: 206,
  },
  {
    id: 17,
    name: "basic-profile-success",
    wizardFlowNumber: 1000,
    actualWizardFlowNumber: 207,
  },
];

export const next = (currentIndex) => {
  const professionCode = localStorage.getItem("ProfessionsCode") ?? "HC";
  const wizardFlow = professionCode == "IT" ? WIZARD_FLOW_IT : WIZARD_FLOW_HC;
  return currentIndex + 1 < wizardFlow.length
    ? wizardFlow[++currentIndex].name
    : wizardFlow[wizardFlow.length - 1].name;
};

export const back = (currentIndex) => {
  const professionCode = localStorage.getItem("ProfessionsCode") ?? "HC";
  const wizardFlow = professionCode == "IT" ? WIZARD_FLOW_IT : WIZARD_FLOW_HC;
  return currentIndex > 0
    ? wizardFlow[--currentIndex].name
    : wizardFlow[0].name;
};

export const getWizarFlowNumber = (currentIndex) => {
  const professionCode = localStorage.getItem("ProfessionsCode") ?? "HC";
  const wizardFlow = professionCode == "IT" ? WIZARD_FLOW_IT : WIZARD_FLOW_HC;
  return currentIndex > 0
    ? wizardFlow[currentIndex].wizardFlowNumber
    : wizardFlow[0].wizardFlowNumber;
};

export const isBlockedURL = (requestUrl, response) => {
  try {
    const wizardFlow =
      response.ProfessionCode == "IT" ? WIZARD_FLOW_IT : WIZARD_FLOW_HC;
    const requestedUrl = requestUrl.replaceAll("/", "");

    let requestUrlIndex = wizardFlow.findIndex(
      (el) => el.name == requestedUrl.replaceAll("/", "")
    );
    let allowedUrlIndex = wizardFlow.findIndex(
      (el) => el.actualWizardFlowNumber == response.WizardFlow
    );

    if (response.WizardFlow === 0) {
      return "profession";
    } else if (isNull(response.WizardFlow)) {
      return false;
    } else if (requestUrlIndex === -1) {
      return wizardFlow[allowedUrlIndex].name;
    } else if (requestUrlIndex <= allowedUrlIndex) {
      return false;
    } else {
      return wizardFlow[allowedUrlIndex].name;
    }
  } catch {
    return false;
  }
};
