import React, { useState, useEffect, useRef } from "react";
import { DatePicker } from "antd";
import errorIcon from "../../../assets/img/ci_warning.png";
import { useHistory } from "react-router-dom";
import BottomButtons from "../../../pages/SignUp/NewCreateSkillListing/components/Footer/BottomButtons";
import { useDispatch, useSelector } from "react-redux";
// import { alertNotification } from "../../../../../components/notifications/alertNotification";
import moment from "moment";
import { updateSkillListing } from "../../../redux/actions";

const EditStartDate = () => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState("");
  const [showError, setShowError] = useState(false);
  const [redirectionFlag, setRedirectionFlag] = useState(false);
  const history = useHistory();
  const profiledata = useSelector((state) => state.profileReducer.user);
  const professionGig = localStorage.getItem("ProfessionsCode");
  const datePickerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const getInputValues = JSON.parse(localStorage.getItem("SkillListing"));

  useEffect(() => {
    if (getInputValues?.StartDate) {
      setSelectedDate(moment(getInputValues?.StartDate));
    }
  }, [getInputValues?.StartDate]);

  const handleOpenChange = (open) => {
    setOpen(open);
  };
  const filterSkills = getInputValues?.SkillListings_Skills?.map(
    ({ _id, SkillName }) => ({
      _id,
      SkillName,
    })
  );
  const GigType = getInputValues?.GigType;
  let MinimumBidType = "";
  let ClientRate = getInputValues?.MinimumBid;
  if (GigType == "Permanent") {
    MinimumBidType = "Year";
  } else if (GigType == "Contract" || GigType == "Per Diem") {
    MinimumBidType = "Hour";
    ClientRate = getInputValues?.MinimumBid * 1.49339;
  }
  const handleUpdateSkillListing = (startDate) => {
    dispatch(
      updateSkillListing({
        DisplayTitle: getInputValues?.DisplayTitle,
        Title: getInputValues?.Title,
        MinimumBid: getInputValues?.MinimumBid,
        MinimumBidType,
        GigType,
        ClientRate,
        StartDate: new Date(startDate),
        SkillListings_Skills: filterSkills?.length > 0 ? filterSkills : null,
        SkillListings_Cities: getInputValues?.SkillListings_Cities,
        _id: getInputValues?._id,
      })
    );
  };
  const handleNext = () => {
    if (selectedDate) {
      localStorage.setItem(
        "SkillListing",
        JSON.stringify({
          ...getInputValues,
          StartDate: new Date(selectedDate)?.toISOString(),
        })
      );
      handleUpdateSkillListing(selectedDate);
        history.push(
          `/skill-listing-detail-page/${getInputValues?.SkillListingId}`,
          { state: true}
        );
      localStorage.removeItem("SkillListing");
      setRedirectionFlag(true);
    } else {
      setShowError(true);
    }
  };

  const handleBack = () => {
    history.push("/edit-desired-pay");
  };

  const Skiphandle = () => {
    handleUpdateSkillListing(getInputValues?.StartDate);
		history.push(
			`/skill-listing-detail-page/${getInputValues?.SkillListingId}`,
			{state: true}
		  );
    localStorage.removeItem("SkillListing");
  };

  return (
    <div className="CreateSkillListingHeaderScreen2">
      <div className="create-skill-listing-title">
        Desired start date{" "}
        <span
          className="Mandatory-Text"
          style={{ position: "relative", left: "-12px", bottom: "3px" }}
        >
          (Optional)
        </span>
      </div>
      <div className="create-skill-listing-subheader-other">
        <span>
          When can you start? Let potential employers know your availability.
        </span>
      </div>
      <DatePicker
        disabledDate={(current) => {
          return current && current.valueOf() < Date.now();
        }}
        value={selectedDate ? moment(selectedDate) : null}
        placeholder="MM DD, YYYY"
        format="MMMM DD, YYYY"
        className={`date-container-datepicker${
          showError ? " error-block" : ""
        }`}
        name="availableToStart"
        inputReadOnly={true}
        ref={datePickerRef}
        open={open}
        onOpenChange={handleOpenChange}
        onChange={(date, dateString) => {
          if (dateString) {
            setSelectedDate(new Date(dateString)?.toISOString());
            setShowError(false);
          } else {
            setSelectedDate("");
          }
        }}
        suffixIcon={null}
      />
      {showError && (
        <div className="error-msg-content">
          <img src={errorIcon} alt="" />
          <span className="error-text">
            Please enter your start date to continue
          </span>
        </div>
      )}
      <BottomButtons
        handleBack={handleBack}
        handleNext={handleNext}
        Skiphandle={Skiphandle}
        Skip={true}
        title={true}
        isBack={false}
      />
    </div>
  );
};

export default EditStartDate;
