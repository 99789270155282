import React from "react";
import UploadDocV2 from "../../UploadDoc";
import {
  back,
  getWizarFlowNumber,
  next,
} from "../../../Auth/components/wizardFlow";

const StateLisenceV2 = () => {
  const currentIndex = 11;

  const docInfo = [
    {
      "State licence - front": 89,
    },
    {
      "State licence - back": 90,
    },
  ];
  const styleContainer = {
    width: "337px",
  };
  return (
    <div className="acls-doc-container">
      <UploadDocV2
        docInfo={docInfo}
        backUrl={back(currentIndex)}
        nextUrl={next(currentIndex)}
        // nextUrl="social-security-doc"
        currentPage={5}
        WizardFlow={getWizarFlowNumber(currentIndex)}
        // WizardFlow={112}
        IsMandatory={true}
        skipUrl={next(currentIndex)}
        // skipUrl={"social-security-doc"}
        style={styleContainer}
        getCurrentIndex={() => currentIndex}
        isSignup={true}
      />
    </div>
  );
};

export default StateLisenceV2;
