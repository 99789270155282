import React from "react";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import "./AddEditBox.css";

const AddEditBox = ({ input, add, cancel, addState = true, addUpdate, isTrue }) => {
  // useUpdateLogger("addState", addState);
  return (
    <div className="add-edit-box">
      {input}
      <div className={isTrue? 'cancel-summary-div ' : 'cancel-add-div'}>
        <div className={isTrue? 'cancel-summary-btn' : 'cancel'} onClick={cancel}>
          Cancel
        </div>
        <div
          className={
            input.props.WorkHistoryForm || addState
              ? `${isTrue? 'add-summary-btn' : 'add'}`
              : `${isTrue? 'add-summary-btn add-summary-disable' : 'add add-disable'}`
          }
          onClick={add}
        >
          {/* <div className="add"  onClick={add}> */}
          {addUpdate ? "Update" : "Save"}
        </div>
      </div>
    </div>
  );
};

export default AddEditBox;
