import React from "react";
import UploadDocV2 from "../../UploadDoc";
import { useSelector } from "react-redux";
import { back, getWizarFlowNumber, next } from "../../../Auth/components/wizardFlow";
import Loader from "../../../../components/Loader/Loader";

const ResumeUploadv2 = () => {
  const ProfessionCodes = useSelector(
    (state) => state.profileReducer?.user?.profile?.ProfessionCode
  );

  const getCurrentIndex = () => {
    return localStorage.getItem("ProfessionsCode") == "HC" ||
    ProfessionCodes == "HC"
      ? 10
      : 9;
  };
  // const currentIndex = localStorage.getItem("ProfessionsCode") == "HC" ? 9 : 8;

  const Profiles = useSelector((state) => state.profileReducer?.user?.profile);

  const ProfessionCode = Profiles?.ProfessionCode;

  const docInfo = [
    {
      Resume: 52,
    },
  ];


  

  return (
    <div>
      <UploadDocV2
        docInfo={docInfo}
        backUrl= {back(getCurrentIndex())}
        nextUrl={next(getCurrentIndex())}
        currentPage={1}
        WizardFlow={getWizarFlowNumber(getCurrentIndex())}
        IsMandatory={true}
        skipUrl={window.innerWidth < 768 ? ProfessionCode === "HC" ? "state-lisence-doc" : "create-skilllisting-gig-type" : false}
        getCurrentIndex={getCurrentIndex}
        isSignup={true}
      />
    </div>
  );
};

export default ResumeUploadv2;
