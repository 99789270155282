import React, {useState, useEffect} from 'react';
import {Select, Spin} from 'antd';
import {debounce} from '../../../shared/helpers';
import {connect, useDispatch, useSelector} from 'react-redux';
import {DownOutlined} from '@ant-design/icons';
import {CheckOutlined} from '@ant-design/icons';
import {
	fetchKeySearchLocation,
	updateSignupProfile,
} from '../../../redux/actions';
import errorIcon from '../../../assets/img/ci_warning.png';
import {useHistory} from 'react-router-dom';
import BottomButtons from '../../../pages/SignUp/NewCreateSkillListing/components/Footer/BottomButtons';
import SelectionItem from '../EditSkills/SelectionItem';

function EditLocation() {
	const dispatch = useDispatch();
	const history = useHistory();
	const {Option} = Select;
	const getInputValues = JSON.parse(localStorage.getItem('SkillListing'));
	const skillListingCities = getInputValues?.SkillListings_Cities || [];
	const [locations, setLocations] = useState([]);
	const [selectedLocations, setSelectedLocations] = useState(
		skillListingCities,
	);
	const [desiredLocations, setDesiredLocations] = useState(skillListingCities);
	const [showError, setShowError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [noDataViewSkills, setNoDataViewSkills] = useState('');

	const location = useSelector(
		(state) => state.credentialingReducer.searchedLocation,
	);
	const loader = useSelector((state) => state.credentialingReducer.skillLoader);

	useEffect(() => {
		if (location.length) {
			setLocations(location);
		} else {
			setLocations([]);
		}
	}, [location]);

	const onSearchLocation = (value) => {
		setShowError(false);
		if (value !== '') {
			setNoDataViewSkills(value);
			dispatch(fetchKeySearchLocation(value));
		}
	};

	const onSelectLocation = (loc) => {
		try {
			const _selectedLocations = [...selectedLocations, JSON.parse(loc)];
			setDesiredLocations(_selectedLocations);
			setSelectedLocations(_selectedLocations);
		} catch (error) {
			console.warn('error', error);
		}
	};

	const onDeselectLocation = (loc) => {
		try {
			const _selectedLocations = selectedLocations.filter(
				(el) =>
					el?.Cities?.LocationWithCountry !==
					JSON.parse(loc)?.Cities?.LocationWithCountry || 
                    el?.LocationWithCountry !==
					JSON.parse(loc)?.LocationWithCountry ,
			);
			setDesiredLocations(_selectedLocations);
			setSelectedLocations(_selectedLocations);
			localStorage.setItem(
				'SkillListing',
				JSON.stringify({
					...getInputValues,
					SkillListings_Cities: [..._selectedLocations],
				}),
			);
		} catch (error) {
			console.warn('error', error);
		}
	};

	const handleNext = () => {
		setLoading(true);
		if (desiredLocations?.length) {
			localStorage.setItem(
				'SkillListing',
				JSON.stringify({
					...getInputValues,
					SkillListings_Cities: [...selectedLocations],
				}),
			);
			setLoading(false);
			history.push('/edit-desired-pay');
			setShowError(false);
		} else {
			setLoading(false);
			setShowError(true);
		}
	};

	const handleBack = () => {
		history.push('/edit-skill');
	};

	const locationPlaceholder = () => (
		<div className="placeholder-new-signup-loc-skill">
			<div className="example">ex.</div>
			<div className="circle-box">
				<span>Houston, TX</span>
			</div>
			<div className="circle-box">
				<span>Ferry Pass, FL</span>
			</div>
		</div>
	);
	return (
		<div className="CreateSkillListingHeaderScreen2">
			<div className="create-skill-listing-title">
				Your desired locations{' '}
				<span className="Mandatory-Text" style={{position:'relative', left:'-12px', bottom: '2px'}}>(Mandatory)</span>
			</div>
			<div className="create-skill-listing-subheader-other">
				<span>Add your preferred work locations here</span>
			</div>
			<Select
				mode="multiple"
				value={selectedLocations?.map((location) => JSON.stringify(location))}
				onSearch={debounce((val) => onSearchLocation(val), 700)}
				onSelect={onSelectLocation}
				onDeselect={onDeselectLocation}
				style={{width: '100%'}}
				showSearch
				listHeight={160}
				autoFocus
				className={`desired-loc-skill ${showError ? 'error-block' : ''}`}
				placeholder={locationPlaceholder()}
				tagRender={({value}) => {
					const _tag = JSON.parse(value);
					return (
						<SelectionItem
							title={
								_tag?.Cities?.LocationWithCountry ||
								_tag?.LocationWithCountry ||
								'N/A'
							}
							value={value}
							onRemove={onDeselectLocation}
						/>
					);
				}}
				notFoundContent={
					noDataViewSkills?.length > 0 ? (
						<div>
							{loader || locations.length > 0 ? (
								<Spin size="small" />
							) : (
								'No data found!'
							)}
						</div>
					) : null
				}
			>
				{locations.map((location) => (
					<Option
						value={JSON.stringify(location)}
						key={location.LocationWithCountry}
					>
						{location.LocationWithCountry}
					</Option>
				))}
			</Select>
			{showError && (
				<div className="error-msg-content">
					<img src={errorIcon} alt="" />
					<span className="error-text">
					Please enter the desired location to continue
					</span>
				</div>
			)}
			<div style={{paddingTop: '50px'}}>
				<BottomButtons handleNext={handleNext} handleBack={handleBack} isBack={false}/>
			</div>
		</div>
	);
}
export default EditLocation;
