import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TextInput from "../../pages/SignUp/Components/TextInput/TextInput";
import BottomButtons from "../../pages/SignUp/Components/BottomBottons/BottomButtons";
import { useDispatch, useSelector } from "react-redux";
import { updateSignupProfile, talentProfileApidata } from "../../redux/actions";
import { Alert, message } from "antd";
import { Auth } from "../../config/aws-exports";
import { handleAlertVisibility } from "../../assets/helpers";

const EditName = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(false);

  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector((state) => state.profileReducer.signupUpdateSuccess);
  const isEmail = useSelector(
    (state) => state.profileReducer?.talentApiData?.Profiles?.IsEmailVerified
  );
  useEffect(() => {
    getName();
  }, [profiledata]);

  const getName = async () => {
    if (profiledata?.profile?.FirstName && profiledata?.profile?.LastName) {
      setName(`${profiledata.profile.FirstName} ${profiledata.profile.LastName}`);
    } else {
      const session = await Auth.currentAuthenticatedUser();
      if (session?.attributes?.name) {
        setName(session.attributes.name);
      }
    }
  };

  useEffect(() => {
    if (apiSuccess && flag) {
      setFlag(false);
      history.push("/profile", { success: true }); 
    }
  }, [apiSuccess]);

  const nextHandler = () => {
    dispatch(talentProfileApidata());
    const pattern = /^[a-zA-Z\s]+$/;
    if (name === "") {
      setError("Please enter your name to continue");
    } else if (!pattern.test(name)) {
      setError("Avoid using special characters or numbers.");
    } else if (name.trim()?.split(" ")?.length < 2 || name?.length > 50) {
      setError("Name should be under 50 characters with space");
    } else {
      setError(null);
      setFlag(true);
      const nameParts = name.trim()?.split(" ");
      const firstName = nameParts[0];
      const lastName = nameParts[nameParts.length - 1];
      dispatch(
        updateSignupProfile({
          "Profiles.FirstName": firstName,
          "Profiles.LastName": lastName,
        })
      );
    }
  };

  return (
    <div className="NameScreenContainer"
    style={{marginTop: handleAlertVisibility(isEmail)}}
    >
      {typeof apiError === "string" && (
        <Alert
          message={apiError}
          type="error"
          className="alert-error"
          showIcon
          closable
        />
      )}
      <TextInput
        title={"Your name"}
        placeholder={"ex. Gary Smith"}
        type={"(Mandatory)"}
        onChange={(e) => {
          setName(e.target.value);
          const pattern = /^[a-zA-Z\s]+$/;
          if (e.target.value === "" || pattern.test(e.target.value)) {
            error && setError(null);
          } else {
            setError("Avoid using special characters or numbers.");
          }
        }}
        value={name}
        error={error}
        maxLength={50}
      />
      <BottomButtons
        nextButtonText="Save"
        NextText ="Save"
        backButtonText="Cancel"
        nextHandler={nextHandler}
        nextBtnLoading={loading}
        backHandler={() => history.push("/profile")}
      />
    </div>
  );
};

export default EditName;
