import React, {useState, useEffect} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import './skill.css';
import {Select, Spin} from 'antd';
import BottomButtons from '../../../pages/SignUp/NewCreateSkillListing/components/Footer/BottomButtons';
import {fetchSearchSkill} from '../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import {debounce} from '../../../shared/helpers';
import errorIcon from '../../../assets/img/ci_warning.png';
import SelectionItem from './SelectionItem';
import {CheckOutlined} from '@ant-design/icons';

const EditSkills = () => {
	const history = useHistory();
	const location = useLocation();
	const {id, idSkill} = location.state || {};
	const dispatch = useDispatch();
	const {Option} = Select;
	const skillListings = useSelector(
		(state) => state.skillListingReducer.skills,
	);
	const loading = useSelector(
		(state) => state.skillListingReducer.loadingSkillListing,
	);
	const ProfessionCode = useSelector(
		(state) => state.profileReducer.user.profile.ProfessionCode,
	);
	const existingData = JSON.parse(localStorage.getItem('SkillListing')) || {
		SkillListings_Skills: [],
	};

	const [selectedSkills, setSelectedSkills] = useState(
		existingData.SkillListings_Skills || [],
	);
	const [skills, setSkills] = useState([]);
	const [noDataViewSkills, setNoDataViewSkills] = useState('');
	const [showError, setShowError] = useState(false);

	useEffect(() => {
		if (skillListings.length) {
			setSkills(skillListings);
		} else {
			setSkills([]);
		}
	}, [skillListings]);

	const handleBack = () => {
		history.push('/edit-skill-title');
	};

	const handleNext = () => {
		if (selectedSkills?.length) {
			localStorage.setItem(
				'SkillListing',
				JSON.stringify({
					...existingData,
					SkillListings_Skills: selectedSkills,
				}),
			);
			history.push('/edit-location');
			setShowError(false);
		} else {
			setShowError(true);
		}
	};
	const onSelectSkill = (skill) => {
		try {
			const parsedSkill = JSON.parse(skill);
			if (
				!selectedSkills.find((el) => el.SkillName === parsedSkill.SkillName)
			) {
				setSelectedSkills((prevSelectedSkills) => [
					...prevSelectedSkills,
					parsedSkill,
				]);
			} else {
				onDeselectSkill(skill);
			}
		} catch (error) {
			console.warn('Error parsing skill:', error);
		}
	};

	const onDeselectSkill = (skill) => {
		try {
			const parsedSkill = JSON.parse(skill);
			setSelectedSkills((prevSelectedSkills) =>
				prevSelectedSkills.filter(
					(el) => el.SkillName !== parsedSkill.SkillName,
				),
			);
			localStorage.setItem(
				'SkillListing',
				JSON.stringify({
					...existingData,
					SkillListings_Skills: selectedSkills.filter(
						(el) => el.SkillName !== parsedSkill.SkillName,
					),
				}),
			);
		} catch (error) {
			console.warn('Error parsing skill:', error);
		}
	};

	const onSearchSkills = (value) => {
		if (value !== '') {
			setNoDataViewSkills(value);
			dispatch(fetchSearchSkill({val: value, profCode: ProfessionCode}));
		}
	};

	const locationPlaceholder = () => (
		<div className="placeholder-new-signup-loc-skill">
			<div className="example">ex.</div>
			<div className="circle-box">
				<span>Speciality Nurse</span>
			</div>
			<div className="circle-box">
				<span>Acute care</span>
			</div>
			<div className="circle-box Mobile-class">
				<span>Oncology</span>
			</div>
		</div>
	);

	return (
		<div className="CreateSkillListingHeaderScreen2">
			<div className="create-skill-listing-title">
				Your skills{' '}
				<span
					className="Mandatory-Text"
					style={{position: 'relative', left: '-12px', bottom: '3px'}}
				>
					(Mandatory)
				</span>
			</div>
			<div className="create-skill-listing-subheader-other">
				<span>List the skills you want to be searched by</span>
			</div>
			<Select
				mode="multiple"
				value={selectedSkills?.map((skill) => JSON.stringify(skill))}
				onSearch={debounce((val) => onSearchSkills(val), 700)}
				onSelect={onSelectSkill}
				onDeselect={onDeselectSkill}
				style={{width: '100%'}}
				showSearch
				listHeight={160}
				autoFocus
				className={`desired-loc-skill ${showError ? 'error-block' : ''}`}
				placeholder={locationPlaceholder()}
				tagRender={({value}) => {
					const _tag = JSON.parse(value);
					return (
						<SelectionItem
							title={_tag?.SkillName || 'N/A'}
							value={value}
							onRemove={onDeselectSkill}
						/>
					);
				}}
				notFoundContent={
					noDataViewSkills?.length > 0 ? (
						<div>
							{loading || skills.length > 0 ? (
								<Spin size="small" />
							) : (
								'No data found!'
							)}
						</div>
					) : null
				}
			>
				{skills?.length > 0 &&
					skills?.map((skill) => {
						let isChecked = selectedSkills.some(
							(item) => item?.SkillName === skill?.SkillName,
						);
						return (
							<Option
								key={`${skill?.SkillName}`}
								value={JSON.stringify(skill)}
								style={{backgroundColor: isChecked ? '#F4F4F4' : null}}
							>
								<div style={{display: 'flex', justifyContent: 'space-between'}}>
									{skill?.SkillName}
									{isChecked && <CheckOutlined style={{color: 'black'}} />}
									<style>
										{`
                  .ant-select-item-option-selected .ant-select-item-option-state {
                    display: none !important;
                  }
                `}
									</style>
								</div>
							</Option>
						);
					})}
			</Select>
			{showError && (
				<div className="error-msg-content">
					<img src={errorIcon} alt="" />
					<span className="error-text">Please add skills to continue</span>
				</div>
			)}
			<div style={{paddingTop: '50px'}}>
				<BottomButtons
					handleNext={handleNext}
					handleBack={handleBack}
					isBack={false}
				/>
			</div>
		</div>
	);
};

export default EditSkills;
