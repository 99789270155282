import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BottomButtons from "../BottomBottons/BottomButtons";
import MultiSelect from "../MultiSelect/MultiSelect";
import { fetchDisciplineSpeciality } from "../../../../API/API";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import { useEffect } from "react";

import { updateSignupProfile } from "../../../../redux/actions";
import { handleAlertVisibility } from "../../../../assets/helpers";
;

const EditSpecialty = () => {
     const windowHeight = window.innerHeight - 50;
      const history = useHistory();
      const dispatch = useDispatch();
      const [val, setVal] = useState("");
      const [selected, setSelected] = useState(null);
      const [error, setError] = useState(null);
      const [speciality, setSpeciality] = useState([]);
      const [loading, setLoading] = useState(false);
      const [flag, setFlag] = useState(false);
      const profiledata = useSelector((state) => state.profileReducer.user);
      const loading1 = useSelector((state) => state.profileReducer.signUpLoading);
      const apiError = useSelector((state) => state.profileReducer.error);
      const isEmail = useSelector(
        (state) => state.profileReducer?.talentApiData?.Profiles?.IsEmailVerified
      );
     
      const apiSuccess = useSelector(
        (state) => state.profileReducer.signupUpdateSuccess
      );
      useEffect(() => {
        if (apiSuccess && flag) {
          setFlag(false);
          history.push("/profile" ,{ success: true });
        }
      }, [apiSuccess]);
      useEffect(() => {
        if (profiledata?.profile?.Speciality) {
          setSpeciality(profiledata.profile.Speciality);
          setVal(profiledata.profile.Speciality);
          setSelected(profiledata.profile.Speciality);
        }
      }, [profiledata]);
      useEffect(
        () =>
          localStorage.getItem("specialitySelections")
            ? setSelected(localStorage.getItem("specialitySelections"))
            : null,
        []
      );
      const getSpeciality = async (e) => {
        setVal(e);
        setFlag(false);
        setLoading(true);
        const temp = await fetchDisciplineSpeciality({
          search: e?.trim()?.length < 1 ? "a" : e?.trim(),
          type: "speciality",
        });
        if (temp.data) {
          const specialityArray = temp.data?.map((obj) => obj.specilty);
          setSpeciality(specialityArray);
          setSelected(specialityArray);
        }
        setLoading(false);
        error && setError(null);
      };
      const nextHandler = () => {
        if (!selected || !selected?.length) {
          setError("Please add your speciality");
        } else {
          setFlag(true);
          setError(null);
          JSON.stringify(localStorage.setItem("specialitySelections", selected));
          dispatch(
            updateSignupProfile({
              "Profiles.Speciality": selected,
              
            })
          );
      
        }
      };
    
      function handleSelection(e) {
        setSelected(e);
        error && setError(null);
      }
    
  return (
    <div className="NameScreenContainer" 
    style={{marginTop: handleAlertVisibility(isEmail)}}
    >
      <div>
        <MultiSelect
          title={"Your speciality"}
          placeholder={"Acute care, Apheresis"}
          type={"Mandatory"}
          value={val}
          onChange={getSpeciality}
          options={speciality}
          keyVal={"specilty"}
          selected={
            localStorage.getItem("specialitySelections")?.split(",") ??
            profiledata?.profile?.Speciality
          }
          setSelected={(e) => handleSelection(e)}
          error={error}
          loading={loading}
        />
      </div>
      <div
        style={
          window.innerWidth < 768
            ? { display: "flex", justifyContent: "center" }
            : { display: "flex", justifyContent: "center", width: "77%" }
        }
        className="bck-button-2"
      >
       
      </div>
      <BottomButtons 
          nextHandler={nextHandler}
          nextBtnLoading={loading1}
          nextButtonText="Save"
        NextText ="Save"
        backButtonText="Cancel"
          backHandler={() => history.push("/profile")}
        />

    </div>
  )
}

export default EditSpecialty