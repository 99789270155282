import React, {useState, useEffect} from 'react';
// import "./CreateSkillListing.css";
import ProgressBar from '../../pages/SignUp/NewCreateSkillListing/components/Header/Progress';
import {useLocation} from 'react-router-dom';
import EditSkills from './EditSkills/EditSkills';
import EditGigType from './EditGigType/EditGigType';
import EditTitle from './EditTilte/EditTitle';
import EditLocation from './EditLocation/EditLocation';
import EditDesiredPay from './EditDesiredPay/EditDesiredPay';
import EditStartDate from './EditStartDate/EditStartDate';
import { handleAlertVisibility } from '../../assets/helpers';
import { useSelector } from 'react-redux';

export const NewEditSkillListing = () => {
	const location = useLocation();
	const isEmail = useSelector(
		(state) => state.profileReducer?.talentApiData?.Profiles?.IsEmailVerified
	);

	return (
		<React.Fragment>
			<div className="create-skill-listing-parent" style={{marginTop: window.innerWidth < 786 ? '-80px': ''}}>
				<div className="create-skill-klisting-child"  style={{marginTop: handleAlertVisibility(isEmail)}}>
					<ProgressBar title={'Let’s build your skill listing'} />
					{location.pathname === '/edit-gig-type' ? (
						<EditGigType />
					) : location.pathname === '/edit-skill-title' ? (
						<EditTitle />
					) : location.pathname === '/edit-skill' ? (
						<EditSkills />
					) : location.pathname === '/edit-location' ? (
						<EditLocation />
					) : location.pathname === '/edit-desired-pay' ? (
						<EditDesiredPay />
					) : location.pathname === '/edit-start-date' ? (
						<EditStartDate />
					) : (
						''
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default NewEditSkillListing;
