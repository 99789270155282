import React, {useEffect,useState} from "react";
import { useHistory } from "react-router-dom";
import BottomButtons from "../BottomBottons/BottomButtons";
import { updateSignupProfile,fetchKeySearchLocation } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import DropdownSelection from "../DropdownSelection/DropdownSelection";

import { Alert } from "antd";
import { handleAlertVisibility } from "../../../../assets/helpers";

const EditStateLicensed = () => {
   const windowHeight = window.innerHeight-50;
    const history = useHistory();
    const dispatch = useDispatch();
    const searchedLocation = useSelector(
      (state) => state.credentialingReducer.searchedLocation
    );
    const searchLoading = useSelector(
      (state) => state.credentialingReducer.searchLoading
    );
      const isEmail = useSelector(
        (state) => state.profileReducer?.talentApiData?.Profiles?.IsEmailVerified
      );
    const profiledata = useSelector((state) => state.profileReducer.user);
    const loading = useSelector((state) => state.profileReducer.signUpLoading);
    const apiError = useSelector((state) => state.profileReducer.error);
    const apiSuccess = useSelector((state) => state.profileReducer.signupUpdateSuccess);
  
    const [val, setVal] = useState('');
    const [selected, setSelected] = useState(null);
    const [error, setError] = useState(null);
    const [flag, setFlag] = useState(false);
   
  
    useEffect(() => {
      if(apiSuccess && flag){
        setFlag(false);
       history.push("/profile", { success: true }) 
      }
    }, [apiSuccess])
    useEffect(() => {
      if(profiledata?.profile?.Location && profiledata?.profile?.Location?.LocationWithCountry){
        setSelected(profiledata.profile.Location)
        setVal(profiledata.profile.Location.LocationWithCountry)
      }
    }, [profiledata])
    
    const getLocation = (e) => {
      setVal(e);
      dispatch(fetchKeySearchLocation(e?.trim()));
      setFlag(false);
      setSelected(null);
      error && setError(null);
    }
    const nextHandler = () => {
      if(!selected){
        setError("Please add your State license location")
        setFlag(false)
      }else{
        setFlag(true);
        let update = {"Profiles.Location":selected};
        if(selected.CityId && selected.CityName && selected.RegionId && selected.CountryId){
          const Cities = {
            CityId: selected.CityId,
            CityName: selected.CityName,
            RegionId: selected.RegionId,
            CountryId: selected.CountryId,
            Latitude: selected.Latitude,
            Longitude: selected.Longitude,
          };
          const Regions = {
            RegionId: selected.RegionId,
            RegionName: selected.RegionName,
            CountryId: selected.CountryId,
            Latitude: selected.Latitude,
            Longitude: selected.Longitude,
            Code: selected.RegionCode
          };
          const Countries = {
            CountryId: selected.CountryId,
            CountryName: selected.CountryName,
          };
          update["Profiles.Cities"] = Cities;
          update["Profiles.Regions"] = Regions;
          update["Profiles.Countries"] = Countries;
         
        }
        dispatch(updateSignupProfile(update));
      }
    }
  return (
    <div className="NameScreenContainer" 
    style={{marginTop: handleAlertVisibility(isEmail)}}
    >
     
      <DropdownSelection
        title={profiledata?.profile?.ProfessionCode == "HC"?"State Licensed in": "Your Current Location"}
        placeholder={"ex. Houston, TX"}
        type={"Mandatory"}
        subTitle={profiledata?.profile?.ProfessionCode == "HC"?"Enter the state you are licensed to work in": "Pick only one option from a list of choices"}
        value={val}
        onChange={getLocation}
        options={searchedLocation}
        keyVal={"LocationWithCountry"}
        selected={selected}
        setSelected={setSelected}
        error={error}
        loading={searchLoading}
      />
      <BottomButtons 
        nextHandler={nextHandler} 
        nextBtnLoading={loading}
        nextButtonText="Save"
        NextText ="Save"
        backButtonText="Cancel"
        backHandler={() => history.push('/profile')} />
    </div>

  
  )
}

export default EditStateLicensed