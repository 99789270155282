import React, {useState, useEffect} from 'react';
import errorIcon from '../../../assets/img/ci_warning.png';
import BottomButtons from '../../../pages/SignUp/NewCreateSkillListing/components/Footer/BottomButtons';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { updateSkillListing } from '../../../redux/actions';

const EditDesiredPay = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [salary, setSalary] = useState('');
	const [flag, setFlag] = useState(false);
	const [buttonType, setButtonType] = useState('Year');
	const [showError, setShowError] = useState(false);
	const loading = useSelector((state) => state.profileReducer.signUpLoading);

	const getInputValues = JSON.parse(localStorage.getItem('SkillListing'));
	const minimumBid = getInputValues?.MinimumBid;
	const gigType = getInputValues?.GigType;
    

	useEffect(() => {
		setSalary(minimumBid);
		setButtonType(gigType === 'Permanent' ? 'Year' : 'Hour');
	}, [minimumBid, gigType]);

	const handleNext = () => {
		if (salary && buttonType) {
			setFlag(true);
			localStorage.setItem(
				'SkillListing',
				JSON.stringify({...getInputValues, MinimumBid: salary, MinimumBidType:buttonType}),
			);
        history.push('/edit-start-date')
		} else {
			setShowError(true);
		}
	};

	const Skiphandle = () => {
		history.push('/edit-start-date')
	};

	const handleBack = () => {
		history.push('/edit-location');
	};
	return (
		<div className="CreateSkillListingHeaderScreen2">
			<div className="create-skill-listing-title">
				Desired pay <span className="Mandatory-Text"  style={{position:'relative', left:'-12px', bottom: '2px'}}>(Optional)</span>
			</div>

			<div
				className={`currency-parent-container ${
					showError ? ' error-block' : ''
				}`}
			>
				<div className="currency-container">
					<div className="usd-container">
						<span>{buttonType === 'Year' ? 'Yearly' : 'Hourly'}</span>
					</div>
					<div className="dollar-sign">$</div>
					<input
						className="input-screenscurrency"
						type="number"
						value={salary}
						onChange={(e) => {
							setSalary(e.target.value);
							setShowError(false);
						}}
						autoFocus={true}
					/>
				</div>
			</div>

			{showError && (
				<div className="error-msg-content">
					<img src={errorIcon} alt="" />

					<span className="error-text">
						Please enter your desired salary to continue
					</span>
				</div>
			)}
			<div style={{paddingTop: '50px'}}>
				<BottomButtons
					handleBack={handleBack}
					handleNext={handleNext}
					Skiphandle={Skiphandle}
					Skip={true}
					isBack={false}
				/>
			</div>
		</div>
	);
};

export default EditDesiredPay;
