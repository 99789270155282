import React, { useState, useEffect } from "react";
import "./TabSkillisting.css";
import { useHistory} from 'react-router-dom';
import moment from "moment";
import { useSelector } from "react-redux";
import { useUpdateLogger } from "../../../../shared/useUpdateLogger";

const TabSkillListing = ({ SkillListing, ProfessionCode }) => {
  const history = useHistory()

  const [bidType, setbidType] = useState("");
  const [availability, setAvailability] = useState();
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const profile = useSelector((state) => state.profileReducer?.user?.profile);

    const professionCode = useSelector(
      (state) => state.profileReducer.user.profile.ProfessionCode,
    );

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
    return () => {
      window.removeEventListener("resize", checkDimensions);
    };
  }, []);

  useEffect(() => {
    if (SkillListing?.MinimumBidType === "Year") {
      setbidType("yr");
    } else {
      setbidType("hr");
    }

    setAvailability(SkillListing?.StartDate);
    // //
  }, [SkillListing]);

  // useUpdateLogger("skillcheck", SkillListing?.SkillListings_Skills);

  return (
    <div className="tabSkillListing">
     {  dimensions < 786 ? <div
        className="mobileTitleOnly"
        style={{ display: "flex", justifyContent: "space-between"}}
      >
       <div style={{fontSize: "16px", color: "#1E1E1E", fontWeight: "500"}}>
       {SkillListing?.DisplayTitle
          ? SkillListing?.DisplayTitle
          : SkillListing?.Title}
       </div>
       {professionCode == 'HC' && SkillListing && (<div style={{fontSize:"", color: "#4054B2", fontWeight: "500"}}
        onClick={ ()=>{
            localStorage.setItem('SkillListing', JSON.stringify(SkillListing))
            history.push('/edit-gig-type')
        }
        }
       >
         Edit
       </div>)}
      </div> : ""}

      { ProfessionCode != 'HC'? <section className="careerSummary">
        <div className="title">Career summary</div>
        <div className="value">
          {profile.Description ? profile.Description : "N/A"}
        </div>
      </section> : ''}
      <section className="jobType">
        <div className="title">Gig type</div>
        <div className="value">
          {SkillListing?.JobOrderTypes?.JobOrderTypeName}
        </div>
      </section>
      {/* <section className="jobType">
        <div className="title">Rolsddse</div>
        <div className="value">{SkillListing?.Title}</div>
      </section> */}
      <section className="skillsData">
        <div className="title">Skills</div>
        <div className="box">
          {SkillListing?.SkillListings_Skills?.map(( data,i) => (
            <div className="databox" key={i}>
              {data?.SkillName ? data.SkillName : "NA"}
            </div>
          ))}
        </div>
      </section>
      <section className="willingToRelocate">
        <div className="title">State licensed in</div>
        <div className="box">
          {SkillListing?.SkillListings_Cities?.length > 0 ? (
            <>
              {SkillListing?.SkillListings_Cities?.map((data,i) => (
                <div className="databox" key={i}>
                  {data?.Location
                    ? data?.Location
                    : data?.Cities?.Location
                    ? data?.Cities?.Location
                    : data?.RegionName && data?.RegionCode
                    ? `${data.RegionName}, ${data.RegionCode}`
                    : "N/A"}
                </div>
              ))}
            </>
          ) : (
            "NA"
          )}
        </div>
      </section>
      {SkillListing?.MinimumBid?.$numberDecimal ||
        (SkillListing?.MinimumBid && (
          <section className="desiredSalary">
            <div className="title">
              {ProfessionCode === "IT" && bidType === "yr"
                ? "Desired Annual Salary"
                : ProfessionCode === "IT" && bidType === "hr"
                ? "Desired Pay Rate"
                : "Desired salary"}
            </div>
            <div className="value">{`$${
              SkillListing?.MinimumBid?.$numberDecimal
                ? SkillListing?.MinimumBid?.$numberDecimal
                : SkillListing?.MinimumBid?.toLocaleString("en-US")
                ? SkillListing?.MinimumBid?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : 0
            }/${bidType}`}</div>
          </section>
        ))}
      <section className="availability">
        <div className="title">Availability</div>
        <div className="value">
          {availability ? moment(availability).format("ll") : "Not Available"}
        </div>
      </section>
    </div>
  );
};

export default TabSkillListing;
