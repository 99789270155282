import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import BottomButtons from "../BottomBottons/BottomButtons";
import DropdownSelection from "../DropdownSelection/DropdownSelection";
import { fetchDisciplineSpeciality } from "../../../../API/API"; 
import { useEffect } from "react";
import { updateSignupProfile,talentProfileApidata } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import { handleAlertVisibility } from "../../../../assets/helpers";



const DisciplineEdit = () => {
      const windowHeight = window.innerHeight-50;
      const history = useHistory();
      const dispatch = useDispatch();
      const [val, setVal] = useState('');
      const [selected, setSelected] = useState(null);
      const [error, setError] = useState(null);
      const [discipline, setDiscipline] = useState([]);
      const [loading, setLoading] = useState(false);
      const [flag, setFlag] = useState(false);
      const profiledata = useSelector((state) => state.profileReducer.user);
      const loading1 = useSelector((state) => state.profileReducer.signUpLoading);
      const apiError = useSelector((state) => state.profileReducer.error);
      const apiSuccess = useSelector((state) => state.profileReducer.signupUpdateSuccess);
      const [isModal , setisModal] = useState(true)
      
        useEffect(() => {
          if(apiSuccess && flag){
            setFlag(false);
            history.push("/profile", { success: true });
          }
        }, [apiSuccess])
        useEffect(() => {
          if(profiledata?.profile?.Discipline){
            setSelected({discipline:profiledata.profile.Discipline});
            setVal(profiledata.profile.Discipline);
          }
        }, [profiledata])
          const isEmail = useSelector(
            (state) => state.profileReducer?.talentApiData?.Profiles?.IsEmailVerified
          );
        const getDiscipline = async(e) => {
          setVal(e);
          error && setError(null);
          setLoading(true);
          const temp = await fetchDisciplineSpeciality({ search: e?.trim()?.length < 1 ? 'a' : e?.trim(),type:"discipline"});
          if(temp.data){
            setDiscipline(temp.data);
          }
          setLoading(false)
        }
        const nextHandler = () => {
          const isValidSelection = discipline.some((item) => item.discipline === val);
          if (!selected || val === "" || (!isValidSelection && val !== selected?.discipline)) {
            setError("Please add your discipline");
          } else {
            setFlag(true);
            setError(null);
            dispatch(
              updateSignupProfile({
                "Profiles.Discipline": selected?.discipline ?? "",
              })
            );
          }
        };
        
  return (
    <>
    
 <div className="NameScreenContainer"  style={{marginTop: handleAlertVisibility(isEmail)}}>

   {typeof apiError === "string" && <Alert message={apiError} type="error" className="alert-error" showIcon closable/>}
   
   <DropdownSelection
     title={"Your discipline"}
     placeholder={"ex. RN"}
     subTitle={"Type and pick a discipline"}
     type={"Mandatory"}
     value={val}
     onChange={getDiscipline}
     options={discipline}
     keyVal={"discipline"}
     selected={selected}
     setSelected={(e)=>{setSelected(e); setVal(e?.discipline); error && setError(null)}}
     error={error}
     loading={loading}
   />
   
   <BottomButtons 
     nextHandler={nextHandler}
     nextBtnLoading={loading1}
     backButtonText="Cancel"
     nextButtonText="Save"
     NextText ="Save"
     

     backHandler={() => history.push('/profile')} />       
 </div>
 </>
  )
}

export default DisciplineEdit