import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import "./SignUp.css";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import DropdownSelection from "./Components/DropdownSelection/DropdownSelection";
import {
  fetchDisciplineSpeciality,
  updateSignUpProfileApi,
} from "../../API/API";
import { useEffect } from "react";
import { updateSignupProfile, talentProfileApidata } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import Ratingmodal from "./Ratingmodal";
import ProgressBar from "../../components/RatingProgressBar/ProgressBar";
import { back, getWizarFlowNumber, next } from "../Auth/components/wizardFlow";
import Loader from "../../components/Loader/Loader";

function DisciplineScreen() {
  const currentIndex = 3;

  const windowHeight = window.innerHeight - 50;
  const history = useHistory();
  const dispatch = useDispatch();
  const [val, setVal] = useState("");
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(null);
  const [discipline, setDiscipline] = useState([]);
  const [loading, setLoading] = useState(false);
  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading1 = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const [isModal, setisModal] = useState(true);

  const [flag, setFlag] = useState(false);

  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );

  useEffect(() => {
    if (apiSuccess && flag) {
      history.push(next(currentIndex));
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (profiledata?.profile?.Discipline) {
      setSelected({ discipline: profiledata.profile.Discipline });
      setVal(profiledata.profile.Discipline);
    }
  }, [profiledata]);

  const getDiscipline = async (e) => {
    setVal(e);
    error && setError(null);
    setLoading(true);
    const temp = await fetchDisciplineSpeciality({
      search: e?.trim()?.length < 1 ? "a" : e?.trim(),
      type: "discipline",
    });
    if (temp.data) {
      setDiscipline(temp.data);
    }
    setLoading(false);
  };
  const nextHandler = () => {
    if (!selected || val === "") {
      setError("Please add your discipline to continue");
    } else {
      setError(null);
      setFlag(true);
      dispatch(
        updateSignupProfile({
          "Profiles.Discipline": selected?.discipline ?? "",
          WizardFlow: getWizarFlowNumber(currentIndex),
        })
      );
    }
    // dispatch(talentProfileApidata());
  };

  const handleBack = () => {
    history.push(back(currentIndex));
  };

  const signUpLoading = useSelector(
    (state) => state.profileReducer.signUpLoading
  );
  const { loading: profileLoading } = useSelector(
    (state) => state.profileReducer
  );

  if (signUpLoading || profileLoading) return <Loader />;

  return (
    <>
      {isModal ? <Ratingmodal /> : null}
      <div className="NameScreenContainer">
        {typeof apiError === "string" && (
          <Alert
            message={apiError}
            type="error"
            className="alert-error"
            showIcon
            closable
          />
        )}
        {profiledata?.profile?.ProfessionCode == "HC" ? (
          <div className="Profile-Completion-Parent">
            <div className="ProfileCompletion">
              <ProgressBar heading={"Tell us a bit about yourself"} />
            </div>
          </div>
        ) : (
          <ProfileCompletion
            currenPage={1}
            totalPage={10}
            title="Tell us a bit about yourself"
          />
        )}
        <DropdownSelection
          title={"Your discipline"}
          placeholder={"ex. RN"}
          subTitle={"Type and pick a discipline"}
          type={"Mandatory"}
          value={val}
          onChange={getDiscipline}
          options={discipline}
          keyVal={"discipline"}
          selected={selected}
          setSelected={(e) => {
            setSelected(e);
            setVal(e?.discipline);
            error && setError(null);
          }}
          error={error}
          loading={loading}
        />

        <BottomButtons
          nextHandler={nextHandler}
          nextBtnLoading={loading1}
          backHandler={handleBack}
        />
      </div>
    </>
  );
}

export default DisciplineScreen;
