import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import Home from "../pages/Home/Home";
import TalentEmployer from "../pages/WelcomeWizard/TalentEmployer";
import AcceptInvite from "../pages/AcceptInvite/AcceptInvite";
import Profession from "../pages/WelcomeWizard/Profession";
import RoleExperience from "../pages/WelcomeWizard/RoleExperience";
import SuccessPage from "../pages/WelcomeWizard/SuccessPage";
import ResumeUpload from "../pages/WelcomeWizard/ResumeUpload";
import ErrorComponent from "../pages/ErrorComponent/ErrorComponent";
import Credentialing from "../pages/Credentialing/Credentialing2";
import AuthSuccess from "../pages/Auth/components/AuthSuccess";
import DashBoard from "../pages/DashBoard/DashBoard";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import LinkedInCheck from "../pages/Auth/components/LinkedInCheck";
import SearchResults from "../pages/SearchGigs/SearchResults";
import TermsOfServices from "../pages/Auth/components/TermsOfServices";

import ReceivedBidDetailPage from "../pages/Bid/ReceivedBids/ReceivedBidDetailPage";
import PlacedBidsDetailPage from "../pages/Bid/PlaceBids/PlaceBidsDetailPage";
import Proposals from "../pages/Proposals/Proposals";
import EditBid from "../pages/Bid/components/EditBid";
import BidtoApply from "../pages/Bid/components/BidToApply";
import Messages from "../pages/Messages/Messages";
import Success from "../pages/Bid/components/successBid";
import Chat from "../pages/Chat/Chat";
import ChatHistory from "../pages/Chat/components/ChatHistory";
import ReceivedBids from "../pages/Bid/ReceivedBids/ReceviedBids2";
import ManageSkillListing from "../pages/Manage_SkillListing/ManageSkillListingOne";
import SkillListng from "../pages/SkillListing/SkillListingPage";
import DeclineBids from "../pages/Bid/DeclineBids";
import GuestGidDetailProvider from "../pages/SearchGigs/GuestUser/components/GuestGidDetailProvider";
import TimeSheet from "../pages/TimeSheet/TimeSheet";
import TimeCorrection from "../pages/TimeSheet/TimeCorrection/TimeCorrection";

import Header from "../components/Header/Header";
import NewCreateSkillListing from "../pages/SignUp/NewCreateSkillListing/CreateSkillListing";
import history from "../config/history";
import SearchGigs from "../pages/SearchGigs/SearchGigs";
import Profile from "../pages/Profile/Profile";
import LinkSocial from "../pages/Profile/components/LinkSocial";
import Loader from "../components/Loader/Loader";
import JobDetailsPageProvider from "../pages/DashBoard/components/JobDetailsPageProvider";
import DummyContent from "../pages/DashBoard/components/DummyContent";
import MyFavorites from "../pages/MyFavorites/MyFavorites";
import RetractedBid from "../pages/Bid/components/RetractedBid";
import { retractBid, userLanded } from "../redux/actions";
import PlaceBids from "../pages/Bid/PlaceBids/PlaceBids";
import Interview from "../pages/Interview/Interview";
import _3D_Resume from "../pages/_3D_Resume/_3D_Resume";
import HomeSearchResults from "../pages/Home/HomeSearchResults";
import HomeSeeAllpage from "../pages/Home/HomeSeeAllpage";
import LicenseNumber from "../components/license/LicenseNumber";
import BasicLifeSupport from "../components/LifeSupport/BasicLifeSupport";
import RestrictNextRoutes, {
  LoginRoute,
  ProtectedRoute,
  PublicRoute,
  RestrictSignUpRoutes,
  SpecialRoute,
  ValidateRoute,
} from "./routes";
import MessageDetail from "../pages/Messages/components/MessageDetailBid/MessageDetail";
import Footer from "../components/Footer";
import RoleExperienceNew from "../pages/WelcomeWizard/RoleExperienceNew";
import { RatingForm } from "../pages/Profile/components/RatingReviews/RatingForm";
import RatingformTwo from "../pages/Profile/components/RatingReviews/RatingformTwo";
import AllCard from "../pages/Profile/components/RatingReviews/Ratingcards/AllCard";
import ManageAssignment from "../pages/Manage_Assignments/ManageAssignment";
import Conversation from "../pages/Conversations/Conversations";
import ScrollToTop from "../components/ScrollToTop";
import NotificationPage from "../pages/NotificationScreen";
import EmailVerification from "../components/EmailVerification/EmailVerification";

import ViewListing from "../pages/ViewListing/ViewListing";
import SkillListingDetailPage from "../pages/Manage_SkillListing/SkillListingDetailPage/SkillListingDetailPage";
import CompanyProfile from "../pages/CompanyProfile/CompanyProfile";
import Error from "../pages/Error/Error";
import Dashboard from "../pages/NewDashboard/Dashboard";
import axios from "axios";
// import Dashboard from "../pages/DashBoard/DashBoard";
import ReadRules from "../pages/WelcomeWizard/ReadRules";
import SuccessProfileInfo from "../pages/WelcomeWizard/SuccessProfileInfo";
import EffectiveProfileInfo from "../pages/WelcomeWizard/EffectiveProfileInfo";
import NameScreen from "../pages/SignUp/NameScreen";
import LocationScreen from "../pages/SignUp/LocationScreen";
import DisciplineScreen from "../pages/SignUp/Discipline";
import SpecialityScreen from "../pages/SignUp/Speciality";
import MobileNumberScreen from "../pages/SignUp/MobileNumber";
import ExperienceLevelScreen from "../pages/SignUp/ExperienceLevel";
import WorkPlacePolicyScreen from "../pages/SignUp/WorkPlacePolicy";
import WorkAuthorization from "../pages/SignUp/WorkAuthorization";
import LicenseScreen from "../pages/SignUp/License";
import VeteranScreen from "../pages/SignUp/Veteran";
import ACLS_DocV2 from "../pages/SignUp/Components/DocUpload/ACLS_DocV2";
import BLSDocv2 from "../pages/SignUp/Components/DocUpload/BLSDocv2";
import Driver_license_DocV2 from "../pages/SignUp/Components/DocUpload/Driver_license_DocV2";
import Reference1_DocV2 from "../pages/SignUp/Components/DocUpload/Reference1_DocV2";
import Reference2_DocV2 from "../pages/SignUp/Components/DocUpload/Reference2_DocV2";
import Social_security_Doc from "../pages/SignUp/Components/DocUpload/Social_security_Doc";
import StateLisenceV2 from "../pages/SignUp/Components/DocUpload/StateLisenceV2";
import ResumeUploadv2 from "../pages/SignUp/Components/DocUpload/ResumeUploadv2";
import AnyCareerGap from "../pages/SignUp/Components/DocUpload/Signup_gap_screen/AnyCareerGap";
import EducationalScreenOne from "../pages/NewSignUP/EducationalScreenOne";
import EducationalScreenTwo from "../pages/NewSignUP/EducationalScreenTwo";
import EducationalScreenThree from "../pages/NewSignUP/EducationalScreenThree";
import SuccessPageSignUp from "../pages/NewSignUP/SuccessScreen";
import ViewCompanyJobs from "../pages/CompanyProfile/ViewCompanyJobs";
import ViewResume from "../pages/_3D_Resume/ViewResume/ViewResume";
import { ArraySignUpRoute } from "../shared/helpers";
import EmployerLandingPage from "../pages/EmployerLandingPage/EmployerLandingPage";
import NewSignUp from "../pages/NewSignupPage/NewSignup";
import { useDispatch , useSelector} from "react-redux";
import { v4 as uuid } from "uuid";
import { APP_NAME } from "../constants/userTrackingConstants";
import SignUpLogin from "../pages/Auth/components/SignUpLogin";
import SocialSecurityNumber from "./../pages/SignUp/Components/SocialSecurityNumber/SocialSecurityNumber";
import LicenseNumberPage from "../components/LicenseNumberComponent/LicenseNumberPage";
import AdvancedLifeSupport from "../components/AdvancedLifeSupport/AdvancedLifeSupport";
import DriversLicense from "../components/Driver'sLicense/DriversLicense";
import MobileNumberOTP from "../pages/SignUp/Components/MobileNumberOTP/MobileNumberOTP";
import EmailInvitation from "../components/EmailInvitation/EmailInvitation.jsx";

import NewEditSkillListing from "../components/NewEditSKillListing/NewEditSkillListing.jsx"
import EditExperience from "../components/EditExperience.jsx";
import EditName from "../components/EditName/EditName.jsx";
import NumberEdit from "../components/MobileNumberEdit/NumberEdit.jsx";
import EditNumberOtp from "../components/MobileNumberotp/EditNumberOtp.jsx";
import DisciplineEdit from "../pages/SignUp/Components/DisciplineEditScreen/DisciplineEdit.jsx";
import EditSocialSecuity from "../pages/SignUp/Components/Edit social Secuity Number/EditSocialSecuity.jsx";
import EditStateLicensed from "../pages/SignUp/Components/Edit social Secuity Number/EditStateLicensed.jsx";
import EditSpecialty from "../pages/SignUp/Components/Edit social Secuity Number/EditSpecialty.jsx";
import EditEmail from "../pages/SignUp/Components/Edit social Secuity Number/EditEmail.jsx";


export const AppRouter = (props) => {
  const dispatch = useDispatch();
  const emailVerified = props.isEmailVerified;
  const { id } = props.userProfile;
  const { WizardFlow } = props.userProfile.profile;
  const [ipaddress, setIpaddress] = useState("");
      const isEmail = useSelector(
        (state) => state.profileReducer?.talentApiData?.Profiles?.IsEmailVerified
      );

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setIpaddress(response?.data?.ip);
    } catch {}
  };

  const locationUrl = useLocation();
  const currentUrl = locationUrl?.pathname;
  const initialPath = locationUrl?.pathname.split("/")[1];
    const headerKey = useSelector(
      (state) => state.searchGigsReducer.headerKey
    );
  const DEFAULT_USER_LANDING_TRACK = {
    page: "/",
    event: "USER_LANDED",
    step: null,
    appName: APP_NAME.TALENT,
    url: window.location.href,
  };

  useEffect(() => {
    getIpAddress();
    dispatch(
      userLanded({
        ...DEFAULT_USER_LANDING_TRACK,
        uuid: localStorage.getItem("uuid") || uuid(),
      })
    );
  }, []);

  const { location, width } = props;

  // //
  const professionCode = localStorage.getItem('ProfessionsCode')
  const handleAlertVisibility = () => {
    if (isEmail || isEmail === undefined || isEmail === null && WizardFlow === 1000) {
      return '5rem';
    } 
    if (!isEmail && WizardFlow !== 1000) {
      return '5rem';
    }
    if (WizardFlow === 1000 && !isEmail && professionCode === 'IT') {
      return window.innerWidth < 786 ? '4rem' : '6rem';
    }
    if (WizardFlow === 1000 && !isEmail && professionCode === 'HC') {
      return window.innerWidth > 786 && window.innerWidth <= 1000 ? '9rem' : '13rem';
    }
    return '12rem';
  }

  return (
    // <Router  basename={'/app'}>
    <>
      <ScrollToTop />
      {/* <Suspense fallback={<Loader />}> */}
      <div className="content-wrap">
        <div
          className={`headerSpaceRouter ${
            ArraySignUpRoute?.includes(currentUrl) === true
              ? "headerSpaceRouterPadding"
              : null
          } ${
            initialPath == "employer" && !props.authToken
              ? "less-padding-for-landing-page"
              : ""
          }`}
          style={{paddingTop: handleAlertVisibility()}}
        >
          <Header
            changeStage={props.shareStage}
            stage={props.stage}
            id={id}
            emailVerified={emailVerified}
            WizardFlow={WizardFlow}
            EmailVerification={EmailVerification}
            key={headerKey}
          />
          <Switch>
            {/* <PublicRoute exact path="/">
            <Home />
          </PublicRoute> */}
            <ValidateRoute exact path="/">
              <Home />
            </ValidateRoute>
            <PublicRoute exact path="/auth">
              <AuthSuccess IPAddress={ipaddress} />
            </PublicRoute>

            <LoginRoute path="/login">
              <SignUpLogin isStatic={true} isSignup={false} />
              {/* <Auth /> */}
            </LoginRoute>
            <LoginRoute path="/signup">
              <SignUpLogin isStatic={true} isSignup={true} />
              {/* <Auth /> */}
            </LoginRoute>

            <LoginRoute path="/joinus">
              <SignUpLogin isStatic={true} isSignup={true} />
            </LoginRoute>

            <LoginRoute path="/joinus2">
              <SignUpLogin isStatic={true} isSignup={true} isFbButton={false} />
            </LoginRoute>
            <LoginRoute path="/signin">
              <SignUpLogin isStatic={true} isSignup={false} />
            </LoginRoute>
            <PublicRoute exact path="/linkcheck">
              <LinkedInCheck />
            </PublicRoute>
            <PublicRoute exact path="/search/Pvt">
              <SearchGigs isPrivatePage={true} />
            </PublicRoute>
            <PublicRoute exact path="/Private">
              <SearchGigs />
            </PublicRoute>
            <PublicRoute exact path="/search/:searchValue">
              <SearchGigs />
            </PublicRoute>
            <ValidateRoute exact path="/details/:jobid">
              <GuestGidDetailProvider />
            </ValidateRoute>
            <ProtectedRoute path="/create-bid">
              <ValidateRoute>
                <BidtoApply />
              </ValidateRoute>
            </ProtectedRoute>
            
            <ProtectedRoute  path="/Edit-name">
            <ValidateRoute>
            <EditName />
            </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute  path="/Edit-number">
            <ValidateRoute>
            <NumberEdit/>
            </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute  path="/Edit-number-otp">
            <ValidateRoute>
            <EditNumberOtp/>
            </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute  path="/Edit-Discipline">
            <ValidateRoute>
            <DisciplineEdit/>
            </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute  path="/Edit-Social-Secuity">
            <ValidateRoute>
            <EditSocialSecuity/>
            </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute  path="/Edit-State-Licensed">
            <ValidateRoute>
            <EditStateLicensed/>
            </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute  path="/Edit-Specialty">
            <ValidateRoute>
            <EditSpecialty/>
            </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute  path="/Edit-Email">
            <ValidateRoute>
            <EditEmail/>
            </ValidateRoute>
            </ProtectedRoute>
            {/* <ProtectedRoute  path="/Edit-Email">
            <ValidateRoute>
            <EditEmail/>
            </ValidateRoute>
            </ProtectedRoute> */}
            <PublicRoute path="/error">
              <Error />
            </PublicRoute>
            <ProtectedRoute path="/receivedBids">
              <ValidateRoute>
                <ReceivedBids
                  emailVerified={emailVerified}
                  id={id}
                  WizardFlow={WizardFlow}
                />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/receivedbid-detail/:bidId">
              <ValidateRoute>
                <ReceivedBidDetailPage />
              </ValidateRoute>
            </ProtectedRoute>

            <ProtectedRoute path="/placebids">
              <ValidateRoute>
                <PlaceBids
                  emailVerified={emailVerified}
                  id={id}
                  WizardFlow={WizardFlow}
                />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/placedbid-detail/:bidId">
              <ValidateRoute>
                <PlacedBidsDetailPage />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/name">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <NameScreen />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/location">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <LocationScreen />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/discipline">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <DisciplineScreen />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/speciality">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <SpecialityScreen />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/mobileno">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <MobileNumberScreen />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/mobileOtp">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <MobileNumberOTP />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/explever">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <ExperienceLevelScreen />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/workPlacePolicy">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <WorkPlacePolicyScreen />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/workAuthorization">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <WorkAuthorization />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/license">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <LicenseScreen />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/veteran">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <VeteranScreen />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/talentemployer">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <TalentEmployer />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/profession">
              <RestrictSignUpRoutes>
                <Profession />
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/read-rules">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <ReadRules />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/profile-instruction">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <SuccessProfileInfo />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/effective-profile-info">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <EffectiveProfileInfo />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute exact path="/resume-upload-v2">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <ResumeUploadv2 />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute exact path="/any-career-gap">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <AnyCareerGap />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/state-lisence-doc">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <StateLisenceV2 />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/bls-doc">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <BLSDocv2 />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/acls-doc">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <ACLS_DocV2 />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            {/* <ProtectedRoute path="/social-security-doc">
              <RestrictSignUpRoutes>
              <RestrictNextRoutes>
                <Social_security_Doc />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute> */}
            <ProtectedRoute path="/social-security-num">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <SocialSecurityNumber />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/driver-license-doc">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <Driver_license_DocV2 />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/reference1-doc">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <Reference1_DocV2 />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/reference2-doc">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <Reference2_DocV2 />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/profile-instruction">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <SuccessProfileInfo />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/effective-profile-info">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <EffectiveProfileInfo />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/basic-profile-success">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <SuccessPageSignUp />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/user-signup-success">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <SuccessPageSignUp />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/linksocial" component={LinkSocial} />
            <ProtectedRoute path="/create-skilllisting">
              <ValidateRoute>
                <SkillListng />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/create-skilllisting-signup">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <SkillListng />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute exact path="/role-experience">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <RoleExperienceNew />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/credentialing">
              <ValidateRoute>
                <Credentialing />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/manageskilllisting">
              <ValidateRoute>
                <ManageSkillListing />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/skill-listing-detail-page/:skillId">
              <ValidateRoute>
                <SkillListingDetailPage />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/view-listing/:id">
              <ValidateRoute>
                <ViewListing />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/view-listing">
              <ValidateRoute>
                <ViewListing />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/company-jobs/:id">
              <ValidateRoute>
                <ViewCompanyJobs />
              </ValidateRoute>
            </ProtectedRoute>
            <PublicRoute path="/company-profile/:id">
              <CompanyProfile />
            </PublicRoute>
            {/* <ProtectedRoute path="/company-profile/:id">
              <CompanyProfile />
            </ProtectedRoute> */}
            <ProtectedRoute path="/dummy">
              <DummyContent />
            </ProtectedRoute>
            <ProtectedRoute path="/myfavorites">
              <ValidateRoute>
                <MyFavorites />
              </ValidateRoute>
            </ProtectedRoute>

            <ProtectedRoute path="/dashboard">
              <ValidateRoute>
                {/* <DashBoard
                shareStage={props.shareStage}
                emailVerified={emailVerified}
                /> */}
                <Dashboard />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path={"/proposals"}>
              <ValidateRoute>
                <Proposals />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/bidsuccess">
              <ValidateRoute>
                <Success />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/edit-bid/:id">
              <ValidateRoute>
                <EditBid />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/messages/:id?">
              <ValidateRoute>
                <Conversation />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/chats">
              <ValidateRoute>
                <Conversation />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/notifications">
              <ValidateRoute>
                <NotificationPage />
              </ValidateRoute>
            </ProtectedRoute>

            {
              // commenting this out as it is a dublicate route for another component if it is not useable please remove.
              /* <ProtectedRoute path="/messages/:id">
              {window.innerWidth < 768 ? <MessageDetail /> : <Messages />}
            </ProtectedRoute> */
            }
            <ProtectedRoute
              path="/sign_educational_screen1"
              component={EducationalScreenOne}
            />
            <ProtectedRoute path="/sign-educational-screen2">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <EducationalScreenTwo />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/sign_educational_screen3">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <EducationalScreenThree />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/chat/history">
              <ValidateRoute>
                <ChatHistory />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/DeclineBids">
              <ValidateRoute>
                <DeclineBids />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/timesheet">
              <ValidateRoute>
                <TimeSheet />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/profile">
              <ValidateRoute>
                <Profile />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/requestcorrection">
              <ValidateRoute>
                <TimeCorrection />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/resume-upload">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <ResumeUpload />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>
            <ProtectedRoute path="/interview">
              <ValidateRoute>
                <Interview />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/3d_resume">
              <ValidateRoute>
                <_3D_Resume />
              </ValidateRoute>
            </ProtectedRoute>
            <PublicRoute path="/resume/:id">
              <ValidateRoute>
                <ViewResume />
              </ValidateRoute>
            </PublicRoute>
            <ProtectedRoute path="/RetractedBids">
              <ValidateRoute>
                <RetractedBid />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/HomeResult">
              <ValidateRoute>
                <HomeSearchResults />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/homeSeeAll">
              <ValidateRoute>
                <HomeSeeAllpage />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/manage_assignments">
              <ValidateRoute>
                <ManageAssignment />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/rating_form">
              <ValidateRoute>
                <RatingForm />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/rating_form2">
              <ValidateRoute>
                <RatingformTwo />
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute path="/all_rate_card">
              <ValidateRoute>
                <AllCard />
              </ValidateRoute>
            </ProtectedRoute>

            <ProtectedRoute exact path="/edit-gig-type">
              <ValidateRoute>
            <NewEditSkillListing/>
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/edit-skill-title">
              <ValidateRoute>
              <NewEditSkillListing/>
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/edit-skill">
              <ValidateRoute>
              <NewEditSkillListing/>
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/edit-location">
              <ValidateRoute>
              <NewEditSkillListing/>
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/edit-desired-pay">
              <ValidateRoute>
              <NewEditSkillListing/>
              </ValidateRoute>
            </ProtectedRoute>
            <ProtectedRoute exact path="/edit-start-date">
              <ValidateRoute>
              <NewEditSkillListing/>
              </ValidateRoute>
            </ProtectedRoute>

            <SpecialRoute path="/forgot">
              <ForgetPassword />
            </SpecialRoute>
            <ProtectedRoute path="/create-skilllisting-gig-type">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <NewCreateSkillListing />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/create-skilllisting-title">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <NewCreateSkillListing />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/create-skilllisting-location">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <NewCreateSkillListing />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/create-skilllisting-skill">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <NewCreateSkillListing />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/create-skilllisting-salary">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <NewCreateSkillListing />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

            <ProtectedRoute path="/create-skilllisting-date">
              <RestrictSignUpRoutes>
                <RestrictNextRoutes>
                  <NewCreateSkillListing />
                </RestrictNextRoutes>
              </RestrictSignUpRoutes>
            </ProtectedRoute>

  

            <ProtectedRoute
              path="/user-signup-success"
              component={SuccessPageSignUp}
            />
             <ProtectedRoute  exact path="/edit-experience">
            <ValidateRoute>
              <EditExperience/>
            </ValidateRoute>
            </ProtectedRoute>

            <PublicRoute path="/employer/SHI">
              <EmployerLandingPage />
            </PublicRoute>
            <PublicRoute path="/employer/PRESIDIO">
              <EmployerLandingPage />
            </PublicRoute>
            <PublicRoute path="/employer/HEB">
              <EmployerLandingPage />
            </PublicRoute>

            <PublicRoute exact path="/license-number">
              <LicenseNumberPage />
            </PublicRoute>
            <PublicRoute exact path="/basic-life-support">
              <BasicLifeSupport />
            </PublicRoute>
            <PublicRoute exact path="/advanced-life-support">
              <AdvancedLifeSupport />
            </PublicRoute>
            <PublicRoute exact path="/driver-license">
              <DriversLicense />
            </PublicRoute>
            <PublicRoute exact path="/Edit-name">
              <EditName />
            </PublicRoute>
            <PublicRoute exact path="/Edit-number">
              <NumberEdit/>
            </PublicRoute>
            <PublicRoute exact path="/Edit-number-otp">
              <EditNumberOtp/>
            </PublicRoute>
            <SpecialRoute path="/terms">
              <TermsOfServices />
            </SpecialRoute>
            <LoginRoute exact path="/accept-invitation">
              <EmailInvitation />
            </LoginRoute>
            <LoginRoute exact path="/email-invitation">
              <EmailInvitation />
            </LoginRoute>
            <PublicRoute exact path="/error-component">
              {" "}
              <ErrorComponent />{" "}
            </PublicRoute>
            <Redirect to="/" />
            <LoginRoute path="/accept-invitation">
              <AcceptInvite />
            </LoginRoute>
          </Switch>
        </div>
      </div>
      {location.includes("messages") && width <= 768 ? null : props.authToken &&
        WizardFlow !== 1000 ? null : (
        <Footer />
      )}
    </>
  );
};
