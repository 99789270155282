import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TextInput from "../TextInput/TextInput";
import BottomButtons from "../BottomBottons/BottomButtons";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import { fetchExistingtUser, updateUserInfo } from "../../../../redux/actions";
import { sendEditEmailVerification } from "../../../../API/API";
import { clearAll, getCookie } from "../../../../shared/helpers";
import { handleAlertVisibility } from "../../../../assets/helpers";
import { Auth } from "aws-amplify";
import { alertNotification } from "../../../../components/notifications/alertNotification";

const EditEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const profiledata = useSelector((state) => state?.profileReducer.talentApiData);
  const apiError = useSelector((state) => state.profileReducer.error);
  const emailValidation = useSelector((state) => state.chekckUserReducer.user);
    const savedLocationUpdate = useSelector(
      (state) => state.profileReducer.user
    );
  const isEmail = useSelector(
    (state) => state.profileReducer?.talentApiData?.Profiles?.IsEmailVerified
  );
  useEffect(() => {
    if (profiledata?.EmailAddress) {
      setEmail(profiledata.EmailAddress);
    }
  }, [profiledata]);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const emailSave = (email) => {
    var premail = email.toString().toLowerCase();
    dispatch(fetchExistingtUser(premail));
  };

  const debouncedEmailSave = debounce(emailSave, 1000);
  const Logout = async (redirect) => {
    try {
      clearAll();
      dispatch(updateUserInfo(null));
      await Auth.signOut();
      const logoutData = { message: `Your email is changed ${email}. You were logged out!`};
      localStorage.setItem('logoutData', JSON.stringify(logoutData));
      redirect();
      localStorage.removeItem("redirectUrl");
    } catch (error) {}
  };
  const validateEmail = (e) => {
    const input = e.target.value;
    setEmail(input);

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRegex.test(input)) {
      setIsValid(true);
      setError(null);
      setLoading(true);
      debouncedEmailSave(input);
    } else {
      setIsValid(false);
      setError("Please enter a valid email address.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (emailValidation?.userExist?.valid) {
      setError("This email already exists. Please try a different one.");
    } else {
      setError(null);
    }
  }, [emailValidation]);

  const handleSaveEmail = async () => {
    if (!error && email) {
      try {
        const payload = {
          EmailAddress: email
        }
        const response = await sendEditEmailVerification(payload);
        if (response?.status === 200) {
          await Logout(() => window.location.replace("/login"));
        } else {
          setError("Bad request - please check the email format or the API requirements.");
        }
      } catch (err) {
        console.error("Error sending email verification:", err);
      }
    }
  };
  return (
    <div
      className="emailScreenContainer"
      style={{marginTop: handleAlertVisibility(isEmail)}}
    >
      {typeof apiError === "string" && (
        <Alert
          message={apiError}
          type="error"
          className="alert-error"
          showIcon
          closable
        />
      )}
      <TextInput
        title={"Email address"}
        defaultValue={email}
        onChange={validateEmail}
        className={`emailInputSignUp ${
          isValid ? "validInputCSS" : error ? "notValidInput" : ""
        }`}
        placeholder="Enter your email"
        value={email}
        error={error}
        maxLength={50}
      />
     
      <BottomButtons
        nextButtonText="Save"
        NextText="Save"
        backButtonText="Cancel"
        nextHandler={handleSaveEmail} 
        backHandler={() => history.goBack()}
      />
    </div>
  );
};

export default EditEmail;
